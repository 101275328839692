.shopping-cart {
    
    &-title {
        color: $color-02;
        line-height: 35px;
        margin-bottom: 1.5rem;
        @extend %font-medium;

        @media only screen and (max-width: $sm - 1) {
            font-size: $s20px;
            font-weight: 500;
            line-height: 1.2;
            text-align: left;
            color: $color-02;
        }
    }

    &-sort {
        &-item {
            font-size: $s10px;
            color: $color-02;
            background-color: $color-white;
            border: solid $color-06 2px;
        }

        &-text {
            font-size: $s12px;
            color: $color-05;
            line-height: 14px;
        }

        &-name {
            margin: 0 0.375rem;
        }

        &-name,
        &-date {
            &:hover {
                text-decoration: none;
            }
        }
    }

    &-item {
        &-link {
            &-wrap {
                margin-top: 1.5rem;
                
                @media only screen and (max-width: $md - 1) {
                    float: none !important;

                    .wishButtons.pull-right {
                        .pickWish,
                        .dropdown-toggle {
                            float: right;
                        }
                    }
        
                    .wishButtons.pull-left {
                        .pickWish,
                        .dropdown-toggle {
                            float: left;
                        }
                    }
                }
            }

            &-self {
                font-size: $s18px;
                color: $color-02;
                line-height: 21px;
                @extend %font-medium;

                &:focus,
                &:hover {
                    text-decoration: none;
                    color: $color-02;
                }
            }
        }

        &-img {
            &-wrap {
                padding: 0;

                @media only screen and (max-width: $md - 1) {
                   /* float: none !important;*/
                }
            }

            &-self {
                img {
                    @media only screen and (min-width: $lg) {
                        width: 13.75rem;
                        height: 13.75rem;
                        margin: 0;
                    }
                }
            }
        }

        &-quantity {
            text-align: center;
            width: 2.8125rem;
            @include default-input;

            &:focus {
                outline: none;
            }
        }

        &-price {
            &-wrap {
                display: flex;
                justify-content: space-between;
                margin: 0 0.5rem;
                color:$color-02;
                flex-wrap: wrap;

                @media only screen and (min-width: $md) {
                    justify-content: space-between;
                }

                .savings {
                    color: $color-10;
                }
            }

            .updateCartButton {
                display: flex;
                align-items: center;

                &:hover .update_img {
                    -webkit-filter: drop-shadow(1px 1px 0px$color-08);
                    filter: drop-shadow(1px 1px 0px $color-08);
                    filter: drop-shadow(1px 1px 0px $color-08);
                }
            }
            .update_img {
                height: 18px;
                width: 18px;
                margin: 0px 5px;
            }

            .price_block {
                margin-bottom: 1rem;
                white-space: nowrap;

                @media only screen and (min-width: $md) {
                    margin-bottom: 0;

                    &_ar {
                        margin-left: 0px;
                    }
                }
            }

            @media only screen and (max-width: $lg - 1) {
                & > .quantity {
                    width: 100%;
                }
                .cc_qty_control_row {
                    margin: 0rem;
                }
                .cart-qty-control {
                    margin: 0rem 4.5rem;
                }
                & > .price_block {
                    margin: 2.2rem 0rem 0rem;
                    width: 100%;
                }
            }

            @media only screen and (max-width: $md - 1) {
                float: none !important;

                .quantity {
                    float: none !important;
                    padding: 0px 6px;
                }
                .item_qty_actions > div {
                    width: 100%;

                    .cart-qty-control {
                        display: inline;
                        margin: 0px;
                    }
                }
                .shopping-cart .wishButtons {
                    margin-top: 0rem; 
                    margin-bottom: 0.6rem;
                }

                &-wrap {
                    margin: 0px;
                }
            }

            @media only screen and (max-width: $sm - 1) {
                .item_qty_actions > div {
                    .cart-qty-control {
                        margin-left: -22px;

                        // safari hack
                        @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {  
                            margin-left: -28px;
                        }
                    }
                }
            }

            @media only screen and (min-width: $md) {
                margin-top: 6.25rem;
                
                .rfq-label,
                .shopping-cart-item-delete {
                    display: inline-block;
                }
                
                .rfq-label + .shopping-cart-item-delete {
                    margin: 0px 10px;
                }
                
                .rfq-label.col-md-10 {
                    white-space: normal;
                    width: 90%;
                }
            }

            @media only screen and (max-width: $md - 1) {
                .rfq-label {
                    max-width: 55%;
                    text-overflow: ellipsis;
                    position: relative;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    white-space: normal;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.14;
                    text-align: left;
                    color: $color-04;
                }
            }

            @media only screen and (max-width: $sm - 1) {
                .rfq-label {
                    top: -31px;
                    padding: 0px;
                }

                .no_price_update {
                    top: -21px !important;
                    padding: 0px !important;
                    left: 28px !important;
                }
            }

            &-tax-inc {
                @extend %font-medium;
            }
        }

        &-included,
        &-additional {
            margin: 1.5rem 0;
            &-items,
            &-comments {
                font-size: $s14px;
                color: $color-08;
                line-height: 1.14px;
                margin-right: 1.5rem;

                &:focus,
                &:hover {
                    text-decoration: none;
                    color: $color-08;
                    @extend %font-medium;
                }
            }

            // &-items {
            //     &:first-child {
            //         margin-right: 2.5rem;
            //     }
            // }
        }

        &-container {
            margin: 1.5rem 0;

            .cart-item-tile {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
                margin: 0;
                position: relative;

                &.coupon-wrap {
                    height: 5rem;

                    .row.availability {
                        display: none;
                    }
                }

                .cc_prt_button_div {
                    padding: 0 0.7rem 0.5rem;
                }
            }

            .additional-discount-info {
                display: block;
                padding: 15px;
                color: $color-08;
                font-size: $s12px;
                line-height: 1.14;
                float: right;

                &_ar {
                    float: left;
                    text-align: right !important;
                }
            }
        }
    }

    &-action {
        text-align: right;

        &-btn {
            border: none;
            background-color: transparent;
            font-size: $s14px;
            color: $color-08;
            line-height: 1.14;
            padding: 0;
            margin-right: 3.5rem;
            position: relative;
            
            @media only screen and (max-width: $sm) {
                margin: 0 0 0 2rem;
            }
            
            &:focus,
            &:hover {
                outline: none;
                border: none;
                background-color: transparent;
                @extend %font-medium;
                .icon {
                    font-weight: 600;
                }
            }

            .icon {
                position: absolute;
                top: -1.5px;
                left: -23px;
                font-size: 1.250rem;

                @media only screen and (max-width: $md - 1) {
                    left: -8px;
                }
            }

            .cc_rfq_link_button {
                margin-right: 0;
            }

            &_ar {
                margin-left: 3.5rem;
                margin-right: 0;
                
                .icon {
                    left: unset;
                    right: -23px;

                    @media only screen and (max-width: $md - 1) {
                        right: -8px;
                    }
                }

                .cc_rfq_link_button {
                    margin-left: 0;
                }
            }
        }
    }

    &-discount {
        border: solid 2px $color-06;
        padding: 2rem;

        &-title {
            font-size: $s16px;
            color: $color-02;
            line-height: 1.19;
            text-transform: uppercase;
            @extend %font-medium;
        }

        &-btn {
            margin-top: 0.625rem;
        }
    }

    &-availability {
        font-size: $s14px;
        margin: 0;
        p  {
            margin: 0;
        }

        .STOCK {

            &_LOW,
            &_UNAVAILABLE {
                color: $orange-1;
            }

            &_AVAILABLE {
                color: $color-10;
                vertical-align: text-bottom;
            }
        }
    }

    .wishButtons {
        margin-top: 3.25rem;
        display: inline-block;
        .btn-group {
            height: 1rem;
            button {
                height: auto;
            }
        }
        .form-group {
            margin-bottom: 0;
        }
    }

    .cc_rfq_link_button {
        margin-right: 0;
    }

    .discount-sec-divider {
        margin: 1.5rem 1rem;
        border-top: 2px solid $color-06;
    }

    .wishFinder {
        margin-bottom: 0;
    }

    .sub-total-section {
        .grand_total {
            &-price {
                @extend %font-medium;

                &-wrap {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                    color:$color-02;
                    font-size: $s20px;
                }
            }

            &-info {
                &-wrap {
                    margin-bottom: 1.5rem;
                    font-size: $s14px;
                    color: $color-04;
                }
            }
        }

        .checkout-btn {
            width: 100%;
            height: 3.5rem;
            border: none;
            &.disabled {
                color: $color-06;

                &:focus {
                    background-color: $color-05;
                    border-color: $color-05;
                }
            }
            color: $color-02;
            // @media only screen and (min-width: $sm) {
            //     width: 27.125rem;
            // }
        }
    }

    .cart-qty-control {
        display: flex;
        margin-bottom: 1rem;

        @media only screen and (min-width: $md) {
            margin-bottom: 0;
        }

        &-btn {
            background-color: transparent;
            border: none;

            &:focus {
                border: none;
                outline: none;
            }
        }

        &-icon {
            font-size: $s12px;
        }
    }

    .item_qty_actions {
        display: flex;
        justify-content: flex-start;

        .updateCartButton {
            margin: 0px 1rem;
            color: $color-08;
            line-height: 1.14;
            cursor: pointer;

            .icon {
                top: 0;
                position: relative;
                left: auto;
                padding-right: 10px;
            }

            &:hover,
            &:hover .icon {
                font-weight: normal;
            }
        }
    }

    .cc_remove_item_button {
        cursor: pointer;
    }


    .tier {
        &-outerbox {
            text-align: center;
            font-size: $s12px;
            padding: 0 0.625rem;

            @media only screen and (min-width: $md - 1) {
                padding-right: 0;
            }

            @extend %font-medium;

            .cc_prt_tier_box_text_range {
                @extend %font-regular;
            }

            .cc_prt_tier_box_text_price,
            .cc_prt_tier_box_text_range {
                @media only screen and (min-width: $sm - 1) {
                    color: $color-02;
                }
            }

            .bg-info {
                background-color: $gray-1;
            }
        }

        &-innerbox {
            border: solid 2px $color-06;
            margin-left: 0.3125rem;

            @media only screen and (min-width: $md - 1) {
                margin-bottom: 0.8125rem;
            }

            margin-bottom: 1.3125rem;
            padding: 0.75rem 0;

            b{
                font-family: 'Roboto', sans-serif;
            }
        }

        &-group {
            &-name {
                @media only screen and (max-width: $sm - 1) {
                    border-top: 2px solid $color-06;
                    padding-top: 18px;
                    font-size: $s16px;
                    font-weight: 500;
                    line-height: 1.19;
                    text-align: center;
                    color: $color-02;
                }

                &.sibling-0 {
                    @media only screen and (max-width: $sm - 1) {
                        border: none;
                        padding-top: 0;
                    }
                }
            }

        }

        &-title {
            font-size: $s14px;
            text-transform: uppercase;
            @extend %font-medium;
        }

        &-toggle {  
            padding: 0;
            border: none;
            margin-top: 1rem;
            color: $color-08;
            font-size: $s14px;
            background-color: transparent;
            &:hover {
                @extend %font-medium;
            }
            &-col {
                padding: 0;
                padding-left: 5px;
            }
        }
        &-row {
            margin:0;
            padding:0;
        }
        &-container {
            padding-left:0;
            margin: 0;
            @media only screen and (max-width: $md - 1) {
                margin-top:1rem;
                width: 100%;
            }
        }
    }
    #couponAddForm {
        .cc_clear_coupon,
        .cc_enter_coupon_code {
            color: $color-02;
        }
    }
    #couponClearForm {
        .cc_applied_coupon {
            color: $color-02;
        }
    }
    .coupon {
        &-wrap {
            display: flex;
            height: 7.5rem;
            align-items: center;
            color: $color-02;
        }
    }
    //IE 
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .wishButtons .dropdown .dropdown-toggle {
            margin: -1px 0px 0px;
        }
    }

    @media only screen and (max-width: $md - 1) {
        // bug fix -> imdbb-1604
        .row > .row {
            position: relative;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }

        &-discount {
            padding: 0 1rem;
        }
        .discount-sec-divider {
            margin: 0.5rem 1rem 1.5rem 1rem;
        }
        .cc_checkout .shopping-cart-action {
            display: grid !important;
            gap: 1rem;
            grid-auto-flow: row;
            margin-top: 1rem;

            #emailCartLink {
                text-align: center;
            }
            .shopping-cart .cc_rfq_link_button,
            &-btn {
                margin-left: 0;
                margin-right: 0 !important;
                .icon {
                    position: relative;
                    top: 3px;
                }
            }
        }
    }

    @media only screen and (min-width: $md) {
        &-discount {
            .down-arrow {
                display: none;
            }
            .collapse {
                display: block;
            }
        }
    }

    .cc_action_totals {
        button.shopping-cart-action-btn {
            line-height: 1.5;
        }
    }

}

.cart-state-msg {
  margin-bottom: 1.5rem;
  font-size: $s14px;
  color: $color-white;
  padding:10px 0;
  background: $color-08;

  @media only screen and (max-width: $sm - 1) {
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  div{
    display: inline-block;
    text-align: center;

    a {
        color: $color-white;
        text-decoration: underline;
    }

    @media only screen and (max-width: $md - 1) {
        text-align: left;
    }
  }
}

#cartStateHeadMessage{
    display: none;
    text-align: center;

    @media only screen and (max-width: $md - 1) {
        text-align: left;
    }
}

#rfqModal {
    overscroll-behavior-y: contain;
}

.other {
    &-products {
        &-group-name {
            @media only screen and (max-width: $sm - 1) {
                border-top: 2px solid $color-06;
                padding-top: 18px;
                font-size: $s16px;
                font-weight: 500;
                line-height: 1.19;
                text-align: center;
                color: $color-02;
            }

            &.sibling-0 {
                @media only screen and (max-width: $sm - 1) {
                    border: none;
                    padding-top: 0;
                }
            }
        }
    }
}

img.shopping-cart-item-img-self.img-thumbnail {
    @media only screen and (min-width: $md) {
        width: 13.75rem;
        height: 13.75rem;
        margin: 0;
    }
}

.spare-part-qty {
    display: block;
    text-align: right;
    padding: 0px 5px;
}

.fee-info {
    display: none;
}

.spare_part_qty {
    margin: 0px 25px;
}

.payment-type {
    .payment {
        display: none;
    }
    .payment:checked {
        &~.checkmark {
            &:after {
                display: block;
                top: 3px;
                left: 3px;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: $color-01;
            }
        }
    }
    .checkmark {
        position: absolute;
        height: 21px;
        width: 21px;
        border-radius: 50%;
        border-width: 1px;
        border-style: solid;
        border-color: $color-04;
    }
    
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .payment_name {
        margin: 0px 32px;
    }
}
.cc_main_container{
    .cartContainer + .featured-prod {
        .featured_prod_item {
            height: 100%;
        }

        .featured_prod_info {
            justify-content: space-between;
        }

        .cc_product_name.gp_prod {
            font-size: $s16px;
            color: $color-04;
        }

        .cc_price_value {
            font-size: $s16px;
            color: $color-21;
        }

        @media only screen and (max-width: $sm - 1) {
            .osf_home_tmpl{
                .cc_featured_prod_list{
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                }
                .cc_featured_col{
                    margin-bottom: 0;
                }
                .cc_feeatured_products .cc_featured_col .featured_prod_item{
                    box-shadow: none;
                }
            }
        }
    }
}
.cc_main_container--ar{
    .shopping-cart{
        .tier-container{
            padding-left: 0;
            float: right;

            .cc_prt_outer_row_cart{
                .cc_prt_tier_box_outer{
                    float: right;
                }
            }
        }
        .tier-toggle-col{
            padding-left: 0;
            padding-right: 5px;
        }
        .cc_modal {
            .form-group{
                text-align: right;
            }
            .cc_table{
                .cc_name{
                    text-align: right !important;
                }
            }
            .modal-footer{
                text-align: left;

                .btn-default{
                    margin-left: 0;
                    margin-right: 40px;
                }
            }

            &.cc_cart_wish_modal, &.cc_cart_additional_info_modal, &.cc_cart_rfq_modal{
                .modal-footer{
                    .btn-default{
                        margin-right: 0;
                    }
                }
            }
        }
        .shopping-cart-item-included-items, 
        .shopping-cart-item-included-comments, 
        .shopping-cart-item-additional-items, 
        .shopping-cart-item-additional-comments{
            margin-left: 1.5rem;
            margin-right: 0;
        }
        .shopping-cart-action-btn.cc_update_cart_button{
            margin: 0px 1rem;

            .icon {
                padding-right: 0;
                padding-left: 10px;

                @media only screen and (max-width: $md - 1) {
                    padding-left: 25px;
                }
            }
        }
        .wishButtons{
            button.btn-default.dropdown-toggle{
                float:right;
            }
            .dropdown-menu{
                text-align: right;
            }
        }
        .shopping-cart-action-btn{

            &.cc_rfq_link_button{
                margin-left: 0;
            }
        }

        .cc_coupon_add_form{
            .couponMessagingSection-Error{
                text-align: right;
            }
        }

        .shopping-cart-action-btn_ar .icon{
            left: auto;
        }
    }

    .featured-prod{
        .featured_prod_item{
            .cc_description {
                display: none !important;
            }
            a.go_to_PDP{
                transform: rotate(180deg);
                align-self: flex-end !important;

                &:hover, &:active, &:focus{
                    align-self: flex-end !important;
                }
            }
        }
    }
}

.page-layout--arabic {
    #cartStateHeadMessage {
        &,
        & div {
            @media only screen and (max-width: $md - 1) {
                text-align: right;
            }
        }
    }
}
.cc_stored_payments_selector.payment-type .payment:checked ~ .checkmark:after {
    width: 14px;
}

.featured-prod #overlay {
    display: none;
}

.cc_cart_rfq_modal .ccrz_loading_gif {
    height: 56px;
}

@media only screen and (max-width: $sm - 1) {
    .cc_cart_item_container {
        .cc_cart_item {
            .cart-item-tile {
                padding: 8px;

                &.coupon-wrap {
                    height: auto;
                    display: block;

                    .cc_coupon_title{
                        font-weight: 500;
                    }
                    .coupon-value{
                        margin-top: 20px;
                        font-weight: 500;
                    }

                    .row.availability {
                        display: none;
                    }
                }

                .shopping-cart-item {
                  .cc_cart_product_image{
                    margin:0 -10px 0 -15px;
                  }


                  /*
                    &-img-wrap {
                        float: left !important;
                        width: auto;

                        img {
                            min-height: 72px;
                            height: 72px;
                            min-width: 72px;
                            width: 72px;
                            object-fit: cover; 
                        }
                    }

                   */
                    &-link-wrap {
                        float: left !important;
                        margin: 0;
                        padding: 0;
                        margin-left: 10px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        
                        p.item_title.cc_item_title {
                            width: 100%;
                            padding-bottom: 4px;

                            .cc_prod_link {
                                font-size: $s16px;
                                font-weight: 500;
                                line-height: 1.19;
                                letter-spacing: normal;
                                text-align: left;
                                color: $color-02;
                            }
                        }

                        .shopping-cart-item-additional {
                            margin: 0;
                            padding: 0;
                            
                            .included_items.cc_included_items.shopping-cart-item-additional-items {
                                font-size: $s14px;
                                font-weight: normal;
                                line-height: 1.14;
                                text-align: left;
                                color: $color-08;

                                p {
                                    margin: 0;
                                }
                            }
    
                        }

                        .shopping-cart-item-included-items {
                            white-space: nowrap;
                        }

                        // remove from cart
                        .shopping-cart-item-additional-comments,
                        .wishFinder {
                            display: none;
                        }

                        .availability {
                            margin: 0;
                            font-size: $s14px;
                            font-weight: normal;
                            line-height: 1.14;
                            text-align: left;
                            min-width: 65%;
                            max-width: 70%;

                            .shopping-cart-availability {
                                margin-left: 16px;
                            }
                        }
                    }
                    &-price {
                        float: left !important;
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 8px;

                        // .savings {
                        //     display: none;
                        // }

                        & > .price_block {
                            margin: 8px 26px 0;
                            order: 4;
                        }

                        & > .quantity {
                            order: 3;
                        }

                        & > .tier-toggle-col {
                            order: 2;

                            .cc_prt_button_div {
                                padding: 0;

                                .tier-toggle {
                                    margin-top: 4px;
                                    margin-left: -16px;

                                    span {
                                        font-size: $s14px;
                                        line-height: 1.14;
                                        text-align: left;
                                        color: $color-08;
                                    }
                                }
                            }

                            .cc_prt_div_outer {
                                margin-left: -12px;
                                margin-right: -15px;
                            }

                            .tier-innerbox {
                                margin-bottom: 8px;
                            }

                            .tier-outerbox {
                                padding: 0;
                            }

                            .tier-outerbox:nth-of-type(even) {
                                padding-left: 2px;
                            }
                            .tier-outerbox:nth-of-type(odd) {
                                padding-right: 2px;
                            }
                        }
                       
                        & > .additional-discount-info {
                            order: 1;

                            &-mobile {
                                font-size: $s14px;
                                font-weight: 500;
                                line-height: 1.14;
                                text-align: left;
                                color: $color-10;
                                padding: 16px 0 8px 0;
                                margin-left: -12px;
                            }
                        }

                        .cc_qty_control_row {
                            // width: max-content;
                            // margin-left: -22px;

                            .item_qty_actions {
                                flex-direction: row-reverse;
                                margin-top: $s18px;

                                .updateCartButton.cc_update_cart_button {
                                    color: $color-02;
                                    position: relative;
                                    top: 68px;
                                    z-index: 5;

                                    .icon.icon-ic_update {
                                        top: -1.6px;
                                        font-size: 20px;
                                        left: 14px;
                                    }

                                    &:hover {
                                        color: $color-02;
                                    }
                                }

                                .shopping-cart-item-quantity {
                                    font-size: $s14px;
                                    line-height: 1.71;
                                    color: $color-02;
                                    border-bottom: solid 2px $color-05;
                                    width: 81px;
                                }
                            }
                        }

                        &-wrap {
                            flex-direction: column;
                            align-items: flex-end;
                            margin-top: -6px;

                            .cc_value.unit-p {
                                position: relative;
                                top: -25px;
                            }

                            .shopping-cart-item-delete {
                                position: absolute;
                                bottom: 0;
                                align-self: flex-start;
                                left: -38px;

                                .deleteItem {
                                    font-size: 24px;
                                }
                            }
                        }
                        .shopping-cart-item-delete-wrap {
                            margin-top: -6px;

                            .shopping-cart-item-delete {
                                position: absolute;
                                bottom: 0;
                                align-self: flex-start;
                                left: -38px;

                                .deleteItem {
                                    font-size: 24px;
                                }
                            }
                        }

                    }
                }
            }
        }
    }
    .checkout .cart-state-msg {
        margin: 0 16px 16px 16px;
    }

    .osf-imageslider-featureditem {
        // height: 288px;
        // max-height: 288px;

        .item-helper {
            box-shadow: none;
            border: 1px solid $gray-1;
        }
    }

    .grand-total-info {
        display: inline;
    }

    .payment-type .payment:checked ~ .checkmark:after{
        background-color: $color-02;
    }
}

.cart_item_update_mobile {
    margin: 0px 15px !important;

    .icon-ic_update {
        color: $color-02 !important;

        &:hover {
            color: $color-02 !important;
        }
    }  
}

.cart_item_savings_mobile {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: $color-10;
    margin: 15px 0px 0px -10px;

    &_ar {
        text-align: right !important;
    }
}

.price_block.cc_price_block.col-md-8 {
    &.shopping-cart-item-price-wrap {
        min-height: 40px;
    } 
}    


.cc_main_container--ar {
    .rfq-label.col-md-10 {
        float: unset !important;
    }
    
    @media only screen and (max-width: $md) {
        .cart-generic-options{
            padding-right: 0;
        }

        .shopping-cart-availability {
            line-height: 1.8;

            @supports (-webkit-touch-callout: none) {
                /* CSS specific to iOS devices */ 
                margin-top: -2px;
            }
        }
    }
    @media only screen and (max-width: $sm - 1) {
        .no_price_update {
            left: unset !important;
            right: 28px !important;
        }

        .item_qty_actions > div {
            .cart-qty-control {
                margin-right: -22px !important;
                margin-left: 0px !important;

                // safari hack
                @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {  
                    margin-right: -20px !important;
                    margin-left: 0px !important;
                }
            }
        }

        .shopping-cart-item-delete {
            left: unset !important;
            right: -38px;
        }

        .rfq-label.col-md-10 {
            text-align: right !important;
        }

        .shopping-cart-title{
            text-align: right;
        }

        .osf_home_tmpl{
            .cc_feeatured_products .cc_featured_prod_list{
                float: none !important; 
            }
        }

        .cc_cart_item_container .cc_cart_item .cart-item-tile .shopping-cart-item-price > .tier-toggle-col .cc_prt_button_div .tier-toggle{
            margin-left: 0;
            margin-right: -16px;
        }

        .cc_cart_item_container .cc_cart_item .cart-item-tile .shopping-cart-item-price > .additional-discount-info-mobile{
            margin-left: 0;
            margin-right: -12px;
        }

        .cc_cart_item_container .cc_cart_item .cart-item-tile .shopping-cart-item-link-wrap .availability{
            text-align: right;
        }
    } 
}