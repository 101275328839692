#countrySwitcherMod {
    .modal {
        &-dialog {
            @media only screen and (min-width: $md) {
                margin: 27px auto;
            }
        }
        &-content {
            padding: 0px 56px;
        }
        &-header,
        &-footer {
            border: none;
        }
        &-header {
            position: relative;
        }
        &-close {
            &-btn {
                position: absolute;
                top: 25%;
                left: 100%;
            }
        }
    }
}

.countryModalTable {
    border: none;

    .cc_tr_country {
        border: none;
    }

    .cc_td_country {
        border: none;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 60%;
        cursor: pointer;

        &_ar {
            flex-direction: row-reverse;
        }

        @media only screen and (max-width: $md - 1) {
            width: 100%;
        }
    }

    .country_flag {
        margin-right: 35px;
        margin-left: 25%;

        &_image {
            width: 54px;
            height: 54px;
            box-shadow: 2px 2px 5px 0px rgba($color-03, 0.8);
            border-radius: 50%;
        }

        &_ar {
            margin: 0px 25% 0px 35px;
        }

        @media only screen and (max-width: $md - 1) {         
            margin-left: auto;
            width: 40%;
            text-align: right;

            &_ar {
                margin: 0px auto 0px 35px;
                text-align: left;
            }
        }
    }

    .country_name {
        margin-right: auto;

        &_ar {
            margin: 0px 0px 0px auto;
        }

        @media only screen and (max-width: $md - 1) {         
            margin-right: auto;
            text-align: left;
            width: 40%;

            &_ar {
                margin: 0px 0px 0px auto;
                text-align: right;
            }
        }
    }
    
}