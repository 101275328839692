@mixin reset-input {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding:0;
    box-shadow: none;
}
@mixin default-input {
    @include reset-input;
    font-size: $s12px;
    color:$color-03;
    border-bottom: solid 1px $color-05;

    @media only screen and (max-width: $sm - 1) {
        font-size: $s16px;
    }

    &:focus {
        @include reset-input;
        color:$color-03;
        border-bottom: solid 1px $color-03;
    }
    &::placeholder {
        color:$color-05;
    }
}
@mixin header-position($nrOfNotifications) {
    #navbar .first_level_menu_desktop {
        top: (95 + 54 * $nrOfNotifications) + px;

        .second_level_menu {
            top: (243 + 54 * $nrOfNotifications) + px;
            $unavailableHeight: (245 + ($nrOfNotifications * 55)) + px;
            height: calc(100vh - #{$unavailableHeight});

            &.no_children {
                top: (235 + 54 * $nrOfNotifications) + px;
            }
        }
    }

    @media only screen and (max-width: $md - 1) {
        .navbar-collapse.collapse.in {
            $unavailableHeight: (48 + ($nrOfNotifications * 44)) + px;
            height: calc(100vh - #{$unavailableHeight}) !important;
        }
    }
}

@mixin layout-margin-top($nrOfNotifications) {
    margin-top: (106 + 54 * $nrOfNotifications) + px;

    @media only screen and (max-width: $md - 1) {
        margin-top: (56 + 44 * $nrOfNotifications) + px;
    }
}