.uiPage {
    .d-flex {
        display: flex;
        div {
            margin-right:2rem;
        }
    }
    .section{
        &-colors {
            [class*='color-'] {
                padding: 2.4rem 1rem;
                margin: 0.4rem;
                display: inline-block;
                border-radius: 15%;
                color: #ffffff;
            }
            .color {
                &-01 {background-color: $color-01;}
                &-02 {background-color: $color-02;}
                &-03 {background-color: $color-03;}
                &-04 {background-color: $color-04;}
                &-05 {background-color: $color-05;}
                &-06 {background-color: $color-06;}
                &-07 {background-color: $color-07;}
                &-08 {background-color: $color-08;}
                &-09 {background-color: $color-09;}
                &-10 {background-color: $color-10;}
            }
        }
        &-fonts {
            color: $color-03;
        }
        &-buttons {
            [class*='size-'] {
                margin: 1rem 0;
                h4 {
                    margin:0;
                }
            }
            table {
                tr {
                    border-bottom: none;
                }
                th,td {
                    padding: 0.75rem 1rem;
                }
                .no-left-pd {
                    padding-left:0;
                }
                
            }
        }
        &-inputs,
        &-selectbox {
            .form-group {
                display: inline-block;
                margin-right: 2rem;
                min-width: 250px;
            }
        }
        &-exampleform {
            form {
                .form-group {
                    min-width: 250px;
                    display: inline-block;
                }
            }
        }
        &-dropdown {
            .btn-group {
                max-width:12.3125rem;
            }
        }
    }
    h1, h2, h3,
    h4, h5, p {
        padding: 0;
        margin: 3rem 0;
        color: $color-03;
    }
    .p1size {font-size:$s16px}
    .p2size {font-size:$s14px}
    .l1size {font-size:$s14px}
    .i1size {font-size:$s12px}
}

.icon-container {
    .icon {
        &-item {
            margin:0.5rem 0;
            &-name {
                margin-right: 1rem;
            }
            &-self {

            }
        }
    }
}
