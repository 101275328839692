.checkcontainer {
    @extend %font-regular;
    color: $color-02;
    font-size: $s14px;
    display: block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked~.checkmark:after {
            display: block;
        }
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $color-white;
        border: solid 1px $color-04;

        &::after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 0;
            width: 6px;
            height: 11px;
            border: solid $color-02;
            border-width: 0 1.5px 1.5px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
