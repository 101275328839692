.checkout-container {

    #billingAddressForm,
    #shippingAddressForm {
        margin: 0;

        input,
        select {
            background-color: transparent;
            border: none;
            height: 1.5rem;
            width: 100%;
            font-size: $s16px;
            color: $color-02;
            opacity: 1;
            cursor: text;
            padding: 0px;
        }

        select {
            margin-left: 2px;
            -moz-appearance: none;
            -webkit-appearance: none;
        }

        .countryListing::-ms-expand {
            display: none;
        } 

        .stateSection {
            .form-control {
                padding: 2px;
            }
        }
    }

    .info {
        &-wrap {

            // display: flex;
            .form-title {
                font-size: $s16px;
                line-height: 1.19;
                color: $color-02;
                text-transform: uppercase;
                @extend %font-medium;

                &-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .change_address {
                        margin: 0;

                        a {
                            color: $color-08;

                            &:hover {
                                @extend %font-medium;
                                text-decoration: none;
                            }
                            &:focus {
                                text-decoration: none;
                            }
                        }

                    }
                }
            }
        }

        &-section {
            padding: 0.625rem 1.25rem;
            border: solid 2px $color-06;
            margin-bottom: 2rem;

            &-field {
                height: 1.5rem;
            }
        }
    }

    .step-links,
    .link_button {
        display: flex;
        justify-content: space-between;
    }

    .step-links {
        input {
            background-color: transparent;
            border: none;
            padding: 0;
            min-width: unset;
            color: $color-08;
            outline: none;
            font-size: $s14px;

            &:hover {
                @extend %font-medium;
            }
        }
    }

    .link_button {
        input {
            height: 3.5rem
        }
    }
    
    .checkout-shipping {
        [class*='col-'] {
            padding: 0;
        }
        .shipping {
            &-title {
                color: $color-02;
                font-size: $s30px;
                line-height: 1.17;
                @extend %font-medium;
            }

            &-buttons {
                margin: 1.875rem 0;
            }

            &_payment {
                .ship-complete .checkcontainer {
                    display: inline-block;
                }
            }

            &_payment_term {
                display: flex;
                justify-content: flex-end;

                .checkcontainer {
                    display: inline-block;

                    a {
                        color: $color-08;

                        &:focus,
                        &:hover {
                            text-decoration: none;
                            @extend %font-medium;
                        }
                    }

                    .checkmark {
                        top: 1.5px;
                        left: -2px;
                    }
                }
            }
            &-method {
                &-select {
                    width: 50%;

                    select.shippingOptions {
                        display: none !important;
                    }
                }
            }
            &-guide {
                &-text {
                    margin-bottom: 3.75rem;
                    color: $color-02;
                }
            }
        }

        .shipForm {
            margin-top: 0.875rem
        }

        .shipnotes {
            border-radius: 0;
            border: solid 1px $color-06;

            @media only screen and (max-width: $sm - 1) {
                font-size: $s16px;
            }
        }

        .ship-complete {
            margin: 2.125rem 0;
        }

        .coso_ship_complete {
            .checkmark {
                top: 1.5px;
                left: -2px;
            }
        }

        .payment {
            &-type {
                margin-bottom: 2.3125rem;

                .credit_card_logos {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 5px 28px -15px;
                }

                .credit_card_logo {
                    display: block;
                    height: 40px;
                    width: auto;
                }
            }
        }

        .method {
            &-title {
                margin-bottom: 2rem;
                color:$color-02;
                font-size: $s16px;
                line-height: 1.19;
                text-transform: uppercase;
                @extend %font-medium;
            }
        }
    }
 
    .custom_select {
        box-shadow: none;
        width: 250px !important;

        .btn-default {
            &,
            &:active,
            &:focus,
            &:hover {
                padding: 5px 0px;
                background-color: transparent;
                border-color: transparent;
                border-bottom: 1px solid $color-05;
                margin: 5px 0px;
                outline: none !important;
            }
        }

        .dropdown-toggle:focus {
            outline: none !important;
        }

        .filter-option,
        .filter-option-inner,
        .filter-option-inner-inner {
            &,
            &:focus {
                outline: none;
                color: $color-02;
            }
        }

        .filter-option-inner-inner {
            padding: 0px 10px;
        }

        .dropdown-menu {
            li a {
                &,
                &:focus,
                &:active {
                    outline: none !important;
                }
            }
            li.active a {
                background-color: $color-06;
                color: $color-02;
            }
            li:hover {
                background-color: $gray-1;
            }
        }
    }
}

//navigation
@media only screen and (max-width: $sm - 1) {
    .checkout-nav-steps {
        display: flex;
        justify-content: center;
        padding: 0;

        .nav-wrap {
            font-size: 0.5625rem;
            color: $color-17;
            display: block;
            position: relative;
            z-index: 1;

            .nav-index {
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 25px;
                border-radius: 50%;
                border-style: solid;
                border-width: 0.186rem;
                border-color: #f1f3f4;
                margin-bottom: 5px;
                background-color: white;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
            }

            .nav-index.icon::before {
                font-size: 16px;
                line-height: 24px;
            }

            .nav-title {
                width: 100%;
                display: block;
                padding-left: 5px;
                padding-right: 5px;
                margin-top: 9px;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                color: $color-02;
            }

            .nav-title:focus,
            .nav-title:hover {
                text-decoration: none;
            }
        }

        .cc_checkoutStep {
            position: relative;
            display: inline-block;
            flex: 0 1 25%;

            &:last-child {
                &::after {
                    content: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                top: 0.9rem;
                background-color: #e2e5f1;
                border-radius: 5px;
                right: -50%;
                z-index: -1;
            }

            & a:hover,
            & a:active {
                text-decoration: none;
            }

            &.checked {
                cursor: pointer;
            }
        }

        .active {
            .nav-wrap {
                   .nav-index {
                    background-color: $color-01;
                    color: white;
                }
                .nav-title {
                    font-weight: 500;
                }
            }
        }
    }
}

@media only screen and (min-width: $sm) {
    .checkout-nav-steps {
        padding: 0;
        overflow: hidden;
        display: flex;
        justify-content: space-between;

        li {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 0;
            width: 100%;
            height: 61px;
            list-style-type: none;
            padding: 5px 5px 5px 30px;
            border-right: 4px solid $color-white;
            position: relative;

            a {
                color: $color-03;
                text-decoration: none;
                text-align: center;
            }

            &:nth-child(n+2) {
                &::before {
                    position: absolute;
                    top: -1px;
                    left: 0;
                    display: block;
                    border-left: 25px solid $color-white;
                    border-top: 32px solid transparent;
                    border-bottom: 32px solid transparent;
                    width: 0;
                    height: 0;
                    content: " ";
                }
            }

            &::after {
                z-index: 1;
                position: absolute;
                top: -1px;
                right: -24px;
                display: block;
                border-left: 25px solid transparent;
                border-top: 32px solid transparent;
                border-bottom: 32px solid transparent;
                width: 0;
                height: 0;
                content: " ";
            }
        }

        .checked {
            cursor: pointer;

            a {
                color: $color-white;
            }
        }

        .nav {
            &-wrap {
                display: flex;
                align-items: center;
                text-transform: uppercase;
            }

            &-index {
                font-size: $s24px;
                margin-right: 0.75rem;
                width: 1.5rem;
                text-align: center;
            }
        }

        /* Arabic layout */
        &.checkout-nav-steps--ar{
            .nav-index{
                margin-left: 0.75rem;
                margin-right: 0;
            }
            li{
                padding-right: 30px;
                padding-left: 5px;

                &:nth-child(n+2) {
                    &::before {
                        left: -25px;
                        border-left: none;
                        border-right-width: 25px;
                        border-right-style: solid;
                        border-right-color: $color-white;
                        right: 0px;
                        left: auto;
                    }
                }

                &::after{
                    border-left-width:0;
                    border-right-width: 25px;
                    border-right-style: solid;
                    left: -25px;
                    right: auto;
                }

                &.co-step3 {
                    margin-right: 0;
                    margin-left: 20.5px;
                }
            }
            
        }
    }
}

/* Setup colors (both the background and the arrow) */

@media only screen and (min-width: $sm) {
    /* Completed */
    .checkout-nav-steps li {
        background-color: $color-10;
    }

    .checkout-nav-steps li::after {
        border-left-color: $color-10;
        border-right-color: $color-10;
    }

    /* active */
    .checkout-nav-steps li.active {
        background-color: $color-01;
    }

    .checkout-nav-steps li.active::after {
        border-left-color: $color-01;
        border-right-color: $color-01;
    }

    /* Following */
    .checkout-nav-steps li.active~li {
        background-color: $gray-1;
    }

    .checkout-nav-steps li.active~li::after {
        border-left-color: $gray-1;
        border-right-color: $gray-1;
    }
}

//optional space for arrow shape on the last element 
.co-step3 {
    margin-right: 20.5px;
    @media only screen and (max-width: $sm - 1) {
        margin-right: 0;
    }
}

//overlay animation on hover
.checkout-nav-steps {
    .checked-overlay {
        position: absolute;
        bottom: 0;
        left: 100%;
        right: 0;
        background-color: $color-10;
        overflow: hidden;
        width: 0;
        height: 100%;
        transition: .5s ease;

        &-text {
            color: $gray-1;
            font-size: 1.5rem;
            margin-left: 1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            white-space: nowrap;
        }
    }

    li {
        &:hover {
            .checked-overlay {
                width: 80%;
                left: 0;
                margin: 0 auto;
            }
        }
    }
}

//check icon
.icon-checkmark-circle-outline {
    font-size: 2rem;
    color: $color-white;
}


/****  Checkout - Order Review  ****/
.cc_checkout_review,
.cc_checkout_payment_panel {
    .step-links,
    .link_button {
        display: flex;
        justify-content: space-between;
        margin: 0px;
    }

    .step-links {
        input {
            background-color: transparent;
            border: none;
            padding: 0;
            min-width: unset;
            color: $color-08;
            outline: none;
            font-size: $s14px;

            &:hover {
                @extend %font-medium;
            }
        }
    }

    .cc_feeatured_products {
        .panel-heading .panel-title {
            font-size: 1.875rem;
            line-height: 1.17;
            color: $color-02;
            text-transform: capitalize;
            margin-bottom: 35px;
        }

        .cc_product_name {
            color: $color-04 !important;
        }
    }
}

.cc_order_details,
.cc_checkout_review {
    .cc_body {
        padding: 0px;
    }

    .well {
        background-color: transparent;
        margin: 32px 0px;
        padding: 0px;

        & > .row {
            display: flex;
            justify-content: space-between;
            margin: 0px;

            &:before,
            &:after {
                content: none !important;
            }
        }
    }

    .checkout_buyer_information,
    .checkout_billing_address,
    .checkout_shipping_address {
        width: 31.5%;
        border: 2px solid $color-06;
        padding: 20px;

        & > h5 {
            font-weight: 500 !important;
            font-size: $s16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            color: $color-02;
            text-transform: uppercase;
            margin: 0px 0px 16px; 
            display: inline-block;
        }

        & > p {
            @extend %font-regular;
            font-size: $s16px;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: $color-02;
            margin: 0px;
        }

        & > p span {
            display: block;
        }

        .cc_addr_name,
        .cc_addr_company {
            margin: 0px;
        }
    }

    .cc_buyer_email {
        word-break: break-all;
    }

    .panel-heading .panel-title {
        @extend %font-medium;
        font-size: $s16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-transform: uppercase;
        padding-top: 0px;
        color: $color-02;
    }

    .cart_item {
        height: 110px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: $color-white;
        margin: 24px 0px;
        display: flex;

        & > div.col-md-2:first-child() {
            padding: 0px;
            height: 100%;
            width: 110px;
            display: flex;
            align-items: center;
            background-color: $gray-1;

            .cc_product_name_img {
                width: 110px;
            }

            .orderReview.img-responsive {
                max-height: 110px;
                max-width: 110px;
            }
        }

        & > div {
            height: 100%;
        }

        & > div:last-child() {
            margin-left: auto;

            @media only screen and (max-width: $sm - 1) {
                margin-left: unset;
            }
        }

        div.cc_price {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between; 
            margin-left: auto;

            & > p {
                @extend %font-medium;
                font-size: $s16px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                margin-bottom: 0px;
            }

            .savings {
                color: $color-10;

                @media only screen and (max-width: $sm - 1) {
                    align-self: flex-end !important;
                    margin: 5px 0px 0px;
                }
            }
        }

        .cc_item_title {
            margin: 20px 0px 10px;

            .cc_product_name {
                &,
                &:link,
                &:hover,
                &:active,
                &:visited {
                    @extend %font-medium;
                    text-decoration: none;
                    color: $color-02;
                    font-size: $s18px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.17;
                    letter-spacing: normal;
                }
            } 
        }

        .cc_item_price {
            color: $color-04;

            @media screen and (min-width: $sm) {
                margin: 0px 15px !important;
            }            
        }

        .inventory {
            & > .value {
                @extend %font-regular;
                font-size: $s14px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.14;
                letter-spacing: normal;
                color: $color-10;
            }

            & > .value.Availability_Available {
                color: $color-10;
            }

            & > .value.Availability_LowStock,
            & > .value.Availability_OutofStock {
                color: $orange-1;
            }
        }

        .coupon_name {
            display: flex;

            .cc_coupon_title {
                margin: auto 0 auto 110px;
            }
        }

        .coupon_value {
            .cc_item_total {
                margin-left: auto;
            }
        }

        &_ar {
            .cc_cart_included_items_modal {
                th {
                    text-align: right;
                }
            }

            div.cc_price {
                margin-left: 0px;
                margin-right: auto;
            }

            & > div:last-child(),
            .coupon_value .cc_item_total {
                margin-right: auto;
                margin-left: 0px;
            }
        }
    }

    .reviewCartSection {
        & > div.cc_total {
            margin: 24px 0px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-end;

            & > p {
                @extend %font-regular;
                font-size: $s16px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                color: $color-02;
                width: 350px;
                display: flex;
                justify-content: space-between;

                span:last-child() {
                    font-weight: 500;
                }

                @media screen and (max-width: 480px) {
                    width: 100%;
                }
            }

            hr {
                margin: 14px 0px 24px;
                border: 1px solid $color-06;
                width: 350px;

                @media screen and (max-width: 480px) {
                    width: 100%;
                }
            }

            .cc_grand_total span {
                @media only screen and (min-width: $sm) {
                    font-weight: bold !important;
                    font-size: 19px;
                }
                @media only screen and (max-width: $sm - 1) {
                    font-weight: 500 !important;
                    font-size: $s20px;
                }
                line-height: 1.2;
            }
        }

        &_ar {
            & > div.cc_total {
                text-align: left;
            }
        }
    }

    .prod_info {
        display: flex;
        justify-content: flex-start; 
    }

    .cc_cart_included_items_modal {
        #myModalLabel {
            text-align: center;
        }

        tbody {
            text-align: initial;

            .cc_name br,
            .cc_name .cc_sku {
                display: none;
            }
        }
    }
    
    @media only screen and (max-width: $sm - 1) {
        .well {
            & > .row {
                flex-direction: column;
            }
        }

        .checkout_buyer_information,
        .checkout_billing_address,
        .checkout_shipping_address {
            width: 100%;
            margin-bottom: 25px;
        }

        .cart_items_container,
        .cc_checkout_payment_container {
            h3.panel-title {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.14;
                padding: 0;
            }
        }

        .cart_item {
            min-height: 166px;
            height: auto;
            flex-direction: column;

            &.coupon {
                height: 78px;
                max-height: 78px;


                .coupon_title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.19;
                    color: $color-02;
                    margin: 8px;

                }

                .cc_item_total {
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-end;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.19;
                    color: $color-02;
                    margin: 8px 16px 8px 8px;
                }
            }

            & > div {
                display: flex;
                // height: inherit !important;
                // flex-direction: column;
            }

            & > div.col-xs-5.col-md-6 {
                padding-right: 0px;
            }

            .product-img-wrapper {
                background-color: $gray-1;
                margin: 8px;
                min-height: 72px;
                height: 72px;
                max-width: 72px;
                width: 72px;
                display: flex;
                align-items: center;
                justify-items: center;
            }

            .orderReview.img-responsive {
                width: 100%;
            }

            .item-title-wrapper {
                margin: 8px 8px 0 -4px;
                display: flex;
                flex-direction: column;
                height: 83%;
                justify-content: space-between;
            }

            .cc_item_title {
                margin: 0;
                line-height: 1.19;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: normal;
                text-align: left;    

                .cc_product_name {
                    color: $color-02;
                    line-height: inherit !important;
                    font-size: inherit !important;
                }
            }

            .cc_prt_tool_tip_text {
                font-size: 14px;
                line-height: 1.14;
            }

            .cc_ext_name {
                word-break: break-word;
            }

            // helpers
            .narrow {
                margin-left: -2px !important;
            }
            .pb-1 {
                padding-bottom: 12px;
            }


            .cc_price {
                display: flex !important;
                flex-direction: column;
                height: 100%;
                padding: 0 12px 18px 0;

                .cc_order_quantity {
                    justify-self: start !important;
                    align-self: flex-start !important;

                    .cc_quantity {
                        width: max-content;
                        font-weight: normal;
                        color: $color-02;
                    }
                }

                .cc_item_price {
                    justify-self: center;
                    align-self: flex-end;
                    font-weight: normal;
                    color: $color-02;
                }
                .cc_item_total {
                    justify-self: center !important;
                    align-self: flex-end !important;
                    font-weight: 500;
                    color: $color-02;
                    margin-top: 6px;
                }
            }

            &_ar {
                & > div:nth-child(2) {
                    padding-left: 0px;
                    padding-right: 15px !important;
                }
            }
        }

        .cc_checkout_payment_panel {
            // reset pull-/left,right
            // dirty & hacky
            .paymentBtn {
                float: none !important;
            }
            // .btn.makeCCPayment.paymentBtn {
                
            // }
        }
    }

    @media only screen and (max-width: 485px) {
        .link_button {
            flex-direction: column-reverse;

            .processReview {
                margin: 0px 0px 15px;
            }
        }
    }

    @media only screen and (max-width: 385px) {
        // .cart_item {
        //     & > div:not(:first-child()) {
        //         padding: 0px 5px !important;
        //     }
        // }

        div.cc_total {
            p,
            hr {
                width: 100% !important;
            }
        }
    }
}


/****  Checkout - Payment  ****/
.cc_checkout_payment_panel {
    h3.heading,
    h3.panel-title {
        @extend %font-medium;
        color: $color-02;
        font-size: $s30px;
        line-height: 1.17;
        margin: 15px 0px;
    }

    .tab-content.cc_tab-content,
    .cc_tab-pane.tab-pane  {
        padding-top: 0px;
    }

    .paymentTypeContainer {
        &.cdc,
        &.pdc {
            span {
                display: block;
            }
        }

        .ccPaymentOuterContainer > ul,
        .ccPaymentOuterContainer > ol {
            margin: 0px 0px 0px 25px;
            color: $color-02;
            padding: 0px;
        }
    
        li {
            text-align: left;
        }
    }

    .paymentBtn {
        margin: 45px 0px;

        &.cc_back {
            margin: 15px 0px;
        }
    }

    .has-error {
        .help-block {
            font-size: $s14px;
            font-style: normal;
            color: $color-09;
        }
    }

    .cc-payment-form {
        label,
        input,
        select {
            font-size: $s16px;
        }
    }

    @media only screen and (max-width: $sm - 1) {
        .cc_payment_processor {
            margin-bottom: 0;
            padding: 0;
        }

        .panel-heading {
            padding: 0;
        }

        p.panel-body {
            margin: 0;
        }

        h3.panel-title {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            color: $color-02;
            margin: 0;
            padding: 0;
        }

        .checkcontainer {
            line-height: 2;
            width: 45%;
            padding: 0px 30px;
            .checkmark {
                width: 21px;
                height: 21px;
            }
        }

        // reset pull-/left,right
        // dirty & hacky
        .btn.paymentBtn,
        p.cc_action_buttons {
            float: none !important;
        }
        .btn {
            max-height: 46px;
            height: 46px;
            font-size: 16px;
            font-weight: normal;
            line-height: 1.19;
            text-align: center;
            &.btn-default {
                color: $color-02;
            }

            &.paymentBtn {
                margin: -24px 0 42px 0;
            }
            &.paymentBtn,
            &.cc_use_stored_payment {
                width: 100%;
            }
        }

        .tab-content.cc_tab-content {
            padding: 0;
        }

        .cc_stored_payment_display {
            font-size: 12px;
            font-weight: 500;
            line-height: 1.17;
            text-align: left;
            color: $color-02;

            .payment_image_container {
                img {
                    // height: 17px;
                    // width: auto;
                    width: 70px;
                    height: 40px;
                }
            }
        }
    }

    .cc_nav-tabs-payment {
        display: none !important;
    }
}

.cc_main_container--ar{
    .ccPaymentOuterContainer{
        .cc-payment-form{
            .form-group{
                text-align: right;
            }
        }
    }

    .cc_checkout_user_info{
        .checkcontainer{
            padding-right: 20px;
            padding-left: 0;

            .checkmark{
                right: 0px;
                left: auto;
            }
        }
        
        .cc_ship_form{
            .shipping-guide-text{
                float: right;
            }
            .cc_coso_ship_complete{
                float: right;
            }
            .cc_coso_ship_notes{
                clear: both;
            }
            .cc_shipnotes_label{
                float: right;
            }
            .form-group{
                text-align: right;
            }
            .bootstrap-select {
                >select{
                    display: none !important; 
                }
                .dropdown-toggle .filter-option{
                    text-align: right;
                }
                .dropdown-menu.inner{
                    text-align: right;
                }
                .dropdown-toggle .caret{
                    right: auto;
                    left: 12px;
                    margin-left: 0;
                    margin-right: 10px;
                }
            }
        }
    }
    
    .checkoutContainer{
    
        .cc-payment-form .checkcontainer .checkmark{
            right: 0;
            left: auto;
        }

        #newWalletForm{
            .form-group{
                > label{
                    float: right;
                    text-align: left;
                }

                .checkbox{
                    label{
                        padding-left: 0;
                        padding-right: 20px;
                    }

                    input[type=checkbox]{
                        margin-left: 0;
                        margin-right: -20px;
                    }
                }

                div.checkbox{
                    text-align: right;
                    margin-right: 220px;

                    @media only screen and (max-width: $sm - 1) {
                        margin-right: 0;
                    }
                }

                .help-block{
                    text-align: right;
                }
            }
        }
    }

    .checkoutContent{
        .cc_address_book_modal{
            .cc_title{
                text-align: right;
            }
    
            .close{
                float: left;
            }
        }
    }

    .checkoutContent{
        .cc_address_book_modal{
            .cc_title{
                text-align: right;
            }
    
            .close{
                float: left;
            }
        }
    }

    .cc_order_proof_upload_modal{
        .modal-close-btn{
            float: left;
        }
        .form-message__item{
            text-align: right;
        }
        .cc_modal_footer{
            text-align: left;

            button{
                margin: 0 40px 0 0;

                @media only screen and (max-width: $sm - 1) {
                    margin: 0;
                }
            }
        }
    }

    @media only screen and (max-width: $sm - 1) {
        .checkout-shipping{
            .payment-method{
                clear: both;
            }
        }
        .cc_order_details, .cc_checkout_review{
            .cart_item {
                .item-title-wrapper{
                    margin: 8px -4px 0 0;
                }
                .cc_item_title{
                    text-align: right;
                }
                .cc_price{
                    padding: 0 0 18px 0; 
                }
            }
        }
        .cc_order_details, .cc_checkout_review{
            .cart_item_ar > div:nth-child(2){
                padding-right: 0px !important;
                width: 100%;
            }
        }

        .cc_stored_payments_container.cc .cc_stored_payment_display.payment_name .cvv_input{
            padding-right: 0 !important;
            padding-left: 10px !important;
        }
    }

    .cc_stored_payments_container.cc .cc_stored_payment_display.payment_name{
        text-align: right;
        margin-left: 0;
        margin-right: 32px;
    }

    .paymentTypeContainer {
        .checkcontainer {
            line-height: 1.7;
            padding: 0px 30px;
        }
    
        .ccPaymentOuterContainer > ul,
        .ccPaymentOuterContainer > ol {
            margin: 0px 25px 0px 0px;
        }
    
        li {
            text-align: right !important;
        }
    }

    .cc_checkout_review {
        .cc_feeatured_products {
            .go_to_PDP.icon-ic_arrow_more {
                align-self: flex-end !important;
                transform: rotateY(180deg);
            }
        }
    }
}

.makeCCPayment {
    margin-top: 15px;
}

.cc_po_action_buttons {
    .cc_action_buttons {
        width: 100%;
    }
}

.checkoutContent #overlay {
    display: none !important;
}

.checkout-overlay {
    position: fixed; 
    background-color: $color-03; 
    opacity: 0.4; 
    width: 100%; 
    height: 100%; 
    z-index: 5000; 
    top: 0px; 
    left: 0px; 
    right: 0px; 
    bottom: 0px;
}

.checkoutPaymentTarget .cc-payment-form{

    @media only screen and (min-width: $sm) {
        >.form-group, .payment-form-top, .payment-form-bottom{
            max-width: 50%;
        }
    }
    
    .form-group{
        margin-bottom: 20px;
    }
}

.cc_stored_payments_container  {
    .payment_image_container {
        width: 100px;
        height: 40px;
        text-align: center;
        display: inline-block;
    }
    
    .payment_image {
        height: 100%;
        width: 70px;
    }

    .payment_name { 
        line-height: 40px;
        padding: 0px;
    }

    .checkmark.stored_payment_checkmark {
        top: 50%;
        transform: translateY(-50%);
    } 
}

.cc_stored_payments_container.cc {
    .cc_stored_payments_selector {
        display: inline-flex;
        align-items: center;
        background-color: $gray-1;
        border-radius: 5px;
        height: 60px;
        justify-content: flex-start;
        padding: 0px 15px;

        &_selected {
            background-color: $color-20;
        }
    }
    .cc_stored_payment_display.payment_name {
        display: flex;
        align-items: center;
    }

    .cc_payment_details {
        width: 350px;
        display: inline-flex;
        justify-content: space-between;
    }

    .cc_type {
        line-height: 1.5;
    }

    @media only screen and (max-width: 600px) {
        .payment_image_container {
            height: auto;
        }

        .cc_payment_details {
            width: 150px;
            flex-direction: column;
            line-height: 1.75;
            margin: 0px 25px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .cc_shipping_payment .shipping_payment_term  {
        justify-content: center !important;
        align-items: center;
    }

    .cc_checkout_user_info .userInformation.cc_user_information,
    .checkout-container .checkout-shipping .shipping-buttons {
        margin: 0px !important;
    }

    .cc_checkout_user_info .cc_link_button,
    .link_button.coso_proceed {
        flex-direction: column-reverse;

        .cc_proceed,
        .cc_process_user_info {
            margin-bottom: 20px;
        }
    }

    .cc_po_action_buttons .cc_action_buttons,
    .action_buttons_container {
        display: flex;
        flex-direction: column-reverse;

        .btn.paymentBtn {
            margin: 5px 0px 15px;
        }
    }

    .shipping_payment .col-xs-12.col-md-6 {
        float: none;
    }
}

.co-step4 {
    display: none !important;
}

.cvv_input {
    width: 80px !important;
    padding: 0px 10px !important;
    font-style: italic !important;
    border-radius: 10px !important;
}

@media only screen and (min-width: $sm) and (max-width: $md - 1) {
    .cc_checkout_review,
    .reviewCartSection  {
        .cart_item {
            .row.pb-1 {
                width: 70% !important;

                .col-xs-3 {
                    width: auto !important;
                }
            }
            .product-img-wrapper {
                width: 110px !important;
            }
            .col-xs-9.col-md-6 {
                width: 60% !important;
            }
            .row.pb-1 + .row {
                display: flex !important;

                .narrow.col-xs-4 {
                    width: 20% !important;
                    padding: 0px 10px !important;
                }

                .col-xs-9 {
                    width: 80% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: $sm - 1) {
    .makeCCPayment {
        margin: 0px 0px 20px !important;
    }
    
    .cc_stored_payments_container.cc {
        margin: 25px 0 15px;

        .radio{
            margin: 0;
        }

        .cc_stored_payments_selector  {
            background: transparent;
            border-radius: 0;
            width: 100%;
            padding: 0;
            height: auto;
            align-items: normal;
            display: block;
        }

        .checkmark.stored_payment_checkmark{
            top: 0;
            transform: translateY(0%);
        }

        .cc_stored_payment_display.payment_name{
            display: block;
            margin-right: 0;
            line-height: normal;

            .payment_image_container{
                display: inline-block;
                width: auto;
                line-height: normal;
                vertical-align: top;

                img {
                    width: auto;
                    height: 22px;
                }
            }
            .cc_payment_details{
                display: inline-block;
                width: auto;
                margin: 0 10px;

                .cc_cardInfo, .cc_name{
                    display: block;
                }
                .cc_endDate, .cc_paymentType{
                    display: inline-block;
                } 
                .cc_paymentType{
                    text-transform: capitalize;
                }
            }

            .card-cvv-title{
                font-size: $s14px;
                line-height: normal;
                font-weight: normal;
                margin: 5px 0 0;
            }
            .cvv_input{
                width: 100% !important;
                padding-left: 0 !important;
                border-radius: 0 !important;
                font-style: normal !important;
                font-weight: normal;
                color: $color-02;
            }
        }

        .cc_stored_payments_selector_selected{
            background: transparent;
        }
    }

    .page-layout--arabic{
        .checkout-nav-steps{
            .cc_checkoutStep::after{
                left: -50%;
                right: auto;
            }
        }
    }

    .checkoutContainer{
        #newWalletForm{
            .cc_po_label_name{
                margin-top: 20px;
            }
        }
    }
}