/* Slick slider OSF customized */

.osf-slider-wrap{
    .osf-slider-prev,
    .osf-slider-next {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50px;
        width: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        z-index: 10;
        outline: none;

        @media only screen and (max-width: $sm - 1) {
            height: 35px;
            width: 35px;
        }
    }

    .osf-slider-prev{
        background-image: url(../images/splash_arrow_left.svg);
        left: 22px;

        @media only screen and (max-width: $sm - 1) {
            left: 10px;
        }
    }
    .osf-slider-next{
        background-image: url(../images/splash_arrow_right.svg);
        right: 22px;

        @media only screen and (max-width: $sm - 1) {
            right: 10px;
        }
    }

    .osf-slider-item{
        outline: none;

        .item-helper{
            box-shadow: none;
            border: 1px solid $gray-1;
        }
    }
}

.page-layout--arabic {
    .osf-slider-prev{
        background-image: url(../images/splash_arrow_right.svg) !important;
        left: unset !important;
        right: 22px !important;

        @media only screen and (max-width: $sm - 1) {
            right: 10px !important;
        }
    }
    .osf-slider-next{
        background-image: url(../images/splash_arrow_left.svg) !important;;
        left: 22px !important;
        right: unset !important;

        @media only screen and (max-width: $sm - 1) {
            left: 10px !important;;
        }
    }
}