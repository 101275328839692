/*** Variables ***/


/*** BREAKPOINTS ***/

$sm: 768px !default;
$md: 992px !default;
$lg: 1200px !default;

/*** Colors ***/

$color-01: #fbbc2a;
$color-02: #3E4348;
$color-03: #000000;
$color-04: #909090;
$color-05: #BEC1C4;
$color-06: #EBEBEB;
$color-07: #007AC5;
$color-08: #2054FF;
$color-09: #D40141;
$color-10: #6AC259;
$color-11: #fdd780;
$color-12: #E4E6E7;
$color-13: #878A8C;
$color-14: #4A4A4A;
$color-15: #F9F9F9;
$color-16: #C5C5C5;
$color-17: #676767;
$color-18: #B3B3B3;
$color-19: #FFC31B;
$color-20: #00BCD4;
$color-21: #41464b;
$gray-1: #F1F3F4;
$gray-2: #B5B5B5;
$orange-1: #E37A00;
$color-white: #FFFFFF;
$color-blue1: #109be7;

/*** Fonts ***/

%font-light {
    font-weight: 300;
}

%font-regular {
    font-weight: 400;
}

%font-medium {
    font-weight: 500;
}


/*** Font Sizes ***/

$s40px: 2.5rem;
$s37px: 2.3rem;
$s32px: 2rem;
$s30px: 1.875rem;
$s29px: 1.8rem;
$s26px: 1.625rem;
$s24px: 1.5rem;
$s23px: 1.4375rem;
$s22px: 1.375rem;
$s20px: 1.25rem;
$s19px: 1.1875rem;
$s18px: 1.125rem;
$s16px: 1rem;
$s15px: 0.9375rem;
$s14px: 0.875rem;
$s12px: 0.75rem;
$s10px: 0.625rem;

/*** Button sizes ***/

$btn-sm-h: 2.375rem;
$btn-md-h: 2.875rem;
$btn-lg-h: 3.5rem;