.cc_home_slider {
    background-color: transparent;
    #breadcrumb_desktop_container {
        position: relative;
        // top: 5.5625rem;

        @media only screen and (min-width: $lg) {
            width: 1330px;
        }

        .breadcrumb {
            padding: 8px 0px 8px;
            margin: 20px 0px;
            list-style: none;
            background-color: transparent;
            border-radius: 0;

            > li + li:before{
                padding: 0 0px 0 5px;
            }
        }

        @media only screen and (max-width: $md - 1) {
            // top: 4rem;

            .breadcrumb {
                margin: 20px 0px;
            }
        }
    }

    .br-hr {
        margin-top: 0px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid $color-06;;
    }

}

/*@media only screen and (max-width: $sm - 1) {
    .cc_home_slider {
        display: none;
    }
}*/
