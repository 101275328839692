#login_main_content {
    display: flex;
    align-items: stretch;
    height: 100%;
    line-height: 1.14;

    @media only screen and (max-width: $sm - 1) {
        margin-top: 24px;
    }

    & > .row {
        margin: 0;
    }
    .cc_user_login {
        margin-top: -15px;
    }
    .panel-heading {
        padding: 0;
    }
    .cc_user_login {
        margin-bottom: 0;
        padding-bottom: 0;
    }
    h3 {
        color: $color-02;
        padding-bottom: 6px;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
    }
    .cc_login {
        color: $color-02;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.4;
        margin-bottom: 0;
        padding-bottom: 2px;
    }
    .loginInput {
        opacity: 0.7;
        line-height: 1.17;
        padding-top: 6px;

        &:active,
        &:focus {
            color: $color-03;
        }
    }
    .form-group {
        padding-bottom: 32px;
        margin-bottom: 0;
    }
    form {
        margin-bottom: 0;
    }
    .cc_login_button {
        margin-top: 8px;
    }
    .cc-register {
        margin-top: 40px;
    }
    .cc_forgot_password {
        margin-top: 32px;
        margin-bottom: 0;
    }
    hr {
        margin-top: 32px;
        margin-bottom: 22px;
    }
    .cc_create_account {
        font-size: 14px;
        font-weight: 300;
        color: $color-02;
        line-height: 1.5;
    }
    .cc_register_account {
        margin-bottom: 110px;
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-05;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-05;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-05;
    }
}

.cc_main_container--ar{
    #login_main_content {
        #loginFormDsk{
            .form-group{
                text-align: right;
            }
        }
    }
}
