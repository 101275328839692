body.is-static-page{

    .cc_content_body{
        padding-top: 0;
    }

    .cc_main_container, .cc_main_container.cc_tmpl_OneColRD.cc_main_content_col{
        padding: 0;

        .static-page {
            opacity: 1;
        }
    }
}  

.static-page {
    color: $color-02;

    &--copyright{
        opacity: 0;
        transition: opacity 0.1s;

        .static-page__header {
            position: relative;
            padding: 32px 0;
            min-height: 25vh;
            background-image: url('https://www.imdad.com/Copyrightsurl/-/media/Corporate-images/Terms_Header.jpg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            z-index: 1;

            @media only screen and (min-width: $lg) {
                padding: 64px 0;
                min-height: 340px;
            }

            &:after {
                content: '';
                background: rgba(62,67,72,.7);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
            }

            h1 {
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                font-size: 40px;
                bottom: 40px;
                margin-bottom: 4px;
                z-index: 3;
                color: $color-white;
            }

            p{
                margin: 0 0 24px;
            }
        }

        .static-page__content{
            padding: 64px 15px;
            color: rgba(0, 0, 0, 0.9);

            @media only screen and (max-width: $lg - 1) {
                padding: 55px 30px;
            }
            @media only screen and (max-width: $sm) {
                padding: 40px 20px;
            }
            @media only screen and (max-width: $sm - 1) {
                padding: 35px 30px;
            }
        }
    }
    &--copyright-ar {
        li {
            padding-left: 0;
            padding-right: 24px;
    
            &:before {
                right: 0;
            }
        }
    }

    .cc_breadrumb_container{
        border-bottom: 1px solid $color-06;
        width: 100%;
        padding: 0;

        .cc_breadcrumb{
            padding: 8px 0 8px;
            margin: 20px auto;
            max-width: 1300px;
            list-style: none;
            background-color: transparent;
            border-radius: 0;

            @media only screen and (max-width: $lg - 1) {
                max-width: 970px;
                padding: 8px 15px;
            }
            @media only screen and (max-width: $sm) {
                max-width: 750px;
                padding: 8px 20px;
            }
            @media only screen and (max-width: $sm - 1) {
                padding: 8px 30px;
            }

            li {
                padding: 0;
            }
        }
    }

    &__header {
        h1 {
            font-size: $s30px;
            line-height: 1.2;

            @media only screen and (max-width: $sm - 1) {
                font-size: $s23px;
            }
        }

    }
        
    &__content{
        max-width: 1330px;
        padding: 0 15px 30px;
        font-size: $s14px;
        margin: 0 auto;

        h1{
            margin-bottom: 20px;
            font-size: $s30px;
        }

        h3{
            margin: 12px 0;
            font-size: $s20px;
        }

        h1 + h3 {
            padding: 10px 0;
        }

        @media only screen and (max-width: $lg - 1) {
            max-width: 970px;
            padding: 0 30px 20px;
        }
        @media only screen and (max-width: $sm) {
            max-width: 750px;
            padding: 0 20px 20px;
        }
        @media only screen and (max-width: $sm - 1) {
            padding: 0 30px 20px;
        }

        p{
            margin: 0 0 12px;
        }

        ul {
            margin-top: 24px;
            margin-bottom: 24px;
            padding: 0;
            width: 100%;
        }

        li {
            position: relative;
            list-style: none;
            padding-left: 24px;

            &:before {
                width: 8px;
                height: 8px;
                top: 9px;
                position: absolute;
                left: 0;
                content: '';
                border: 0;
                border-radius: 0;
                background: $color-01;
            }
        }
    }

    &--ar{
        .static-page__content{
            li{
                padding-left: 0;
                padding-right: 24px;

                &:before{
                    left: auto;
                    right: 0;
                }
            }

            @media only screen and (max-width: $lg - 1) {
                padding-right: 15px;
            }
            @media only screen and (max-width: $sm) {
                padding-right: 20px;
            }
            @media only screen and (max-width: $sm - 1) {
                padding-right: 30px;
            }
        }
    }
}

.cc_main_container--ar{
    .static-page__content{
        li{
            padding-left: 0;
            padding-right: 24px;

            &:before{
                left: auto;
                right: 0;
            }
        }
    }
}