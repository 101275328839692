.prodDetailContainer {
    @media only screen and (min-width: $md) {
        padding: 0 35px 0 0;
    }

    @media only screen and (min-width: $lg) {
        margin-left: -15px;
    }

    .tabSection {
        .cc_nav-tabs {
            border-bottom: none;
            text-align: center;
            background-color: $color-02;
            z-index: 100;
            white-space: nowrap;
            width: 100% !important;
            padding: 0;

            >li {
                float: none;
                display: inline-block;
                margin-bottom: 0;

                >a {
                    border: none;
                    margin: 0;
                    color: $color-white;
                    padding: 2rem 1rem;
                    cursor: pointer;
                    background-color: transparent;

                    @media only screen and (min-width: $md) {
                        &:focus,
                        &:hover {
                            background-color: transparent;
                            color: $color-01;
                        }
                    }

                    p {
                        margin: 0;
                    }
                }

                &.active {
                    >a {
                        color: $color-01;
                        border: none;
                        border-bottom: 3px solid $color-01;
                        background-color: transparent;

                        &:focus,
                        &:hover {
                            color: $color-01;
                            border: none;
                            border-bottom: 3px solid $color-01;
                        }
                    }
                }
            }
        }

        .tab-content {
            .pane-title {
                margin-bottom: 1.5rem;
                color: $color-02;
            }

            // .secContentDescription ol,
            .secContentDescription ul {
                margin: 10px 0 15px 25px;
            }

            .specSection {
                padding: 3.5rem;
                background-color: $gray-1;

                .pdp-spec-sec {
                    .groupName {
                        color: $color-04;
                        font-size: $s15px;
                        margin-bottom: 2.8125rem;
                    }

                    .spec-name {
                        color: $color-02;
                        font-size: $s19px;
                    }

                    .spec-value {
                        color: $color-04;
                        font-size: $s20px;
                    }

                    .spec-sec-hr {
                        border-top: 1px solid $color-12;
                        margin: 2.0625rem 0;
                    }
                }
            }

            .pane-hr {
                margin: 2.5rem 0;
            }

            .cc_tab-pane:last-child hr:last-child {
                display: none;
            }
        }
        .panel-body {
            padding-top: 20px;
        }
    }

    .addItem {
        width: 100%;
        &:focus {
            background-color: $color-01;
            border-color: $color-01;
        }
        &:hover {
            background-color: $color-11;
            border-color: $color-11;
        }
    }

    .price_block.cc_price_block {
        font-size: $s20px;
        color: $color-04;
        @extend %font-medium;
    }

    .tier-info-box-mobile {
        display: none;

        @media only screen and (max-width: $sm - 1) {
            display: block;
        }
    }

    .product_detail {
        padding: 0;

        .inventory {
            margin-bottom: 1rem;
            font-size: $s14px;

            .STOCK {

                &_LOW,
                &_UNAVAILABLE {
                    color: $orange-1;
                }

                &_AVAILABLE {
                    color: $color-10;
                }
            }
        }

        .pdp-price {

            &-mainprice {
                line-height: 1.21;
                margin-bottom: 1rem;
                font-size: 1rem;

                &-value {
                    display: flex;
                    justify-content: space-between;
                    font-size: $s16px;
                }
                .mp-val,
                .mp-val + span:not([class]) {
                    text-decoration: line-through;
                    font-size: 1rem;
                }
            }

            &-savingprice {
                color: $color-10;
                line-height: 1.19;
                font-size: $s16px;
                margin-left: 1.5rem;
                font-size: normal;
            }

            &-taxprice {
                line-height: 1.19;
                font-size: $s16px;
                margin-bottom: 1.8125rem;
            }

            &-totalprice {
                color: $color-02;
                font-size: $s20px;
                line-height: 1.17;
                margin-bottom: 2.25rem;
                @extend %font-medium;

                .mp-each {
                    display: inline-block;
                    margin-right: 5px;
                }

                .inc-vat,
                .mp-each {
                    color: $color-04;
                    font-size: $s16px;
                    line-height: 24px;
                    @extend %font-medium;
                }
            }

            .mp-each {
                text-decoration: none !important;
                display: inline-block;
            }
        }

        .cc_agg_section{
            .dropdown-toggle .filter-option-inner-inner{
                color: $color-02;
            }
            .dropdown-menu>li>a{
                color: $color-02;
            }
        }
    }

    .pdp-item {
        &-title {
            @extend %font-medium;
            font-size: $s30px;
            color: $color-02;
            margin-top: 0;
        }

        &-desc {
            font-size: $s16px;
            color: $color-02;
        }
    }

    .cc_alt_image_modal {
        .modalImg {
            width: 100%;
            max-width: 500px;
        }
    }

    .related-product-wrap {

        .cc_data_link, .cc_cart_item {
            outline: none;
        }

        .cc_cart_item{
            height: 210px;
            background-color: $gray-1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .cart {
            &-item {
                margin-top: 1.25rem;

                @media only screen and (min-width: $sm) {
                    min-height: 5.25rem;
                }

                a {
                    @extend %font-medium;
                    font-size: $s18px;
                    color: $color-04;
                    padding: 0 1rem;
                    display: block;

                    @media only screen and (max-width: $sm - 1) {
                        font-size: $s16px;
                        font-weight: 500;
                        line-height: 1.19;
                        color: $color-04;
                    }

                    @media only screen and (min-width: $sm) {
                        min-height: 75px;
                    }
                }
            }

            &-price {
                @extend %font-medium;
                font-size: $s20px;
                color: $color-02;
                margin: 0;
                padding: 0 1rem;

                @media only screen and (max-width: $sm - 1) {
                    font-size: $s16px;
                    font-weight: normal;
                    line-height: 1.19;
                    color: #41464b; // CAVE: no official CI color
                }

                &-wrap {
                    min-height: 1.75rem;
                }
            }
        }

        .prod-desc {
            padding: 0 1rem;
        }

        .item-helper {
            padding: 0;
            min-height: 297px;

            @media only screen and (min-width: $sm) {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                margin-bottom: 20px;
            }
        }

        .prodDetRel {
            max-height: 100%;
        }

        .icon-wrap {
            text-align: right !important;
            padding: 1rem 2rem;

            .icon {
                cursor: pointer;

                .icon-ic_arrow_more {
                    font-size: $s26px;
                    color: $color-01;
                }
            }
        }
        .cc_prod_name {
            overflow: hidden;
        }
    }

    .widget_container {
        h3 {
            font-size: $s30px;
        }
    }

    .videoSection {
        .video {
            &-wrapper {
                padding-bottom: 30px;
            }
        }

        .modal {
            &-content {
                padding: 0;
                height: unset;
            }

            &-body {
                padding: 0
            }
        }

        svg {
            cursor: pointer;
        }

        .col-md-4:first-child {
            padding-left: 0;
        }
    }

    .pdfSection {
        display: flex;

        .product-doc-wrap {
            margin: 0px 35px 0px 0px;

            &_ar {
                margin: 0px 0px 0px 35px;
            }
        }

        .pdf {
            &-name {
                display: flex;
                align-items: center;
                font-size: $s20px;
                color: $color-02;
                @extend %font-medium;
                cursor: pointer;
                text-decoration: none;

                svg {
                    cursor: pointer;
                    margin-right: 1.5rem;
                }
            }
        }

        .col-md-4:first-child {
            padding-left: 0;
        }
    }

    .pdp-spec-sec {
        @extend %font-medium;

        .groupName {
            color: $color-04;
            font-size: $s15px;
            line-height: $s18px;
            text-transform: uppercase;
            margin-bottom: 2.8125rem;
        }

        .cc_name {
            color: $color-02;
            font-size: $s19px;
            line-height: $s23px;
        }

        .cc_value {
            color: $color-04;
            font-size: $s20px;
            line-height: $s24px;
        }

        hr {
            border-top-color: $color-12;
            margin: 2.0625rem 0;
        }

        .nvRow {
            padding: 0;

            .spec {

                &-name,
                &-value {
                    padding: 0
                }
            }

            hr {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    .quantity_block {
        margin-top: $s12px;
        z-index: 1;
        width: auto;

        @media only screen and (max-width: $md) {
            margin-top: 0;
            margin-bottom: $s12px;
        }

        .cc_quantity_block {
            padding: 0;
    
            .pdp-qty-control {
                margin-left: 4px;
            }
        }

        input#qty {
            font-size: $s16px;
            font-weight: normal;
            line-height: 1.5;
            text-align: center;
            color: $color-02;
            border-color: $color-05;
        }

        .pdp-qty {
            text-transform: uppercase;
            color: $color-04;
            font-size: $s16px;
            margin-top: 2px;
            margin-left: -28px;
            margin-right: $s12px;
        }
    }

    .secRelatedCrossSell_container {
        margin-bottom: 42px;
    }

    .additional-discount-info {
        display: block;
        color: $color-08;
        font-size: $s12px;
        line-height: 1.14;
        margin-top: 6px;
    }

    .mobileIncludedQuantity{
      margin: 10px 0 10px -5px;
    }
}

.cc_main_container--ar .prodDetailContainer {
    @media only screen and (min-width: $md) {
        padding: 0 0 0 35px;

        .addItem{
            float:left;
        }
    }

    @media only screen and (min-width: $lg) {
        margin-left: 0;
        margin-right: -15px;
    }

    .quantity_block{
        float: right;

        .pdp-qty{
            float: right;
            margin-right: -28px;
            margin-left: 12px;
        }
    }

    .wishButtons{
        text-align: left;
    }

    .pdp-item .btn-group .dropdown-menu{
        text-align: right;
    }

    .icon-ic_arrow_more{
        transform: rotate(180deg);
    }

    .tabSection .tab-content .specSection .pdp-spec-sec .spec-value{
        text-align: left !important;
    }

    // .tabSection .secContentDescription ol,
    .tabSection .secContentDescription ul {
        margin: 10px 35px 15px 0px;
    }

    .pdfSection .pdf-name svg{
        margin-right: 0;
        margin-left: 1.5rem;
    }

    .pdp-qty-control{
        float: left !important;
    }
    .pdp-aggr-selection{
        .filter-option{
            text-align: right;
            float: right;
            padding-right: 0;
            padding-left: 8px;
        }
        .caret{
            left: 12px;
            right: auto;
            margin-left: 0;
            margin-right: 10px;
        }
        li a{
            text-align: right;
        }
    }

    .includedItemsSection{
        h3.cc_title{
            text-align: right;
        }

        .icon-wrap{
            float: left !important;
        }

        .cc_widget_column{
            > div{
                float: right;
            }

            .cc_quantity{
                direction: ltr;
                text-align: right;
            }
        }
    }

    .pdp-price-savingprice{
        margin-left: 0;
        margin-right: 1.5rem;
        display: inline-block;
    }

    @media only screen and (max-width: $sm - 1) {
        .product_title.cc_product_title{
            text-align: right;
        }
        .shortDesc.cc_short_desc{
            text-align: right;
        }
        .quantity_block .pdp-qty{
            width: auto;
            margin-left: 0px;

            &.aggregated{
                margin-left: 0px !important;
            }
        }
        .wishButtons{
            text-align: center;
        }
        .tabSection .tab-content{
            h1.pane-title{
                text-align: right;
            }
            p.secContentDescription{
                text-align: right;
            }
        }
        
        .product_detail_item .tier-wrap .tier-outerbox{
            &:nth-child(2n+1){
                padding-left: 2px;
                padding-right: 15px;
            }
            &:nth-child(2n) {
                padding-left: 10px;
                padding-right: 2px;
            }
        }
        .cc_prt_heading_label{
            text-align: right;
        }
        .widgetSection{
            .cc_title{
                text-align: right !important;
            }
        }
    }

    .related-tiered-product-wrap{
        .related-tiered-product-tile{
            float: right;
        }
    }
}

.osf_pdp_tmpl.cc_main_container--ar{
    @media only screen and (max-width: $md) {
        .cc_main_content_col, .cc_left_col{
            float: none !important;
        }
    }
}

.categoryName {
    .pdp-ctg-name {
        color: $color-02;
        text-transform: uppercase;
    }
}

.osf_pdp_tmpl {
    .wishButtons {
        @media only screen and (max-width: $sm - 1) {
            margin-top: 1.5rem;
            text-align: center;
            // border-bottom: solid 1px $color-06;

            button.btn-sm {
                height: 1.375rem;
            }

            margin-right: -15px;
        }

        text-align: right;
        margin-top: 1.5rem;

        .dropdown-menu {
            li {
                a.pickWish {
                    padding: 3px 14px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    } 

    .widgets-container {
        padding-top: 0 !important;

        .minicart {
            background-color: $color-white;
            padding: 0;
            display: block;
            margin-bottom: 2.5rem;

            .panel {
                @extend %font-medium;
                font-size: $s16px;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.19;
                letter-spacing: normal;
                border: solid 2px $color-06;
                background-color: $color-white;
                padding: 1.25rem;
                margin: 0px;

                .panel-heading {
                    // text-align: center;
                    position: relative;
                    text-transform: uppercase;

                    .glyphicon {
                        font-size: $s20px;
                    }
                }

                .down-arrow {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: right;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    width: 40px;
                }

                .panel-title {
                    // text-align: center;
                    display: inline-block;
                    color: $color-02;
                    margin-left: 1rem;
                }

                .collapse.in {
                    min-height: 5rem;
                }
            }

            .cc_prod_link,
            .cc_product_name_link {

                &,
                &:link {
                    font-weight: 400;
                    color: $color-02;
                    text-decoration: none;
                    font-size: inherit;
                    text-align: left;
                    cursor: pointer;
                }

                &:hover {
                    color: $color-01;
                }
            }

            .summary {
                border-top: none;
            }

            .cc_cart_item_count,
            .cc_subtotal {
                color: $color-02;
                font-size: $s20px;
            }

            .cc_item_heading,
            .cc_row_total,
            .sku,
            .updateCartButton {
                display: none;
            }

            .loggedInAction {
                font-size: $s14px !important;
                font-weight: 400;
                line-height: 1.14;
                text-transform: capitalize;
                padding: 0;
                text-align: right;
                color: $color-08;
            }
        }

        .no_items {
            @extend %font-light;
            line-height: 1.5;
            text-align: center;
            color: $color-04;
        }

        .gp_btn_reorder {
            width: 100%;
        }

        .cc_items {
            margin-bottom: 1rem;

            .cc_prod_name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .promotion-box-LeftNav {
        padding: 0;

        .cc_promo_img {
            width: 100%;
        }
    }

    .rfq-label-wrap {
        color: $color-04;
        font-size: $s16px;
        margin-bottom: 2rem;
    }

    .unit-of-measure {
        margin-bottom: 1rem;
        font-size: $s14px;
        color: $color-02;
    }

    .pdp-aggr-selection {
        button.btn-default {
            background-color: $gray-1;
            border: solid $color-06 2px;
            height: 38px;
            padding: 0.5rem;
        }

        .dropdown-menu {
            border-radius: 0;

            li {
                a {
                    padding: 0.5rem;
                }
            }

            &>.active {
                &>a {
                    color: $color-02;
                    background-color: $gray-1;
                }
            }
        }
    }

    .tier {
        &-outerbox {
            text-align: center;
            font-size: $s12px;
            padding: 0 0.625rem;

            @media only screen and (min-width: $md - 1) {
                padding-right: 0;
            }

            @extend %font-medium;

            .cc_prt_tier_box_text_range {
                @extend %font-regular;
            }
        }

        &-innerbox {
            border: solid 2px $color-06;
            margin-left: 0.3125rem;
            margin-bottom: 1.3125rem;
            padding: 0.75rem 0;
            color: $color-02;

            @media only screen and (min-width: $md - 1) {
                margin-bottom: 0.8125rem;
            }

            &.cc_prt_tier_box_selected {
                border: solid 2px $gray-1;
            }

            .cc_prt_tier_box_text_price {
                font-weight: bold;
            }
            
            .i-vat {
                font-weight: normal;
            }

            b{
                font-family: 'Roboto', sans-serif;
            }
        }
        

        &-title {
            font-size: $s14px;
            text-transform: uppercase;
            @extend %font-medium;
            color: $color-02;
        }

        &-info {
            &-container > .tier-info-box-wrap {
                display: none;
            }

            &-text {
                font-size: $s16px;
                color: $color-02;
                line-height: 1.19;
                margin-top: -1.5rem;
            }

            &-box {
                margin: 0;
                @extend %font-medium;
                font-size: $s16px;
                line-height: 1.19;
                
                @media only screen and (max-width: $sm - 1) {
                    font-size: $s14px;
                    line-height: 1.14;
                    text-align: center;
                    color: $color-10;
                }

                &-wrap {
                    background-color: $gray-1;
                    color: $color-10;
                    font-size: $s15px;
                    padding: 1rem;
                    margin-top: -40px;
                    margin-bottom: 2rem;

                    @media only screen and (max-width: $sm - 1) {
                        margin-top: -10px;
                    }
                }
            }
        }

        &-hr {
            margin: 2.5rem 0;
            border-top: 2px solid $color-06;
        }
    }

    .tierInf {
        hr {
            display: none;
        }
    }

    .icon {
        cursor: pointer;

        &-ic_arrow_more {
            font-size: $s26px;
            color: $color-01;
        }
    }

    .bg-info {
        background-color: $color-white;
    }

    .bg-highlight {
        background-color: $gray-1;
        border: solid 2px $gray-1;
    }

    @media only screen and (min-width: $md) {
        .cc_prt_outer_row{
            margin-right: 0;
        }
        .wishButtons.cc_plus_minus{
            padding-right: 0;
        }
        .addItem{
            max-width: 230px;
            float: right;
        }
    }
    .pdp-qty-control {
        display: flex !important;

        &-btn {
            background-color: transparent;
            border: none;

            &:focus {
                border: none;
                outline: none;
            }
        }

        &-icon {
            font-size: $s12px;
        }
    }
}


//IE
_:-ms-fullscreen,
:root .wishButtons .dropdown .dropdown-toggle {
    margin-left: -25px;
    margin-top: 0;
    min-width: 0;
}

_:-ms-fullscreen,
:root .wishButtons .icon-ic_arrow_down_small {
    margin-left: 24px;
    margin-top: 4px;
}

_:-ms-fullscreen,
:root .includedItemsSection .cc_quick_wishlist_item {
    display: block !important;
}

//  Mozilla
@supports (-moz-appearance:none) {
    .wishButtons .icon-ic_arrow_down_small {
        margin-top: 1px;
    }
}

.container.cc_main_container.osf_pdp_tmpl {
    margin-top: 0px;

    .pdp-ctg-name {
        margin-top: 0px;
    }
}

.tier-inf-desktop {
    display: block;
}
.tier-inf-mobile {
    display: none;
}

@media only screen and (max-width: $sm - 1) {
    .tier-inf-desktop {
        display: none;
    }
    .tier-inf-mobile {
        display: block;

        .tier-info-container {
            margin-top: 5px;
            padding-top: 25px;
            padding-bottom: 25px;
            border-top: 2px solid $color-06;
        }

        .tier-info-box-wrap{
            margin-bottom: 20px;
            margin-top: 0;

            .tier-info-box{
                font-size: $s14px;
                line-height: 1.14;
                font-weight: normal;
            }
        }

        .quick_wishlist {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

            .featured_prod_item{
                margin-bottom: 0 !important;
            }
        }

        .tier-hr {
            margin: 25px 0;
            display: none;
        }
    }
    .osf-slider-wrap{
        .osf-slider-prev{
            left: 10px;
        }
        .osf-slider-next{
            right: 10px;
        }
    }

    .osf-imageslider-wrap {
        .osf-slider-prev,
        .osf-slider-next {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 50px;
            width: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background-color: transparent;
            z-index: 10;
            outline: none;

            @media only screen and (max-width: $sm - 1) {
                height: 35px;
                width: 35px;
            }
        }

        .osf-slider-prev {
            background-image: url(../images/splash_arrow_left.svg);
            left: 10px;
        }
        .osf-slider-next {
            background-image: url(../images/splash_arrow_right.svg);
            right: 10px;
        }

        .osf-imageslider-item {
            height: 290px;
            max-height: 290px;
            display: flex;
            justify-content: center;
            background-color: $gray-1;
            margin: 0 15px;
            padding: 0;

            img{
                max-height: 100%;
            }

            .item-helper {
                box-shadow: none;
                border: none;
            }
        }
    }

    .prodDetailContainer {
        .cc_product_detail_container {
            margin: 0;
            padding: 0;
        }

        .product_title.cc_product_title {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: $color-02;
            margin-top: 20px;
            margin-bottom: 24px;
        }

        .shortDesc.cc_short_desc {
            font-size: 14px;
            font-weight: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: left;
            color: $color-02;
        }

        .cc_prt_heading_label {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: left;
            color: $color-02;
        }

        .cc_main_prod_image--hidden {
            display: none;
        }

        .addItem {
            color: $color-02;
        }

        .product_type_aggregate {
            .row .price_block {
                display: none;
            }
            #price-mobile .price_block {
                display: block;
            }
        }

        .product_detail {
            &_item {
                .rfq-label-wrap {
                    color: $color-02;
                    font-size: $s14px;
                }

                .pdp-price-mainprice {
                    .mp-val,
                    .mp-val + span:not([class]) {
                        text-decoration: line-through;
                        font-size: $s16px;
                        font-weight: 500;
                        color: $color-04;
                    }

                    .mp-each {
                        font-size: $s16px;
                        font-weight: 500;
                        color: $color-04;
                    }
                }

                .tier-wrap .tier-outerbox:nth-child(2n+1) {
                    padding-left: 8px;
                    padding-right: 2px;
                }
                .tier-wrap .tier-outerbox:nth-child(2n) {
                    padding-left: 2px;
                    padding-right: 16px;
                }

                .tier-wrap .tier-outerbox {
                    margin-bottom: -12px;

                    & .i-vat {
                        display: none;
                    }
                }

                .inventory .value p,
                .price .pdp-price-savingprice {
                    font-weight: 300;
                }
                .price_block {
                    .tier-info-text {
                        font-size: $s14px !important;
                        line-height: 1.14;
                        color: $color-02;
                        margin-top: $s24px;
                        margin-bottom: $s16px;
                        font-weight: normal;
                    }
                    .pdp-price-totalprice {
                        margin-bottom: 12px;
                        margin-top: 12px;

                        .cc_aggPriceValue, 
                        .cc_aggPriceLabel {
                            font-size: $s18px;
                            font-weight: 500;
                            line-height: 1.17;
                            color: $color-02;
                        }

                        .pdp-total-amount {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 1.17;
                            color: #41464b;
                        }

                        .inc-vat {
                            line-height: 1.14;
                            font-weight: 500;
                            font-size: $s14px;
                        }

                        .additional-discount-info {
                            font-size: $s14px;
                            font-weight: 500;
                            line-height: 1.21;
                            color: $color-10;
                            margin-top: $s24px;
                        }
                    }
                }
                .pdp-qty.aggregated {
                        margin-left: -30px !important;
                }

                .unit-of-measure .uom span {
                    font-size: 14px;
                    line-height: 1.14;
                    text-align: left;
                    color: $color-04;
                }

                .cc_agg_selection {
                    .dropdown-menu li a span.text {
                        white-space: pre-wrap;
                    }

                    .dropdown-toggle .filter-option-inner-inner {
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }

        .includedItemsSection {
            h3.cc_title {
                border-bottom: 0;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2;
                text-align: left;
                color: $color-02;
            }

            .widget_column.cc_widget_column {
                margin: 0;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
                padding: 4px 8px 0 2px;
                margin-bottom: 12px;

                .cc_quick_wishlist_item {
                    a { 
                        img {
                            width: 72px;
                            margin: 0;
                            max-width: 72px;
                            height: 72px;
                            max-height: 72px;
                            object-fit: cover;
                        }
                    }
                    p.cc_name {
                        margin-left: -8px;
                    }
                }
                .cc_pricing {
                    margin-top: 12px;

                    p.cc_quantity {
                        font-size: $s16px;
                        line-height: 1.19;
                        color: $color-02;
                    }
                    p.price.cc_price {

                        span {
                            font-size: $s16px;
                            font-weight: 500;
                            line-height: 1.19;
                            letter-spacing: normal;
                            color: $color-04;
                        }

                        .value {
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.19;
                            letter-spacing: normal;
                            text-align: right;
                            color: $color-02;
                        } 
                    }
                }
            }
        }

        .widgetSection {
            .panel-body {
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
            }

            .secRelatedCrossSell_container {
                margin-bottom: 25px;
                padding-bottom: 25px;
                border-top: 2px solid $color-06;
                border-bottom: 2px solid $color-06;
            }
            
            h3.cc_title {
                font-size: $s20px;
                font-weight: 500;
                line-height: 1.2;
                letter-spacing: normal;
                text-align: left !important;
                color: $color-02;
                margin-bottom: 0;
            }

            .featured_prod_item {
                .cc_cart_item {
                    height: 216px;
                    max-height: 216px;
                    outline: none;

                    img {
                        height: 216px;
                        max-height: 216px;
                    }
                }
            }
            
            .featured_prod_info {
                margin-bottom: 12px; // push down bottom border 
                
                .cc_prod_name {
                    font-size: $s16px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-04;

                    a {
                        font-size: $s16px;
                        color: $color-04;
                        padding: 0 1rem;
                        display: block;
                    }
                }
                .cart-price {
                    font-size: $s16px;
                    font-weight: normal;
                    line-height: 1.19;
                    color: #41464b;
                }

                .icon-wrap {
                    padding: 0 2rem;
                }
            }
        }

        .tabSection {
            hr {
                // removes line & margin -> imdbb 1596
                margin: 0 !important;
                // border-top-width: 2px;
                border-top: none;
            }

            .panel-body{
                padding-top: 0;
            }

            ul#myTab {
                margin-left: -22px;
                margin-right: -16px;
                width: unset !important;
                display: flex;
                justify-content: space-between;

                li {
                    a {
                        padding: $s12px;
                        font-size: $s14px;
                    } 
                }
            }

            .tab-content {
                padding: 0;

                h1.pane-title {
                    font-size: $s20px;
                    font-weight: 500;
                    line-height: 1.2;
                    text-align: left;
                    color: $color-02;
                }

                p.secContentDescription {
                    font-size: 14px;
                    line-height: 1.21;
                    text-align: left;
                    color: $color-02;
                }

                .specSection {
                    padding: $s16px;

                    .spec-name .name {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.17;
                        text-align: left;
                        color: $color-02;
                    }
                    .spec-value .value {
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1.17;
                        text-align: right;
                        color: $color-04;
                    }
                    .pdp-spec-sec .groupName {
                        margin-bottom: 0.8125rem !important;
                    }
                    .spec-sec-hr {
                        margin: 0.25rem 0 !important;
                    }
                }

                .pdfSection {
                    flex-direction: column;

                    .product-doc-wrap,
                    .product-doc-wrap_ar {
                        margin: 0px;
                    }

                    .col-md-4 {
                        padding-left: 0;;
                    }

                    .pdf-section {
                        margin: $s16px 0;
                    }

                    .pdf-name {
                        font-size: $s16px;
                        font-weight: 500;
                        line-height: 1.19;
                        text-align: left;
                        color: $color-02;

                        svg {
                            max-height: 40px;
                            min-height: 40px;
                            max-width: 40px;
                            min-width: 40px;
                        }

                        // span {}
                    }
                }

                .videoSection {
                    margin: 0;

                    .video-wrapper {
                        margin: $s16px 0;
                    }
                }
            }
        }
    }

    .cc_product_detail_photo_container{
        .osf-imageslider-wrap {
            .osf-slider-prev {
                left: 25px;
            }
            .osf-slider-next {
                right: 25px;
            }
        }
    }

    .prod_details_container {
        float: none !important;
    }
}

.cc_tab-content {
    .cc_tab-pane {
        &.active {
            padding-top: 104px;
            transition: 0s;
    
            @media only screen and (min-width: $sm) and (max-width: $md - 1) {
                padding-top: 145px;
            }
        }
    }  
}

@media only screen and (min-width: $sm) {
    .cc_product_detail_photo_container {
        .slider-hook { 
            display: flex;
            flex-wrap: wrap;
            
            img {
                width: 80px;
                height: 80px;
            }
        }
    }
}

