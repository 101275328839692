header {
    background-color: $color-white;

    @media only screen and (max-width: $md - 1) {
        display: none;
    }

    .header {
        @media only screen and (min-width: $md) {
            margin-left: calc(100vw - 100%);
            height: auto;
        }
    }

    #logoUrl{
        @media only screen and (min-width: $lg) {
            width: 1300px;
        }
    }
    #cc_store_logo_img{
        @media only screen and (min-width: $lg) {
            margin-left: 0;
            margin-top: 35px;
            width: auto;
            left: -10px;
            display: flex;
            align-items: center;
        }

        @media only screen and (min-width: $md)and (max-width: $lg - 1) {
            margin-left: 0;
            margin-top: 53px;
            height: auto;
        }

        background-image: none;
        transform: none;

        img {
            max-width: 100%;
        }
    }

    .container,
    .navbar,
    .row {
        &:before {
            display: none;
        }

        &:after {
            clear: none;
            display: none;
        }
    }

    .navbar-default {
        background-color: $color-white;
        z-index: 90;

        @media only screen and (min-width: $md) {
            margin-left: calc(100vw - 100%);
            border-bottom: 1px solid $color-06;
        }

        .cc_container{
            @media only screen and (min-width: $lg) {
                width: 1330px;
            }
        }
    }

    .cc_navbar_col_acct {
        .navbar-header {
            display: flex;
        }

        &.pull-left{
            padding-left: 0;
        }
    }

    .cc_navbar-nav {
        & > .cc_dropdown {
            white-space: nowrap;
        }
    }

    .cc_col_search {
        position: relative;
        z-index: 200;
        margin-top: -20px;
        
        .nav {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;

            svg:hover {
                path {
                    fill: $color-01 !important;
                }
            }

            .top-bar-link {
                padding-top: 0px;
                padding-bottom: 0px;
                font-size: $s14px;
            }
        }

        .cc_loginoutsec,
        .cc_my_account {
            .top-bar-link {
                padding-top: 0px;
            }
        }

        .cc_my_chead {
            .top-bar-link {
                padding-top: 2px;
            }
            #cartHeader {
                position: relative;

                .itemNr {
                    top: -3px;
                    left: 32px;
                }
            }

            &_ar {
                @media only screen and (min-width: $md) {
                    padding-left: 10px;
                } 
            }
        }

        .cc_search {
            width: 57px;
            padding: 0px 3px 0px 10px;
            transition: 0.3s ease-in-out;

            .input_search {
                padding-left: 20px;
                height: 30px;
                border-left: 2px solid $color-02;
            }

            .search_form  {
                .popover {
                    left: 0px !important;
                }

                &_ar {
                    .popover.bottom>.arrow:after {
                        margin-left: 0px;
                        margin-right: -10px;
                    }
                }
            }

            .input-group {
                width: 100%;
                position: relative;
                margin-bottom: 15px;

                &.pull-left {
                    width: 97%;
                }

                @media only screen and (max-width: $lg - 1) {
                    width: 80%;
                }
            }

            #searchText {
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                width: 0%;
                font-size: $s14px;
                border: none;
                display: none;
                width: calc(100% - 27px);

                &::-ms-clear {
                    display: none;
                }
            }

            .input-group-btn {
                position: absolute;
                z-index: 10;
                right: 0px;

                .search_button {
                    height: auto;
                    font-size: 20px;
                }
            }

            .input-group-btn-left {
                left: 0px;
                right: unset;
            }
        }  
        
        .show_search {
            width: 100% !important;
            transition: 0.3s ease-in-out;

            #searchText {
                width: 90%;
                transition: 0.3s ease-in-out;
            }
        }

        .search_group_display_border {
            .input-group {
                border-bottom: 2px solid $color-17 !important;
            }
        }

        &.pull-left{
            padding-left: 0;
        }
    }

    .cc_navbar_col_acct,
    .cc_navbar_col_misc {
        padding: 15px;
        
        .top-bar-link,
        .changeLocale {
            &,
            &:link {
                font-size: $s12px;
                line-height: normal;
            }
        }
    }

    .cc_navbar_col_misc {
        &.margin-right145 {
            left: 0px;

            ul.cc_navbar_nav {
                margin: 0px 62px;
    
                &.pull-right { 
                    padding-right: 15px;
                }
            }
        }
        
        ul.cc_navbar_nav {
            margin-left: 47px;
        }
    }

    .top-bar-link,
    .changeLocale {
        &,
        &:link,
        &:focus,
        &:hover {
            @extend %font-regular;
            text-decoration: none;
            color: $color-02;
            font-size: $s12px;
            background-color: $color-white !important;
            cursor: pointer;
        }

        &:hover {
            color: $color-01;
        }
    }

    .menu_container {
        position: relative;
        top: -20px;
        margin-bottom: -20px;
        background-color: $color-white;

        .cc_navbar_collapse {            
            .dropdown {
                a {
                    color: $color-02 !important;
                    @extend %font-medium;
                    font-size: $s16px;
                    text-transform: uppercase;
                    padding: 0px 25px;

                    &:hover {
                        color: $color-01 !important;
                    }
                }  
            }

            @media only screen and (min-width: $md) {
                .cc_navbar-nav {
                    margin-top: 0px;
                    margin-left: 190px;
                }
            }
        }
    } 

    &.page-header--ar{
        #cc_store_logo_img{
            @media only screen and (min-width: $md) {
                right: 0;
                margin-right: 0;
                left: auto;
            }
        }

        .second_level_image{
            transform: rotate(180deg);

            &.menu-category-image{
                transform: rotate(0);
            }
        }
    }
}

.itemNr {
    @extend %font-medium;
    font-family: 'Roboto', sans-serif;
    color: $color-white;
    background-color: $color-01;
    position: absolute;
    padding: 0 5px;
    height: 16px;
    min-width: 16px;
    line-height: 16px;
    border-radius: 10px;
    text-align: center;
    font-size: $s12px;
}

.glyphicon-log-in {
    font-size: 22px;
}


/*** Mobile header ***/
.mobile_navbar {
    border: none;
    border-bottom: 1px solid $color-06;
    position: unset;
    margin-bottom: 0px;

    .mobile-menu {
        position: unset;
    }

    .container {
        width: 100%;

        .cc_navbar_row {
            display: flex;
            align-items: center;
        }
        
        .menu_container {
            position: unset;
            border-bottom: none;
            width: auto;
            top: 0;
            margin-bottom: 0;
        }

        .cc-search {
            position: relative;
            z-index: unset;
            clear: none;
            margin-top: 0px;
            top: 0px;
            display: flex;
            justify-content: space-between;
            padding: 0px;
        }

        .pull-left.cc-search {
            ul.cc_nav {
                padding: 0px;
            }
        }
    }

    ul.nav {
        margin-top: 0px;
        flex-wrap: wrap;

        & > li {
            padding: 0px;

            a {
                display: inline-block;
            }
        }

        .cc_search {
            margin: 0px 12px;
            width: 25px;
            padding-top: 3px;
        }

        .cc_my_account {
            text-align: left;
        }

        .cc_my_chead {
            text-align: center;
        }

        .cc_loginoutsec {
            text-align: right;
        }

        &::before,
        &::after {
            display: none;
            content: none;
        }
    }
    
    .cc_navbar_col_misc .list-inline,
    .mobile_top_bar_links {
        border-top: 1px solid $color-06;
        width: 100%;
        padding: 15px 30px !important;
        
        & li .top-bar-link,
        & li .changeLocale {
            color: $color-04 !important;
        }
    }

    .cc_navbar_col_misc {
        padding: 0px;
    }
    
    .navbar-default .navbar-toggle {
        &,
        &:active,
        &:focus,
        &:hover {
            width: 30px;
            display: block;
            padding-top: 8px;
            margin: 6px 16px;
            position: absolute;
            z-index: 850;
            background-color: $color-white;

            .icon-bar {
                background-color: $color-02;
            }
        }
    }

    .navbar-toggle-ar {
        right: 0px;
        margin-left: 0px;
        margin-right: 26px;
    }

    .icon-burger-menu.icon-ic_cross {
        &::before {
            font-size: 17px;
            color: $color-02;
            margin-top: -2px;
            display: block;
        }

        span {
            display: none;
        }
    }

    .navbar-nav>li {
        float: none;

        .dropdown-menu {
            box-shadow: none;
        }
    }

    .cc_navbar_collapse {
        display: none;

        .dropdown-submenu {
            a::after {
                content: none;
            }
        }
    }

    #logoUrl {
        width: 0px;
        position: unset;
        top: unset;
        z-index: unset;
        margin: 5px 15px 0px;
        padding: 0;

        @media only screen and (max-width: $sm - 1) {
            margin: 5px 0 0px;
        }
    }

    #logoUrl + ul.nav {
        width: 100%;

        .mobile-search {
            margin-right: 0px; 
            padding: 1px 0px 0px;
            border: none !important;
        }

        .border-left.mobile-search {
            margin: 0px 0px 0px 17px;
        }

        .input-group {
            width: 100%;
            height: 32px;
            border-bottom: 2px solid transparent;
            margin-bottom: 0px;

            #searchText {
                top: -4px;
                background-color: transparent;
            }

            .input-group-btn {
                width: 25px;
            }
        }
    }

    #cc_store_logo_img {
        margin: 0px;
        transform: none;
        height: 25px;
        width: 115px;
        z-index: 800;
        background-position: center;
        background-image: none;
        line-height: 1.2;

        img {
            max-width: 100%;
        }
    }

    .cc_cart_header {
        padding: 0px;
        width: auto;

        .cc_my_chead {
            padding: 0px 15px 0px 0px;

            &_ar {
                padding: 0px 0px 0px 15px;
            }
        }

        .cartHeaderLink {
            padding: 2px 15px 0px 0px;

            @media only screen and (max-width: $sm - 1) {
                padding-right: 10px;
            }

            .itemNr {
                top: -5px;
                left: 17px;
            }

            &_ar {
                padding: 2px 0px 0px 15px;
            }
        }
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.in {
        display: block !important;
        height: calc(100vh - 55px) !important;
        overflow: scroll !important;

        .navbar-nav {
            margin: 0px !important;

            .cc_user_links {
                padding: 0px 30px;

                .cc_nav {
                    display: flex;
                    justify-content: space-between;

                    .loginoutsec .top-bar-link,
                    .cc_my_chead .top-bar-link {
                        padding: 5px 0px 0px !important;
                    }

                    .top-bar-link {
                        line-height: 1.42857143 !important;
                        
                        &:hover {
                            color: $color-01;
                        }
                    }

                    .cc_my_chead {
                        .itemNr {
                            top: -2px;
                            left: 15px;
                        }
                    }
                }
            }

            .dropdown-menu {
                position: static;
                float: none;
                width: auto;
                margin-top: 0;
                background-color: transparent;
                border: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }

        .first_level_menu-ar {
            text-align: right;

            .dropdown-menu {
                text-align: right;
            }
        }
    }

    .cc_navbar_col_misc,
    .mobile_top_bar_links {
        width: 100%;
        
        .navbar-header {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        .list-inline {
            display: flex;
            flex-direction: column;
            margin-left: 0px;
            align-items: flex-start;
            padding: 0px;

            li {
                padding-left: 0px;
                padding-right: 0px;
            }

            .top-bar-link,
            .changeLocale  {
                &,
                &:link {
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: normal;
                    text-align: left;
                    color: $color-02;
                    display: block;
                    text-transform: uppercase;
                    padding: 10px 0px;
                }

                &:hover {
                    color: $color-01;
                }
            }
        }

        .cc_lssec .cc_navbar_nav {
            position: relative;
            top: 0px;
            left: 0px;
        }
    }

    .mobile-menu #navbar {
        .cc_navbar-nav {
            & > li:not(:first-child) {
                padding: 11.5px 15px;
                border-top: 1px solid $color-05;

                & > a {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: $s16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: normal;
                    color: $color-02;
                    padding: 11px 15px;

                    &:hover {
                        color: $color-02 !important; 
                    }
                }

                & > ul.first_level_menu,
                .dropdown-menu {
                    padding-left: 10px;

                    &-ar {
                        padding-left: 0px;
                        padding-right: 10px;
                    }

                    & > li > a,
                    .dropdown-menu > li > a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        text-transform: capitalize !important;
                        line-height: 1.19;
                        padding: 11.5px 17px;
                        background-color: transparent;
                        border-bottom: 1px solid $color-05;
                    }

                    .dropdown-menu>li>a {
                        padding: 11.5px 0 11.5px 0;
                        margin-left: 17px;
                        margin-right: 0;

                        >.icon-ic_plus,
                        >.icon-ic_minus {
                            margin: 0 17px;
                        }

                        @at-root [dir="rtl"] & {
                            margin-right: 17px;
                            margin-left: 0;
                        }
                    }
                    
                }

                & > .dropdown-menu > li:last-child a {
                    border: none;
                }

                & > .dropdown-menu .dropdown-menu .dropdown-menu {
                    margin: 0px -15px 0px -35px;
                    background-color: $gray-1;

                    & > li {
                        margin: 0px 15px 0px 35px;

                        & > a {
                            font-size: 14px;
                        }
                    }

                    &-ar {
                        margin: 0px -35px 0px -15px;

                        & > li {
                            margin: 0px 35px 0px 15px;
                        }
                    }                
                } 

                & > ul.first_level_menu > li > .dropdown-menu > li.dropdown-submenu {
                    & > a,
                    & > a:hover,
                    & > a:visited {
                        color: $color-04 !important;
                    }
    
                    & > ul > li > a {
                        border: none !important;
                        padding-top: 5px !important;
                        padding-bottom: 5px !important;
                        line-height: 1.14 !important;
                    }
                } 

                .thirdLevelLink {
                    &,
                    &:hover {
                        color: $color-01 !important;
                        justify-content: flex-start !important;
                        line-height: 1.14 !important;

                        .icon {
                            margin: 0px 15px;
                            font-size: 25px;
                            line-height: 16px;
                        }
                    }
                }
            }

            & > li:nth-child(2) {
                padding: 11.5px 0px;
            }

            .icon-ic_plus,
            .icon-ic_minus {
                &:before {
                    color: $color-01;
                }
            }
        }
    }
}

.selected {
    background-color: $color-05 ;
}

.modal-content {
    color: $color-02;
}


/*** Mega Menu ***/
#navbar {
    .first_level_item {
        .caret {
            margin: 0px 10px;
        }
    }

    .first_level_menu_desktop {
        position: fixed;
        top: 106px;
        left: 0px;
        right: 0px;
        background-color: $color-15;
        border: 1px solid $color-06;
        box-shadow: none !important;
        padding-bottom: 0px;
    
        li.menu-item {
            height: 136px;
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            a {
                text-transform: capitalize !important;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.36;
                letter-spacing: normal;
                text-align: center;
                color: $color-02;
                text-decoration: none;

                &:active {
                    color: $color-01;
                }

                &::after {
                    content: none;   
                }
            }

            .close-menu {
                outline: none;
                padding: 0px 3px;

                .close-btn {
                    width: 48.6px;
                    height: 48.6px;
                    line-height: 24.3px;
                    font-size: 48.6px;
                    font-weight: 300;
                    color: $color-13;
                    outline: none;
                    cursor: pointer;
                }
            }
        }

        .second_level_item {
            display: flex;
            height: 100%;
            box-sizing: border-box;

            & > .submenu-item {
                height: 100%;
                cursor: pointer;

                & > a:hover,
                & > a:hover > span {
                    color: $color-01 !important;
                    text-shadow: 1px 0px 0px $color-01;
                }
            }

            &:first-child {
                align-items: center;
                border-right: 1px solid $color-06;
    
                & > .submenu-item {
                    display: flex;
                    align-items: center;
    
                    a {
                        text-transform: uppercase !important;
                        margin: 0px;
                        padding: 0px;
                        font-weight: 500;
                        font-size: $s16px;
                        line-height: 1.19;
    
                        &:last-child {
                            margin: 0px 44px 0px 17px;
                        }
                    }
                }
            }
    
            &:not(:first-child) {
                flex-direction: column;
                justify-content: space-evenly;
    
                .second_level_image {
                    display: block;
                    margin: 20px auto 15px;
                    width: 50px;
                    height: 50px;
                    border: solid 0.5px $color-14;
                    background-color: $color-white;
                    border-radius: 50%;
                }
    
                .cc_product {
                    padding-top: 0px;
                }
            }

            &_selected {
                border-bottom: 3px solid $color-01;

                & > div > a {
                    color: $color-01 !important;
                }
            }

            &_ar {
                &:first-child {
                    align-items: center;
                    border-right: none;
                    border-left: 1px solid $color-06;
        
                    & > .submenu-item {        
                        a:last-child {
                            margin: 0px 17px 0px 44px;
                        }

                        img {
                            transform: rotateY(180deg);
                        }
                    }
                }
            }
        }
    
        .second_level_menu {
            position: fixed;
            top: 254px;
            left: 0px !important;
            right: 0px;
            box-shadow: none;
            border-left: 1px solid $color-06;
            border-right: 1px solid $color-06;
            height: calc(100vh - 245px);
            overflow: auto;
            cursor: default;

            &.no_children {
                top: 246px;
            }
    
            & > li.menu-item {
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                height: auto;
                overflow: auto;
                padding-bottom: 30px;
    
                & > .submenu-item {
                    margin: 34px 0px 0px;
                    padding: 0px;

                    &:not(:nth-child(4n+1)) {
                        border-left: 1px solid $color-06;
                    }

                    & > div {
                        height: 48px;
                        padding: 0px 20px;
                    }
    
                    a {
                        padding: 14px 0px !important;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        text-align: left;
                        color: $color-01 !important;
                    }
                }

                .third_level_item {
                    &_ar {
                        .third_level_image {
                            transform: rotateY(180deg);
                        }
                    }

                    a {
                        span {
                            line-height: 1.14px;
                            display: flex;
                            align-items: center;
                        }

                        &,
                        & span,
                        & img {
                            color: $color-19 !important;
                        }

                    }
                }
            }

            .third_level_menu {  
                padding: 0px;
    
                & > li.menu-item {
                    display: block;
                    padding: 0px;
                    height: auto;
        
                    & > .submenu-item {   
                        div {
                            padding: 0px 0px 0px 30px;

                            a:hover {
                                color: $color-01 !important;
                            }
                        }

                        a {
                            padding: 7px 0px !important;
                            font-size: $s16px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: left;
                            color: $color-02 !important;

                            img {
                                line-height: 1.36;
                                font-size: $s14px;
                            }
                        }
                    }
                }

                &_ar {
                    img {
                        transform: rotateY(180deg);
                    }

                    & > li.menu-item {            
                        & > .submenu-item {   
                            div {
                                padding: 0px 30px 0px 0px;

                                a {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    .fourth_level_item {
                        margin: 0px 10px 0px 0px !important;
                    }
                }

                .fourth_level_item {
                    margin: 0px 0px 0px 10px;
                }
            }

            &_ar {
                & > li.menu-item {
                    & > .submenu-item {
                        &:not(:nth-child(4n+1)) {
                            border-left: none;
                            border-right: 1px solid $color-06;
                        }
                    }
                }
            }
        }
    }    

    .cc_navbar-nav li {
        a:hover {
            color: $color-01;
        }
    }
}

@media only screen and (min-width: $md) and (max-width: $lg - 1) {
    .header .navbar {
        .cc_navbar_col_misc {
            .list-inline {
                margin-left: 28px;
            }
        }

        .cc_navbar_col_misc.margin-right145 {
            ul.cc_navbar_nav {
                margin: 0px 28px;
            }
        }
        
    }

    #navbar {
        & > ul.nav {
            & > li {
                & > a {
                    padding: 3px 6px 0px
                }
            }
        }
    }
}

@media only screen and (max-width: $md) {
    header .mobile_navbar {
        position: relative;
        
        .navbar-default .navbar-header{
            position: relative;

            .navbar-toggle{
                margin: 2px 0;
            }
        }
    }
}

@media only screen and (max-width: $sm - 1) {
    header .mobile_navbar {
        
        .navbar-default .navbar-header{
            .navbar-toggle{
                padding-right: 0;
                padding-left: 0;
            }
        }
    }
}

/*** Search autocomplete styling ***/
.ui-widget {
    @extend %font-regular;
    padding: 2px;
    width: 20% !important;
    position: fixed;

    @media only screen and (max-width: $md - 1) {
        width: 80% !important;
    }

    @media only screen and (max-width: $sm - 1) {
        width: 75% !important;
    }

    .ui-menu-item-wrapper,
    .ui-state-active {
        color: $color-02;
        background-color: $color-white;
        border-color: $color-white;
        border: none;
        margin: 0px;

        &:hover {
            background:  $gray-1;
            border: none;
            margin: 0px;
        }

        a{
            display: block;
            font-size: $s16px;
            color: $color-08;
            line-height: inherit;
        }
    }
}

.hideScrollBar,
.hideScrollBar_modal {
    margin: 0px;
    height: 100%;
    overflow: hidden;

    @media only screen and (min-width: $md) {
        header {
            margin-right: 15px;
        }
    }
}

.keep_open {
    display: block !important;
}

.deskLayout {
    margin-top: 106px;

    &.deskLayout--notification {
        margin-top: 156px;
    }
    @media only screen and (max-width: $md) {
        margin-top: 56px;
    } 
}

.header_icon {
    font-size: 25px;

    @media only screen and (min-width: $md) {
        &:hover {
            color: $color-01;
        }
    } 
}

img.header_icon{
    width: 25px;
}

.goToLogin.cc_goto_login, .doLogout.cc_do_logout{
    padding-bottom: 0;
    
    svg{
        width: 50px;
        height: 32px;
        direction: ltr;
    } 
}

#goToAccount{
    svg{
        height: 30px;
        width: 30px;
    }
}

.extra_margin {
    margin-bottom: 77px;
}

.deskLayout {
    #overlay ~ #overlay {
        display: none;
    }
}

.cc_main_body.page-layout--arabic{
    .ui-menu-item-wrapper,
    .ui-state-active {
        text-align: right;
    }

    .goToLogin.cc_goto_login, .doLogout.cc_do_logout{
        
        svg{
            width: 90px;
            margin-left: -22px;
        } 
    }

    .goToLogin.cc_goto_login{
        svg{
            width: 118px;
            margin-left: -35px;
        } 
    }
}