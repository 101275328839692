.registrationPage {
    label {
        position: relative;
        line-height: 34px;
    }
    .cc_shipping_address_panel {
        display: none;
    }
    .business-label {
        margin-right: 30px;
    }
    .personal-information {
        display: none;
    }
    .cc_navbar_col_acct {
        display: none !important;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .tooltip-label {
        .tooltip {
            display: none;
            opacity: 1;
            background-color: $color-white;
            border-radius: 6px;
            padding: 5px;
            text-align: center;
            width: 120px;
            bottom: 0;
            left: 150px;
            border-width: 1px;
            border-style: solid;
            border-color: $color-03;
        }
        &:after {
            content: 'i';
            border-style: solid;
            border-width: 1px;
            border-color: #909090;
            border-radius: 50%;
            padding: 2px 8px;
            margin-left: 5px;
            font-size: 12px;
        }
        &:hover {
            .tooltip {
                display: block;
            }
        }
    }
    .formTypeRadioBtn {
        display: none;
    }
    .formTypeRadioBtn:checked {
        &~.checkmark {
            &:after {
                display: block;
                top: 2px;
                left: 2px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: $color-01;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin-top: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: $color-04;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .formTypeRadioText {
        margin-left: 20px;
    }
    .useBillingAddressCheckbox {
        display: none;
    }
    .useBillingAddressText {
        margin-left: 28px;
        &:before {
            content: '';
            display: inline-block;
            height: 14px;
            width: 14px;
            padding: 2px;
            border: 1px solid $color-04;
            font-weight: 700;
            position: absolute;
            left: 0;
            margin: 8px 0px 0px 15px;
        }
    }
    input[type="checkbox"]:checked {
        &~.useBillingAddressText {
            &:before {
                content: "\e907";
                font-family: 'imdadicon' !important;
                speak: none;
                font-style: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: $s10px;
                padding-right: 0px;
            }
        }
    }
    // #phoneNumber {
    //     &+em {
    //         transform: translateX(-72px);
    //     }
    // }
    .select2-input-container {
        flex: 1 1;
    }
    .login_error_section {
        i {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .has-error {
        .help-block {
            font-size: $s12px;
            font-style: normal;
            color: $color-09;
        }
        .cc_phone {
            &~.select2-container--default{
                border-width: 0 0 1px 0;
                border-style: solid;
                border-color: $color-09;
            }
        }
    }
    .bootstrap-select {
        &:focus {
            box-shadow: none;
        }
        .btn.dropdown-toggle {
            line-height: 34px;
            margin: 0;
            background-color: white;
            border-style: solid;
            border-width: 1px;
            border-color: $color-05;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding-left: 5px;
            font-size: $s12px;
            &:focus {
                outline: none !important;
            }
        }
        .dropdown-menu {
            border-radius: 0;
            font-size: $s12px;
        }
        .dropdown-menu.inner {
            a {
                color: $color-02;
                background-color: $color-white;
                &:hover,
                &:focus {
                    background-color: $gray-1;
                    outline: none;
                }
            }
        }
    }
    .panel-title, 
    h3.cc_section_title {
        font-weight: 500;
    }
    .cc_required_fields {
        color: $color-04;
    }
    .has-success {
        .form-control {
            box-shadow: none;
        }
    }
    .alert-info {
        background-color: $color-08;
        color: $color-white;

    }
}

.select2-container--default {
    width: 4.8rem !important;
    padding: 3px 0 2px;
    border-width: 0 0 1px 0;
    border-color: $color-05;
    border-style: solid;
    .select2-dropdown {
        width: 202px !important;
    }
    span.select2-selection--single {
        border: none;
        outline: none;
        .select2-selection__rendered {
            color: $color-03;
            padding-left: 0;

            & > div {
                direction: ltr;
            }
        }
    }
    li.select2-results__option {
        padding: 14px 28px;
        span {
            font-size: 14px;
        }
    }
    .select2-results__option[aria-selected] {
        background-color: $color-white;
    }
    .select2-search--dropdown.select2-search--hide {
        display: none;
    }
    .select2-selection__rendered {
        font-size: $s12px;
    }
    .select2-flag {
        display: inline-block;
        margin-right: 5px;
    }
    .img-flag {
        width: 20px;
        padding-left: 0;
    }
    ul {
        width: 200px;
    }
    span.select2-search--dropdown {
        display: none;
    }
    .select2-country,
    .select2-code {
        padding: 0 2px;
    }
}

.registrationPage.cc_main_container--ar{
    opacity: 0;

    .business-label{
        margin-right: 0;
        margin-left: 30px;
    }
    .newCustomerSection{
        .form-group{
            > label{
                text-align: right;
            }
        }
    }

    .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered{
        padding-right: 0;
    }

    .bootstrap-select{
        .dropdown-toggle{
            padding-right: 5px;
            padding-left: 32px;
    
            .filter-option{
                padding-right: 0;
                padding-left: 32px;
                text-align: right;   
            }
            .caret{
                right: auto;
                left: 12px;
                margin-left: 0;
                margin-right: 10px;
            }
        }

        .dropdown-menu{
            text-align: right;
        }
    }

    .useBillingAddresslabel{
        float: right;
        text-align: right;

        .useBillingAddressText{
            margin-right: 28px;
            margin-left: 0;

            &:before{
                left: auto;
                right: 0;
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }

    .has-error .help-block{
        text-align: right;
    }

    // #phoneNumber + em{
    //     transform: translateX(72px);
    // }

    .tooltip-label{
        &:after {
            height: 20px;
            line-height: 14px;
            display: inline-block;
            margin-right: 5px;
            margin-left: 0;
        }
    }
}

.page-layout--arabic{
    .select2-container--default{
        .select2-country,
        .select2-code{
            display: inline-block;
            direction: ltr;
        }
    }
}
