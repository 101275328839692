.navbar-inverse {
    background-color: $color-white;
    border-top: 1px solid $color-05;

    .cc_scroll-top{
        float: right;

        @media only screen and (max-width: $sm - 1) {
            margin-top: -65px;
        }
    }
}

.osf-footer {
    .section {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        // padding: 25px 15px;

        & > div:last-child {
            @media only screen and (min-width: $md) {
                padding-right: 0px;
            }
        }

        &__box {
            display: block;
            padding: 15px;

            @media only screen and (max-width: $sm - 1) {
                width: 100%;
            }
        }

        .nav-list {
            margin: 0px;

            &__title {
                margin-bottom: 16px;
                @extend %font-medium;
                color: $color-02;

                &::after {
                    width: 40px;
                    height: 2px;
                    display: block;
                    margin-top: 16px;
                    content: '';
                    background: $color-01;
                }
            }

            &__item {
                margin: 0 0 16px;

                a {
                    @extend %font-regular;
                    text-decoration: none;
                    color: $color-04;
                    font-size: $s14px;

                    &:hover {
                        color: $color-01;
                    }
                }
            }

            .grid__box {
                &:not(:last-child) {
                    margin-right: auto;

                    @media only screen and (max-width: $sm - 1) {
                        margin-right: 30px;
                    }
                }
            }

            .grid__box-ar {
                &:not(:last-child) {
                    margin-left: auto;

                    @media only screen and (max-width: $sm - 1) {
                        margin-left: 30px;
                    }
                }
            }
        }

        .icon {
            width: 20px;
            height: 24px;
            color: $color-04;
            font-size: 20px;
        }
    }

    > .section{
        @media only screen and (min-width: $lg) {
            width: 1330px;
        }
    }
}

.cookie_popup {
    background-color: rgba($color-03, 0.8);
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 500;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: $sm - 1) {
            display: block;
        }

        &::before, 
        &::after {
            content: none;
        }

        .cookie_text {
            width: 75%;
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            text-align: left;
            color: $color-white;
            padding: 20px 0px;

            @media only screen and (max-width: $sm - 1) {
                width: 100%;
            }

            a {
                &,
                &:link,
                &:visited {
                    color: $color-white;
                }
            }
        }

        .js-cookieAccept {
            border: none;
            height: 38px;
            
            @media only screen and (max-width: $sm - 1) {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }
}

.page-layout--arabic {
    .cookie_text {
        text-align: right !important;
    }
    .cc_scroll-top{
        float: left;
        padding-left: 0;
        padding-right: 0;
    }
}
