.osf_tmpl_myaccount {
    color: $color-02;

    /****  Contact Information page ****/
    .title-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        h3.cc_title {
            margin-bottom: 15px;
        }

        .profile_edit_btn {
            margin: 0px 16px;
            font-size: $s14px;
            cursor: pointer;
        }
    }

    h3.cc_title {
        @extend %font-medium;
        color: $color-02;
        font-size: $s30px;
    }

    .cc_myaccount_content {
        .panel-heading .cc_title {
            line-height: 1.2;
            font-size: $s20px;
            margin: 0px;
        }

        .myAccProfileNote {
            display: none;
        }

        .panel {
            border-color: $color-06;

            &.cc_myaccount_user_information {
                border: none;
            }
        }

        .panel-body {
            p {
                span:first-child {
                    @extend %font-regular;
                    font-size: $s16px;
                    min-width: 90px;
                    display: inline-block;
                    line-height: 1.2;
                }

                span:last-child {
                    @extend %font-medium;
                    line-height: 1.19;
                    margin: 0px 5px;
                    font-size: $s16px;
                    display: inline-block;
                }
            }

            .row {
                @media only screen and (min-width: $sm) {
                    display: flex;
                }

                & > div {
                    background-color: $color-white;
                    border: 2px solid $color-06;
                    margin: 25px 10px 0px 15px;

                    & > span:first-child {
                        @extend %font-medium;
                        background-color: $color-white;
                        color: $color-02;
                        text-transform: uppercase;
                        font-size: $s16px;
                        line-height: 1.19;
                    }

                    & > span:last-child {
                        line-height: 1.5;
                    }
                }

                @media only screen and (max-width: $sm - 1 ) {
                    display: block;

                    & > div {
                        margin: 25px 15px 0px;
                        float: none;
                        width: auto;
                        padding: 10px;
                    }
                }
            }

            .shorter {
                min-width: 90px;
            }
        }

        .updateProfile {
            margin: 20px 15px 0px;
        }
    }

    .cc_edit_account_form.cc_edit_account_form_ar .form-group,
    .cc_edit_account_form.cc_edit_account_form_ar + .form-group {
        text-align: right;
    }

    .user_form_label {
        @extend %font-regular;
        font-size: $s16px;
        min-width: 90px;
        display: inline-block;
        line-height: 1.2;
    }

    /****  My Account Navigation ****/
    .cc_myaccount_nav {
        .panel-heading {
            .cc_title {
                @extend %font-light;
                color: $color-18;
                font-size: $s24px;
                line-height: 1.17;
                padding: 0px;
                margin: 20px; 
            }
        }

        ul#side_nav {
            margin: 0px;
            padding: 0px;
        }

        .acctStepNav {
            border-bottom: 2px solid $color-06 !important;
            padding: 16px 22px;

            .gotoSection {
                &,
                &:link,
                &:hover,
                &:visited,
                &:active {
                    @extend %font-medium;
                    font-size: $s16px;
                    color: $color-02;
                    line-height: 1.19;
                    text-decoration: none;
                }
            }

            &.selectedSection {
                background-color: $gray-1;
            }
        }
    }

    @media only screen and (max-width: $sm - 1 ) {
        .cc_right_col,
        .cc_main_content_col {
            float: none !important;
        }
    }

    /**** Manage Address Book ****/
    .addressBookSection {

        .cc_myaccount_changePassword {
            margin: 0px;
            padding: 0px;

            .cc_heading {
                margin-top: 10px;
            }

            .cc_body {
                padding: 0px;
            }
        }

        .orderPaginationSec {
            .cc_body {
                .row {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;  

                    .col-xs-4:first-child {
                        text-align: left;
                    }

                    .cc_current_page,
                    .cc_paginator_legend {
                        @extend %font-regular;
                        font-size: $s12px;
                        color: $color-05;
                        line-height: 46px;
                        padding: 0px;
                    }

                    #itemsPerPage {
                        border: 2px solid $gray-1;
                        height: 46px;
                        font-size: $s14px;
                        color: $color-02;
                        line-height: 1.43;
                        padding: 7px 27px 11px 15px;

                        @media only screen and (max-width: $md - 1) {
                            padding: 11px !important;
                            width: 125px !important;
                            height: auto;
                        }
                    }
                }
            }

            &_en {
                .cc_body .row {
                    .col-xs-4:first-child {
                        text-align: right;
                    }

                    .col-xs-4:last-child {
                        .cc_paginator_form {
                            float: left !important;
                        }
                    }

                    #itemsPerPage {
                        padding: 11px 15px 11px 27px;
                    }
                }
            }
        }

        .cc_myaccount_addresses {
            .table-responsive {
                padding: 0px;

                th {
                    font-weight: 400;
                }

                .th_ar {
                    text-align: right;
                }

                tr {
                    &,
                    &:hover,
                    &:active {
                        background-color: $color-white;
                        border: none;
                        border-bottom: 2px solid $color-06;
                    }
                }

                .status.cc_address_type_value {
                    font-weight: 400;
                    color: $color-02;
                }
            }
        }
    }

    /**** My Carts ****/
    .cc_myaccount_mycart {
        .panel-heading {
            .cc_title {
                padding: 15px 0px 0px;
            }
        }

        .cc_myaccount_content {
            .table-responsive {
                padding: 0px;
                border: none;

                tr:hover {
                    background-color: $color-white;
                }

                th {
                    @extend %font-regular;
                }

                .cart_name_col {
                    width: 35%;
                }
            }
        }

        &_ar {
            .table-responsive th {
                text-align: right;
            }
        }
    }

    /**** My Account - My Orders ****/
    .acctmainSection {
        .cc_myaccount_myorders,
        .cc_myaccount_myinvoices {
            .panel-heading{
                padding: 0;

                .cc_title{
                    padding: 0;
                }
            }
            .form-group{
                vertical-align: bottom;
                margin-right: 10px;

                @media only screen and (max-width: $sm - 1) {
                    margin-right: 0 !important;
                }

                label{
                    display: block;
                    font-size: $s14px;
                    line-height: 1.14;
                }
            }
            .form-group-sm .form-control{
                padding: 0;

                @media only screen and (max-width: $sm - 1) {
                    width: 100%;
                }
            }
            .form-control[readonly]{
                background-color: transparent;

                &:focus{
                    border-color: $color-05;
                }
            }
            .input-group-addon{
                padding: 5px 12px;
                background-color: transparent;
                border-width: 0 0 1px 0;
                border-radius: 0;
            }
            .input-group.date{
                cursor: default;
                
                .input-group-addon{
                    padding: 0 7px;
                }

                svg{
                    display: block;
                    height: 20px;
                    width: 20px;
                }
            }
            .cc_search_order_form{
                margin: 20px 0 40px;

                &.pull-right {
                    .form-group label {
                        text-align: right;
                    }
                }

                .cc_search_order_status {
                    button {
                        margin: 0px;
                        padding: 5px;
                    }
                    .bs-caret {
                        color: $color-02;
                    }
                } 

                @media only screen and (max-width: $lg - 1) {
                    float: none;
                    margin-bottom: 25px;
                }
            }
            .cc_order_search_action,
            .cc_invoice_search_action{
                margin: 40px 0 0;

                @media only screen and (max-width: $lg - 1) {
                    float: none;
                    margin-top: 0;
                }
                @media only screen and (max-width: $sm - 1) {
                    text-align: left;
                }

                button,
                input[type=button] {
                   
                    vertical-align: middle;
                    @media only screen and (min-width: $sm) {
                        background-color: transparent;
                        border: none;
                        border-radius: 0;
                    }

                    &:focus {
                        outline: none !important;
                    }

                    @media only screen and (max-width: $sm - 1) {
                        margin: 0 20px;
                    }
                }
                .cc_search_order{
                    margin: 0 30px;

                    @media only screen and (max-width: $sm - 1) {
                        padding: 0;
                    }

                    .icon-ic_search{
                        font-size: $s22px;
                        display: block;

                        @media only screen and (max-width: $lg - 1) {
                            font-size: $s14px;
                        }

                        &:hover{
                            color: $color-01;
                        }
                    }
                    .cc_search_order--text{
                        font-size: $s14px;
                        vertical-align: text-top;
                        display: inline-block;
                        margin-left: 7px;  
                    }
                }
            }

            .cc_search_invoicer_status + button, 
            .cc_search_invoice_type + button {
                margin: 0px;
            }

            .searchInvoiceForm {
                .form-group.form-group-sm {
                    margin-bottom: 15px;

                    &.searchButtons {
                        margin-right: 0px !important;
                        margin-left: 0px !important;
                        display: inline-flex;
                        align-items: center;

                        @media only screen and (max-width: $sm - 1) {
                            display: flex;
                        }

                        .icon-ic_search {
                            margin: 0;
                            font-size: 1.375rem;

                            @media only screen and (max-width: $sm - 1) {
                                margin: 0 30px;
                                font-size: $s14px;
                            }

                            &:hover {
                                color: $color-01;
                            }
                        }

                        .resetInvoiceSearch {
                            border: 0;
                            padding: 0;
                            background-color: transparent;
                            color: $color-08;
                            font-size: 0.875rem;
                            line-height: 1.14; 
                            font-weight: 400;
                            text-decoration: none;
                            outline: none;

                            &:hover {
                                text-shadow: 1px 0px 0px $color-08;
                            }
                        }
                    }
                }

                &_ar {
                    .form-group{
                        &.form-group-sm {
                            margin-right: 0px !important;
                            margin-left: 10px !important;
    
                            @media only screen and (max-width: $sm - 1) {
                                margin-left: 0 !important;
                            }
                        }

                        label{
                            text-align: right;
                        }
                    }

                    .searchInvoice.cc_search_invoice {
                        margin: 0px 22px !important;
                    }
                }

                @media only screen and (max-width: $sm - 1) {
                    div.cc_search_invoicer_status, 
                    div.cc_search_invoice_type {
                        width: 100%;
                    }
                }
            }

            .cc_invoice_table {
                .cc_table_col {
                    height: 60px;
                }
            }
        }

        @media only screen and (max-width: $sm - 1) {
            .cc_order_search_action,
            .cc_invoice_search_action{
                display: flex;
                clear: both;
                justify-content: space-around;

                button{
                    width: 40%;
                    margin-left: 0;
                    margin-right: 0;

                    .icon-ic_search{
                        display: inline-block !important;
                        vertical-align: middle !important;
                        margin: 0 !important;
                        font-size: $s14px;
                    }
                    .cc_search_order--text{
                        vertical-align: middle !important;
                        margin-left: 0 !important;
                        margin-right: 10px;
                    }
                }
            }
        }

        .orderPaginationSec {
            font-size: $s12px;
            color: $color-05;
            line-height: 1.17;
            margin-bottom: 15px;

            .cc_paginator {
                margin-bottom: 0;
                padding-bottom: 0;

                .textbutton-blue {
                    font-size: $s12px;
                }
            }

            .cc_body {
                .row {
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;  

                    .col-xs-4:first-child {
                        text-align: right;
                    }
                    .cc_paginator_form{
                        float: left !important;
                    }
                }
            }

            .cc_size_action {
                button {
                    margin: 0px;
                    padding: 0 15px 5px;
                    font-size: $s14px;
                    line-height: 1.43;
                }

                .bs-caret .caret {
                    color: $color-02;
                    margin-top: -4px;
                }
            }

            .cc_current_page{
                text-transform: capitalize;
            }
        }

        .cc_paginator{
            .table-responsive{
                padding: 0 0 30px;
                margin-top: 0;

                .cc_order_search_table{
                    th {
                        padding: 0 12px;
                        font-size: $s16px;
                        line-height: 1.43;
                        font-weight: normal;
                    }

                    tr{
                        border-bottom:2px solid $color-06;

                        &.cc_table_header_row{
                            border-bottom: none;
                        }

                        &:hover{
                            background-color: transparent;
                        }
                    }

                    td{
                        padding: 30px 12px 10px;
                        @extend %font-medium;
                        color: $color-04;
                        width: 17%;
                        vertical-align: initial;

                        @media only screen and (max-width: $sm - 1) {
                            padding: 10px;
                        }

                        &.cc_table_col--order-number{
                            width: 30%;
                        }
                    }

                    .cc_col_order_number{
                        a{
                            font-size: $s20px;
                            line-height: 1.2;

                            &:hover{
                                text-decoration: none;
                            }
                        }
                    }
        
                    .cc_col_prompt_amend, .cc_col_prompt_cancel{
                        display: none;
                    }

                    .cc_col_order_statust{
                        color: $color-01;

                        &.completed,
                        &.delivered {
                            color: $color-10;
                        }
                    }

                    &_ar {
                        .cc_table_header_row th {
                            text-align: right;
                        }
                    }
                }
            }
        }

        &.ordersSection--ar{
            /* Styles for Arabic version */
            .cc_myaccount_myorders{
                .cc_order_search_action{
                    .cc_search_order{
                        margin: 0 30px;
                    }
                }

            }
            .orderPaginationSec{
                .cc_body{
                    .row {
                        .cc_paginator_form{
                            float: right !important;
                        }
                        .col-xs-4:first-child{
                            text-align: left;
                        }
                    }
                }
            }
            .cc_paginator .table-responsive .cc_order_search_table th{
                text-align: right;
            }
        }
        .cc_col_reorder {
            text-transform: capitalize;
        }
    }

    /****  My Wishlists page ****/
    .cc_myaccount_mywishlist,
    .cc_myaccount_mywishlists_container {
        .panel-heading {
            .cc_title {
                padding-top: 15px;
            }
        }

        .cc_myaccount_content {
            .createTemplate {
                @extend %font-regular;
                font-size: $s18px;
                height: 3.5rem;
                border-radius: 0;
                color: $color-01;
                background-color: transparent;
                border-color: $color-01;
                border-radius: 0;
                border-width: 2px;
                min-width: 7.5rem;
                padding: 0 2rem;
                margin: 10px 0px;
            }

            .table-responsive {
                padding: 0px;

                tr {
                    &,
                    &:hover,
                    &:active {
                        background-color: $color-white;
                        border: none;
                        border-bottom: 2px solid $color-06;
                    }
                }

                tr:hover {
                    background-color: $color-white;
                }

                th {
                    @extend %font-regular;
                }

                th.cc_table_header:nth-child(3) {
                    width: 11%;
                    padding: 10px 0px;
                }

                .cc_col_active,
                .button-group input {
                    text-transform: capitalize;
                }

                @media only screen and (max-width: $sm - 1) {
                    width: 100%;

                    .cc_mywishlist_table {
                        width: 100%;
                        // table-layout: fixed;

                        th,
                        td {
                            text-align: center;
                            word-break: break-word;
                        }
                    }

                    .button-group {
                        display: flex;
                        line-height: 0px;

                        input {
                            padding: 0px;
                            margin: 0 5px;
                        }
                    }
                }
            }

            .cc_save_changes {
                & > div {
                    margin: 0px;
                    width: 100%;
                    text-align: center;
                }

                .cc_save {
                    margin: 0px;
                }
            }

            .cc_wishlist_active.checkcontainer {
                .checkmark {
                    height: 14px;
                    width: 14px;
                    margin-top: -2px;
                }
            }
        }

        .wishlist_actions {
            margin-top: 15px;

            .cc_wishlist_action {
                display: inline-flex;
                margin: 0px 15px;
            }
        }

        .cc_wishlist_image .cc_prod_link.textbutton-blue {
            width: 120px;
            height: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $gray-1;

            .img-responsive {
                max-width: 100%;
                max-width: 100%;
            }
        }
    }

    .cc_myaccount_mywishlist_ar {
        .table-responsive th {
            text-align: right;
        }
    }

    .cc_myaccount_mywishlists_container {
        .well {
            background-color: $gray-1;
        }

        .deleteItem {
            text-transform: capitalize;
        }

        .sku {
            display: none;
        }

        hr {
            margin-top: 0px;
            border-top: none;
        }

        &_ar {
            .cc_wishlist_item,
            .cc_wishlist_item + .row {
                & > div {
                    float: right;
                }
            }
        }
    }

    .table-responsive {
        padding: 0px;

        tr:hover {
            background-color: $color-white;
        }

        th {
            @extend %font-regular;
        }

        .cart_name_col {
            width: 35%;
        }

        &_ar {
            th {
                text-align: right;
            }
        }
    }

    .cc_mywallet_table {
        .myWalletButton {
            text-transform: capitalize;
        }
    }

    .custom_select {
        box-shadow: none;
    
        .btn-default {
            &,
            &:active,
            &:focus,
            &:hover {
                padding: 5px 0px;
                background-color: transparent;
                border-color: transparent;
                border-bottom: 1px solid $color-05;
                margin: 5px 0px;
                outline: none !important;
            }
        }
    
        .filter-option,
        .filter-option-inner,
        .filter-option-inner-inner {
            &,
            &:focus {
                outline: none;
                color: $color-02;
            }
        }
    
        .dropdown-menu {
            li a {
                &,
                &:focus,
                &:active {
                    outline: none !important;
                }
            }
            li.active a {
                background-color: $color-06;
                color: $color-02;
            }
            li:hover {
                background-color: $gray-1;
            }
        }
    }

    @media only screen and (max-width: $md - 1) {
        .ordersSection .cc_paginator .table-responsive .cc_order_search_table {
            .cc_col_order_number a {
                font-size: 1rem;
            }
            th,
            td {
                padding: 10px 20px !important;
                white-space: nowrap !important;
            }
        }
    }

    .datepicker.datepicker-dropdown{
        left: 0 !important;

        @media only screen and (max-width: $sm - 1) {
            .table-condensed{
                width: 100%;
            }
        }
    }

    &.cc_main_container--ar{

        .addressBookSection .orderPaginationSec .cc_body .row .col-xs-4:first-child {
            text-align: left;
        }

        .addressBookSection .orderPaginationSec .cc_body .row .cc_paginator_form{
            float: right !important;
        }

        .user_form_label{
            min-width: 110px;
        }

        .bootstrap-select {
            .dropdown-toggle {
                .filter-option{
                    text-align: right;
                    padding-right: 0;
                    padding-left: 5px;
                }
                .caret{
                    right: auto;
                    left: 5px;
                    margin-left: 0;
                }
            }

            .dropdown-menu li a{
                text-align: right;
            }
        }

        .help-block.has-error{
            text-align: right;
        }

        .cc_modal.cc_edit_wishlist{
            .form-horizontal .form-group{
                margin-right: 0;
                margin-left: 0;
                text-align: right;

                &.cc_wishlist_default{
                    .checkcontainer{
                        padding-left: 0;
                        padding-right: 20px;

                        .checkmark{
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }

        .wishButtons .pickWish{
            text-align: right;
        }

        .datepicker.datepicker-dropdown{
            left: auto !important;
            right: 0 !important;

            @media only screen and (max-width: $sm - 1) {
                table tr th.dow{
                    font-size: $s12px;
                }
            }
        }

        .datepicker-switch {
            direction: ltr;
        }
    }
}

.cc_myaccount_mywallet {
    h3.panel-title.cc_title {
        font-size: $s30px;
        line-height: 1.17;
        @extend %font-medium;
        color: $color-02;
        padding-top: 15px;
    }

    .saveCC {
        margin-top: 15px
    }
    
    .cancelCC,
    .saveCC {
        margin: 15px 0px;
    }

    select.paymentMethod:focus {
        padding: 5px 10px;
        color: $color-02;
        border-color: $color-02;
    }

    .messagingSection-Error,
    .error_messages_section {
        .alert-danger {
            color: $color-white;
            text-align: center;
            background-color: $color-09; 
        }
    }

    .myWalletModal {
        .modal-footer {
            text-align: center;
        }

        input[type=button] {
            margin: 0px 15px;
        }

        .myWalletModalCloseBtn {
            background-color: $color-white;
            border-color: $color-01;
            color: $color-01;
        }
    }

    &_ar {
        .myWalletModal {
            .modal-footer {
                direction: rtl;
            }
        }
    }

    .searchOrderForm  {
        display: inline-block;
        width: 175px;

        .form-group,
        .bootstrap-select.paymentMethod  {
            width: 100% !important;

            button.dropdown-toggle {
                &,
                &:hover {
                    padding: 5px;
                }
            }
        }
    }

    .ivalid_payment_messages_container {
        width: calc(100% - 200px);
        margin-bottom: 30px;
    }

    .ivalid_payment_message {
        @extend %font-regular;
        font-size: $s16px;
        color: $color-09;
    }

    .table-responsive {
        width: 100%;
    }

    .myWalletModalBody {
        .modal {
            &-header,
            &-footer {
                border: none;
            }

            &-body {
                display: none;
            }
        }
    }

    .form-horizontal {
        .control-label{
            text-align: left;
        }

        .checkcontainer{
            display: inline-block;
        }

        .cc_cancel_po{
            float: left;
        }
    
        .cc_save_po{
            float: right;
        }
    } 
    
    .cc_new_cc_form{

        .cancelCC{
            float: left;
        }

        .saveCC{
            float: right;
        }
    }
} 

.paymentContainer {
    .help-block {
        color: $color-09;
    }

    .checkcontainer {
        line-height: 1.7;
        padding: 0px 30px;
    }
}

.cc_invoice_table {
    a.ascSort.sortControl.cc_asc_sort {
        position: absolute;
    }
    
    a.sortControl {
        i.fa {
            color: $color-02;
        }
    }
}

.cc_invoice_payment_container {
    .cc_stored_payments_container {
        .cc_stored_payments_container.po {
            display: none;
        }
    }    
}

.gotoInvoiceDetails.cc_goto_invoice_details.textbutton-blue {
    font-size: 16px;
    font-weight: 500;
}

.invoicePaymentContainer {
    .panel-heading {
        h3.cc_title {
            font-size: 1.875rem;
            line-height: 1.17;
            color: $color-02;
        }
    }

    .heading {
        display: none;
    }

    .cc_invoices_table th {
        color: $color-02;
        font-weight: 400;
    }

    .myInvoices.cc_my_invoices.textbutton-blue,
    .payAllFullLink.cc_pay_all_full_link.textbutton-blue {
        font-size: $s16px;
    }

    .cc_nav-tabs_anchor {
        color: $color-08;
    }
}

.cc_panel_invoice_detail,
.cc_panel_invoice_payment_single {
    .cc_invoice_header_container {
        .invoice_label {
            text-align: left;
        }

        .cc_invoice_header_col .row > div:first-child {
            text-align: left;

            div {
                float: none !important;
            }
        }
    }
}

@media only screen and (min-width: $sm) {
    #searchInvoiceForm {
        display: inline-flex;
        align-items: flex-end;
    }

    #searchInvoiceForm + .searchButtons.cc_invoice_search_action {
        display: inline-block;
        vertical-align: unset;

        .searchInvoice {
            margin: 0px 30px;
        }

        .icon.icon-ic_search {
            font-size: 1.375rem;
            display: block;

            &:hover {
                color: $color-01;
            }
        }

        .cc_search_order--text.hidden-lg {
            display: none;
        }
    }
}

@media only screen and (min-width: $sm) and (max-width: $lg - 1) {
    #searchInvoiceForm {
        margin-bottom: 10px;
    }

    button.searchInvoice {
        margin: 0px 15px;
        color: $color-02 !important;
        border: none !important;
        min-width: auto !important;
        padding: 0px;

        &:hover {
            outline: none !important;
        }
    }
}

.cc_main_container--ar{
    .cc_myaccount_content{
        .cc_myaccount_user{
            p span:first-child{
                min-width: 100px;
            }
        }
    }
    .cc_myaccount_mywallet{
        .form-horizontal{
            .control-label{
                text-align: right;
            } 

            .checkbox{
                text-align: right;
            }
            .checkcontainer{
                text-align: right;

                .checkmark{
                    right: 0;
                    left: auto;
                }
            }

            .cc_cancel_po{
                float: right;
            }

            .cc_save_po{
                float: left;
            }
        }

        .cc_new_cc_form{
            .form-group{
                text-align: right;
            }

            .checkmark{
                left: auto;
                right: 0;
            }

            .cancelCC{
                float: right;
            }

            .saveCC{
                float: left;
            }
        }
    }

    .cc_invoice_table th {
        text-align: right;
    }

    .cc_panel_invoice_detail,
    .cc_panel_invoice_payment_single {
        .cc_invoice_header_container {
            .invoice_label {
                text-align: right;
            }
            .cc_invoice_header_col .row > div:first-child {
                text-align: right;
            }
        }

        .cc_invoice_action_container{
            float: right !important;
        }
    }

    .invoicePaymentContainer .cc_invoices_table th{
        text-align: right;
    }

    .invoiceContainer.cc_invoice_action_container{
        float: none;
    }

    .invoicePaymentContainer #frmTokenization .checkcontainer .checkmark {
        left: unset;
        right: 0;
    }
}