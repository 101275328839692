.cc_order_details {
    color: $color-02;
    .cc_order_confirmation,
    .cc_order_information {
        margin-bottom: 0px;
    }
    .panel-heading .panel-title {
        @extend %font-medium;
        font-size: $s30px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        color: $color-02;
        margin-top: 40px;
        text-transform: capitalize;

        @media only screen and (max-width: $sm - 1) {
            font-size: $s20px;
            font-weight: 500;
            line-height: 1.2;
            color: $color-02;
            margin-top: 26px;
        }
    }
    .panel-body {
        margin: 25px 0px 40px;
    }
    .order_details {
        color: $color-02;
        font-stretch: normal;
        font-style: normal;
        font-size: $s16px;
        letter-spacing: normal;
    }
    .order_details_label {
        @extend %font-regular;
        line-height: 1.19;
        display: inline-block;
        min-width: 150px;
    }
    .order_details_value {
        @extend %font-medium;
        line-height: 1.19;
    }
    .inventory {
        .STOCK {
            &_LOW,
            &_LOW p,
            &_UNAVAILABLE,
            &_UNAVAILABLE p {
                color: $orange-1 !important;
            }

            &_AVAILABLE {
                &,
                & p {
                    color: $color-10;
                }
            }
        }
    }

    @media only screen and (max-width: $sm - 1) {
        .h3.cc_title {
            font-size: $s14px;
            font-weight: 500;
            line-height: 1.14;
            text-align: left;
            color: $color-02;
        }
        .h5 {
            &.cc_buyer_information,
            &.cc_billing_address_label,
            &.cc_shipping_address_label {
                font-size: $s14px;
                font-weight: 500;
                line-height: 1.14;
                text-align: left;
                color: $color-02;
            }
        }
        .cc_buyer > span,
        .cc_billing_address p,
        .cc_billing_address span,
        .cc_shipping p,
        .cc_shipping span 
        .reviewPaymentSection .panel-body span {
            font-size: $s14px;
            line-height: 1.43;
            color: $color-02;
        }
        .cc_order_confirmation_guidingtext {
            &1, &2 {
                font-size: $s14px;
                line-height: 1.21;
                text-align: left;
                color: $color-02;
            }
        }
        .reviewPaymentSection .panel-heading .panel-title,
        .reviewCartSection .panel-heading .panel-title {
            font-size: $s14px;
            font-weight: 500;
            line-height: 1.14;
            color: $color-02;
            text-transform: uppercase;
        }
        .cc_total {
            .cc_grand_total .cc_total_label,
            .cc_grand_total .cc_total_amount {
                line-height: 1.2;
                text-align: right;
                color: $color-02;
            }

            & .cc_subTotal_label,
            & .cc_cashDiscount_label,
            & .cc_tax_label,
            & .cc_shipping_label {
                font-size: $s16px;
                line-height: 1.19;
                text-align: left;
                color: $color-02;
            }

            & .cc_subTotal,
            & .cc_cashDiscount,
            & .cc_tax,
            & .cc_shipping_charge {
                font-size: $s16px;
                font-weight: 500;
                line-height: 1.19;
                text-align: right;
                color: $color-02;
            }
        }
    }
    .gotoOrdersBtn {
        display: inline-block;
        margin: 32px 0px 0px;
        .orderHistory {
            &,
            &:link,
            &:visited,
            &:active,
            &:hover {
                font-size: $s18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: $color-01;
                text-decoration: none;
            }
        }

        @media only screen and (max-width: $sm - 1) {
            width: 100%;
        }
    }

    .cc_order_information .panel-body {
        margin-bottom: 0px;

        & p:last-child {
            margin: 0px;
        }
    }
}

/* Proof upload modal */
.cc_order_proof_upload_modal{
    .cc_modal_body{
        text-align: left;

        .files-upload-form{
            text-align: center;
        }
        .alert{
            color: $color-02;
            font-size: $s18px;
            line-height: 1.2;
            padding: 15px;
            @extend %font-medium;
            border: none;
            text-align: center;
    
            &.alert-success{
                background-color: $color-10;
                color: $color-02;
                line-height: 1.17;
            }
            &.alert-danger{
                background-color: $color-09;
                color: $color-white;
                font-size: $s16px;
                line-height: 1.19;
            }
            &.alert-info{
                background-color: $color-08;
                color: $color-white;
                font-size: $s16px;
                line-height: 1.19;
            }
        }

        .form-message__item{
            &.alert{
                display: none;
            }
        }
        label.btn{
            line-height: 2.5;
        }
        .upload-step{
            width: 85%;
            display: inline-block;
            margin-bottom: 25px;
            text-align: left;

            @media only screen and (max-width: $sm - 1) {
                width: 100%;
            }

            .upload-step__number{
                border-radius: 50%;
                display: inline-block;
                border: 2px solid $color-05;
                color: $color-05;
                width: 25px;
                height: 25px;
                text-align: center;
                font-size: 14px;
                line-height: 1.5;
            }
            .upload-step__text{
                margin-left: 15px;
                display: inline-block;
                color: $color-05;
                font-size: $s16px;

                .textbutton-blue{
                    font-size: inherit;
                    color: inherit;

                    &:hover{
                        text-shadow: none;
                    }
                }
            }

            &.step-is-active{
                .upload-step__number{
                    border-color: $color-02;
                    color: $color-02;
                }
                .upload-step__text{
                    color: $color-02;

                    .textbutton-blue{
                        cursor: pointer;
                        color: $color-08;

                        &:hover{
                            text-shadow: 1px 0px 0px $color-08;
                        }
                    }
                }
            }
        }
        
        #uploadProofFiles-label{
            .icon{
                transform: rotate(90deg);
                display: inline-block;
            }
        }
        .files-info-panel{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            background-color: $color-06;
            margin-top: 10px;
            padding: 10px;

            .proof-files-list{
                margin: 0 10px 0 0;
                font-size: $s14px;
                padding-left: 25px;

                .icon-ic_checkout{
                    color: $color-10;
                }
                .icon-ic_cross{
                    color: $color-09;
                }
                .proof-file-item{
                    margin: 0 5px;
                }
            }

            @media only screen and (max-width: $sm - 1) {
                flex-wrap: wrap;
                justify-content: center;

                .files-control{
                    margin-top: 15px;
                }
            }
        }
        #proof-files-remove{
            padding-left: 5px;
            padding-right: 5px;
        }
        
    }
}
.cc_main_container--ar {
    .cc_order_proof_upload_modal{
        .form-message{
            text-align: right;
        }
        .cc_modal_body .upload-step{
            text-align: right;
    
            .upload-step__text{
                margin-left: 0;
                margin-right: 15px;
            }
    
            .files-info-panel .proof-files-list {
                padding-right: 25px;
                padding-left: 0;
                margin-right: 0;
                margin-left: 10px;
                max-width: 340px;
            }
        }
    }

    .cc_order_details{
        .cc_order_confirmation_guidingtext1,
        .cc_order_confirmation_guidingtext2
        {
            text-align: right;
        }
    }
}

.temporaryHidden {
    display: none !important;
}