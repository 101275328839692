.productListHeader {
    .cc_category_name {
        margin-top: 0;
        margin-bottom: 0;
    }
    .cc_search_title {
        line-height: 1.17;
        font-size: $s30px;
        color: $color-02;
        margin-bottom: 24px;
    }
    .cc_product_results_tagline {
        color: $color-05;
        font-size: $s12px;
        line-height: 30px;
        display: block;
        width: 100%;
        text-align: right;
    }
    .cc_search_message {
        line-height: 1.5;
        color: $color-02;
    }
    .page-size-option {
        width: 100%;
    }
    .cc_page_size_control {
        padding: 0 16px 0 25px;
        margin-top: 0;
        margin-right: 32px;
        &:hover,
        &:focus,
        &:active {
            background-color: $gray-1;
        }
        .fa {
            color: $color-02;
        }
    }
    .cc_sort_options {
        border-color: $color-06;
        border-style: solid;
        border-width: 0 0 0 2px;
        .btn-dropdown {
            border-width: 2px 2px 2px 0;
            &:hover,
            &:active,
            &:focus {
                background-color: $gray-1;
            }
        }
    }
    .fa {
        color: $color-02;
    }
    .cc_filter_breadcrumb {
        background-color: transparent;
        border-color: $color-06;
        border-style: solid;
        border-width: 2px;
        color: $color-02;
        border-radius: 0;
        font-weight: 500;
        &:active,
        &:focus,
        &:hover {
            background-color: $gray-1;
        }
        a {
            color: $color-17;
        }
    }
    .cc_remove_filters {
        color: $color-08;
        text-decoration: none;
    }
    .panel-body {
        padding-top: 0;
    }
    .cc_controls_panel {
        display: none;
    }
}

.productListPage,
.productComparePage {
    .cc_layout_option {
        min-width: auto;
        padding: 7px 12px 7px;
        .fa {
            width: 25px !important;
            font-size: 1.33333333em;
        }
    }
    .cc_page_header{
        @media only screen and (min-width: $md) {
            padding: 0 0 0 35px;
        }
        @media only screen and (min-width: $lg) {
            margin-right: -30px;
        }
    }

    .cc_results_list{
        @media only screen and (min-width: $md) {
            padding: 0 0 0 35px;

            &.cc_grid_container{
                padding: 0 0 0 40px;
            }
        }
    }

    .cc_grid_container {
        display: flex;
        flex-wrap: wrap;

        .cc_product_item {
            height: 100%;

            .thumbnail {
                height: 100%;
                display: flex;
                flex-direction: column;

                .cc_product_grid_actions {
                    margin-top: auto;
                }
            }
        }

        .wishButtons {
            text-align: center;

            .pickWish {
                &+button{
                    margin-left: 12px;
                }
            }
            .caret {
                margin-left: 0;
            }
        }

        @media only screen and (max-width: $md - 1) {
            .cc_product_name_img {
                background-color: $gray-1;
                display: block;

                .img-responsive {
                    width: 100%;
                    height: auto;
                    max-width: 400px;
                }
            }
        }
    }
    
    .wishButtons {
        li:last-child {
            .pickWish:hover {
                font-weight: 400;
                text-shadow: 1px 0px 0px $color-08;
                background: none;
            }
        }

        button.pickWish {
            padding: 0 1rem;
            line-height: 1;
            height: auto;
        }
    }

    .dropdown-toggle {
        min-width: auto;
    }
    .productListContent {
        .cc_addto_compare {
            width: auto;
        }
    }
    div.reorderContainer {
        padding: 0;

        @media only screen and (max-width: $sm) {
            display: none;
        }
    }
}

.productListPage{
    .cc_wishlist {
        float: left;
        margin-right: 40px;
    }
}

.productComparePage {
    @media only screen and (min-width: $lg) {
        width: 1170px;
    }
    .cc_grid_item {
        .cc_grid_image_container {
            display: flex;
            justify-content: center;
            a {
                display: flex;
                height: 100%;
                align-items: center;
            }
            img {
                max-height: 100%;
                width: 100%;
            }
        }
        .cc_price_container {
            margin: 10px 0 0;
        }
    }
    .product_table_label {
        margin-bottom: 0;
        padding-left: 10px;
    }
    .table-responsive {
        margin-top: 0;
    }
    .compareCol {
        padding-top: 0;
        width: 1%;
        max-width: 292px;
        .cc_product_container {
            position: relative;
            padding-top: 26px;
        }
    }
    .compare-products {
        display: block;
        width: 100%;
    }

    .cc_wishlist{
        margin-right: 0;
    }

    @media only screen and (max-width: $sm - 1) {
        .compare-wishlist-container{
            .compareBasicInfo{
                .dropdown.open{
                    .btn-default{
                        font-weight: 400;
                    }
                }
                .dropdown-backdrop{
                    display: none;
                }
            }
        }
    }
}

.cc_main_container--ar.productListPage,
.cc_main_container--ar.productComparePage {
    .cc_page_header{
        @media only screen and (min-width: $md) {
            padding: 0 20px 0 0;
        }
        @media only screen and (min-width: $lg) {
            margin-right: 0;
            margin-left: -30px;
        }
    }
    .cc_results_list{
        @media only screen and (min-width: $md) {
            padding: 0 35px 0 0;
        }
        
        &.cc_grid_container{
            @media only screen and (min-width: $md) {
                padding: 0 40px 0 0;
            }
        }
    }

    .cc_grid_item .cc_compare{
        float: left;
    }

    .cc_yousave_price {
        margin-left: 0px !important;
    }

    @media only screen and (max-width: $sm - 1) {
        .compareTray .cc_remove_from_compare {
            right: auto !important;
            left: -5px !important;
            position: absolute !important;
            top: 0 !important;
        }

        .compareAddToCol {
            .cc_add_to_cart_btn {
                padding: 0px !important;
            }
        }

        .compare-wishlist-container {
            .wishButtons > .btn-group {
                display: flex;
                align-items: center;

                .dropdown-toggle {
                    margin: 0px !important; 
                }
            }
        }
    }

    .tiered-info{
        &::before{
            padding-right: 0;
        }
    }
}

.cc_main_container--ar.productListPage{
    
    .product-list_filter-actions{
        .col-xs-6{
            &:nth-of-type(odd){
                padding-left: 5px;
                padding-right: 0;
            }
            &:nth-of-type(even){
                padding-left: 0;
                padding-right: 5px;
            }
        }
    }
    .filterContainer{
        .cc_title{
            text-align: right;
        }
        .cc_filter_options {
            text-align: right;
        }
        .badge{
            padding-left: 0;
            padding-right: 7px;
        }
    }

    .cc_filter_breadcrumb_panel{
        padding-right: 15px;
        padding-left: 0;

        @media only screen and (max-width: $sm - 1) {
            .cc_filter_breadcrumb_container{
                clear: both;
                padding: 0px 25px;
    
                .cc_filter_breadcrumb_list{
                    padding-right: 0; 
                }
            }
        }
 
    }

    .cc_controls_list{
        padding-right: 0;

        >li{
            float:right;
        }
    }

    .productListHeader {
        .cc_product_results_tagline {
            text-align: left;
        }
        .cc_category_details .flex-header .cc_category_name{
            text-align: right;
        }
        .cc_page_size_control{
            padding: 0 25px 0 16px;
            margin-left: 32px !important;
            margin-right: 0;
        }
    }

    .btn .caret{
        margin-left: 0;
        margin-right: 5px; 
    }

    .cc_grid_container .wishButtons .pickWish + button{
        margin-right: 12px;
        margin-left: 0;
    }

    .btn-group .dropdown-menu{
        text-align: right;
    }

    .cc_page_controls{
        .dropdown-menu{
            right: 0;
            left: auto;
            
            button.btn-default{
                text-align: right;
            }
        }
    }

    .cc_sort_controls{
        .dropdown-menu{
            right: auto;
            left: 0;
            width: auto;

            button.btn-default{
                text-align: right;
            }
        }
    }

    .price-and-tax-container .product-price{
        padding-right: 0 !important;
    }

    .cc_qty_control_row{
        padding-right: 0;
        padding-left: 5px;
    }

    .cc_results_list .cc_qty_control_row label{
        padding: 0 0 0 8px;
    }

    .compareTrayControls{
        .cc_goto_prod_compare{
            margin-left: 15px;
            float: left;  

            @media only screen and (max-width: $md) {
                margin-left: 0;
                float: none;
            }
        }
        .cc_clear_compare{
            clear: both;
            float: right;
            margin-left: 15px;
        }
    }

    .compareTray{
        @media only screen and (max-width: $sm - 1) {
            .close-wrapper {
                right: auto;
                left: 0;
            }
        }
        .cc_remove_from_compare{
            left: 15px;
            right: auto;
        }
        .compare-tray-row.well .scroll-plane{
            .col:nth-child(n+2){
                margin-right: 16px;
            }
            .panel-body .cc_compare_tray_name{
                text-align: right;
            }
        }
    }

    @media only screen and (min-width: $md) {
        .compareTrayProd .cc_compare_tray_name{
            text-align: right;
            padding-right: 15px;
            padding-left: 55px;
        }
    }

    .cc_compare_tray_controls{
        float: left;
    }

    .cc_product_item{
        &.cc_grid_item{
            .cc_wishlist{
                margin-right: 0;
            }
        }
        &.cc_row_item{
            /*.cc_compare{
                float: left;
                margin-left: 90px;
            }*/
            .cc_wishlist{
                float: right;
                margin-right: 0;
                margin-left: 40px;
            }
        }
    }

    #filtersModal {
        h5.cc_title{
            text-align: right;
        }
        .cc_filter_value{
            float: right;
        }
        .cc_filter_breadcrumb_panel .cc_filter_breadcrumb{
            margin-right: 0;
            margin-left: 5px;
        }
    }
}

.productComparePage.cc_main_container--ar{
    #osf_compare .compare-remove-prd{
        left: 9px;
        right: auto;
    }

    .price-and-tax-container .product-price{
        padding-right: 0 !important;
    }

    .cc_grid_container .wishButtons .pickWish + button{
        margin-right: 12px;
        margin-left: 0;
    }

    .wishButtons .dropdown-menu{
        text-align: right;
    }

    .cc_results_list .cc_qty_control_row label{
        padding: 0 0 0 8px;
    }

    .gp_quantity_block .cc_qty_control_row{
        padding-right: 0;
        padding-left: 5px;
    }

    .cc_compare_spec_price_item{
        display: inline-block;
    }

    .productCompareBasicTable {
        .compareCol{
            text-align: right;
        }
    }
}

div.cc_main_container.productListPage {
    margin-top: 0px;
}

.productListPage,
.productComparePage {
    .compareCol .cc_grid_image_container .cc_product_name_img {
        width: 195px;
        height: 195px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .compareTrayProd .cc_product_name_img {
        width: 75px;
        height: 75px;
        display: block;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .minimize-tray {
        color: $color-08;
    }
    
    .cc_grid_item {
        padding: 0;
        .cc_grid_product_info,
        .cc_price_container,
        .cc_product_grid_actions {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 0;
        }
        .cc_price_container {
            margin-bottom: 20px;

            &.cc_grid_price_info {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;

                .tiered-info {
                    order: -1;
                    @media only screen and (max-width: $sm - 1) {
                        margin-bottom: $s18px;
                        width: 100%;
                    }
                }
            }
        }
        .cc_grid_image_container {
            padding: 0;
            background-color: $gray-1;
            border-top-width: 1px;
            border-top-style: solid;
            border-top-color: $gray-1;

            .img-responsive {
                max-width: 100%;
            }
        }
        .cc_product_name {
            font-size: $s20px;
            line-height: 1.2;
        }
        .cc_desc {
            height: 2.6rem;
        }
        .cc_availability {
            margin: 10px 0;
            padding: 0;
            height: 1em;
            line-height: 1em;
            @media only screen and (min-width: $md) and (max-width: $lg - 1) {
                height: 2em;
            }
            @media only screen and (max-width: $sm - 1) {
                // margin: 0;
                // padding: 0;
                // height: auto;
                // line-height: 1em;
                // margin-top: 8px;
                display: none;
            }
        }
        .cc_price {
            text-align: left;
        }
        hr {
            border-color: $color-06;
            padding-bottom: 15px;
            margin: 0;
        }
        .cc_base_price {
            color: $color-04;
        }
        .cc_base_price_value {
            text-decoration: none;
        }
        .compare-wishlist-container {
            height: 38px;
            margin-bottom: 5px;
        }
        .cc_wishlist {
            display: inline-block;
            width: auto;
            float: none;
        }
        .cc_compare {
            margin-left: 0;
            float: right;
        }
        .discount-container {
            justify-content: space-between;
            margin-top: 10px;
            margin-bottom: 25px;
            margin-right: -5px;

            @media only screen and (min-width: $md) and (max-width: $lg - 1) {
                height: 2.5em;
            }
            @media only screen and (max-width: $sm - 1) {
                display: block;
                margin: 0px;

                .cc_baseprice {
                    display: inline;
                }
                
                .cc_yousave_price {
                    display: inline-block;
                }
            }
            .cc_baseprice {
                padding-right: 4px;
            }
            &_ar {
                margin-right: 0px;
                margin-left: -5px;

                @media only screen and (max-width: $md) {
                    margin-right: 0px;
                    margin-left: 0;
                }

                .cc_baseprice {
                    padding-right: 0px;
                    padding-left: 4px;
                }
            }
        }
        .price-and-tax-container {
            @media only screen and (min-width: $md) and (max-width: $lg - 1) {
                height: 3em;
            }
            .product-price {
                padding-right: 4px;
            }
        }
        .tiered-container {
            @media only screen and (min-width: $md) and (max-width: $lg - 1) {
                height: 6.5em;
            }
        }
        .tiered-price {
            margin-top: 17px;
            @media only screen and (max-width: $sm - 1) {
                margin-top: 0;
            }
        }
        .rfq-label-wrap {
            margin-top: 19px;
            color: $color-02;

            @media only screen and (max-width: $sm - 1) {
                font-size: $s14px;
                line-height: 1.14;
            }
        }
        .tier-info-grid {
            display: block;
        }
        .tier-info-list {
            display: none;
        }
    }
    .cc_row_item {
        .panel-body {
            padding: 0;
        }
        .cc_product_link {
            margin-top: 20px;
            a {
                font-size: 20px;
            }
        }
        .cc_price_container {
            padding-top: 24px;

            & > a.cc_label {
                float: none;
            }
        }
        .cc_yousave_price {
            margin-left: 13px;
        }
        .cc_qty_control_row {
            padding-right: 0;
            input {
                @media only screen and (min-width: $md) and (max-width: $lg - 1) {
                    width: 55px;
                }
            }
        }
        .tiered-container {
            text-align: right;
        }
        .tiered-price {
            margin-top: 17px;
        }
    }
    .cc_product_item {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }
    .cc_grid_product_info {
        hr {
            margin-top: 16px;
        }
    }
    .cc_grid_product_info {
        padding-top: 20px;
    }
    .cc_baseprice,
    .cc_yousave_price {
        display: inline-block;
        padding: 0;
    }
    .cc_product_item_row {
        display: flex;
    }
    .price-qty-addToCart {
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }
    .cc_price_container {
        margin-bottom: 12px;

        &.cc_row_price_info {
            .tiered-info {
                float: right;
                margin-top: 30px;
            }
        }
    }
    .cc_price {
        font-size: $s20px;
        height: 24px;
        line-height: 1.2;
        color: $color-02;
        font-weight: 500;
        text-align: right;
    }
    .discount-container {
        margin-bottom: 12px;
    }
    .cc_product_container {
        @media only screen and (min-width: $md) {
            padding: 0px 10px;
        }
        h5.cc_product_link {
            height: 48px;
            margin-bottom: 16px;
            margin-top: 0;
        }
    }
    .cc_baseprice {
        font-size: $s16px;
        line-height: 1.19;
        color: $color-04;
        font-weight: 500;
    }
    .cc_yousave_price {
        display: inline-block;
        color: $color-10;
        font-size: $s16px;
        line-height: 1.19;
        font-weight: 500;
        margin-left: 0px;
    }
    
    .cc_compare {
        display: inline-block;
        vertical-align: top;
        margin: 0;

        .btn {
            margin: 0;
        }
    }
    .cc_wishlist,
    .cc_compare {
        .wishButtons .form-group {
            margin-bottom: 0px;
        }
        .btn.btn-sm {
            padding: 9px 0;
            line-height: 1;
            height: auto;
        }
        .btn.dropdown-toggle {
            min-height: 4px;
            .caret {
                margin-top: 0px;
            }
        }
    }
    .cc_product_name {
        font-size: $s18px;
        color: $color-02;
        font-weight: 500;
        line-height: 1.17;
    }
    .cc_availability {
        padding: 12px 0 13px;
    }
    .available {
        color: $color-10;
        font-size: $s14px;
    }
    .out-of-stock,
    .low-stock {
        font-size: $s14px;
        color: $orange-1;
    }
    .cc_desc {
        height: 2.6rem;
        margin-bottom: 7px;
        color: $color-04;
        font-weight: 300;
        font-size: $s14px;
        line-height: 1.43;
        overflow: hidden;
    }
    .cc_product_container {
        .modal-body {
            .cc_desc {
                height: 2.6rem;
            }
        }
    }
    .price-and-tax-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .product-price {
            margin-bottom: 0;
        }
    }
    .product-price {
        margin-bottom: 12px;
    }
    .product-tax {
        font-size: $s16px;
        font-weight: 500;
        line-height: 1.19;
        color: $color-04;
        padding-top: 4px;
    }
    .cc_qty_control_row {
        padding-right: 5px;
        padding-left: 0;
        .qty {
            border-width: 0 0 1px 0;
            color: $color-03;
            border-color: $color-05;
        }
        input {
            line-height: normal;
            width: 60px;
        }
    }
    .qty_label {
        font-size: $s16px;
        height: 34px;
        line-height: 34px;
        padding: 0 12px 0 0;
        color: $color-04;
    }
    .cc_price_container,
    .qty-addToCart {
        padding: 0 20px;
    }
    .qty-addToCart {
        .cc_action {
            padding: 0;
        }
    }
    .cc_add_to_btn {
        margin-top: 0;
    }
    .cc_results_list {
        .cc_input_group {
            padding: 0;
        }
        .cc_qty_control_row {
            label {
                padding: 0 8px 0 0;
            }
            input {
                padding: 0;
                text-align: center;
            }
        }
    }
    .cc_prod_list_item_label {
        color: $color-02;
        text-align: center;
    }
    div.minicart {
        margin-bottom: 0;
    }
    .cc_filter_breadcrumb_panel {
        padding-left: 15px;
    }
    .cc_filter_breadcrumb_list {
        margin-left: 0;
    }
    .cc_controls_list {
        margin-left: 0;
        > li {
            float: left;
            padding: 0;
        }
    }
    .measure-each {
        font-size: 1rem;
        line-height: 1.19;
        color: $color-04;
        font-weight: 500;
        text-decoration: none !important;
    }
    .tiered-info {
        color: $color-08;
        font-weight: 500;
        line-height: 1.14;
        text-decoration: none;

        &:before {
            padding-right: 8px;
        }

        &--vol {
            &::before {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-box-direction: normal;
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                width: 15px !important;
                background-color: transparent !important;
                content: "\f05a";
            }

        }
    }
    .plp-qty-control {
        display: flex;
        justify-content: space-between;
        &-btn {
            background-color: transparent;
            margin-top: 0;
        }
        &-icon {
            font-size: $s12px;
        }
        input {
            background-color: transparent;
        }
    }
    .btn-default {
        &:focus {
            background-color: $color-01;
            border-color: $color-01;
        }
    }
}

#osf_compare {
    .compare {
        &-heading {
            margin: 2rem 0 0;
            color: $color-02;
        }

        &-products {
            padding-left: 0;
            padding-right: 0;
        }

        &-remove-prd {
            position: absolute;
            right: 9px;
            top: 0px;
            font-size: $s30px;
        }

        &-spec {
            &-table {
                color: $color-02;
                th {
                    @extend %font-medium;
                }
                tr {
                    border-bottom: solid 1px $color-12;
                }
            }
        }
        &-qty-wrap {
            display: flex;
        }
        &-qty-control {
            display: flex !important;

            &-btn {
                background-color: transparent;
                border: none;

                &:focus {
                    border: none;
                    outline: none;
                }
            }

            &-icon {
                font-size: $s12px;
            }
        }
    }
    .STOCK {
        font-size: $s14px;

        &_LOW,
        &_UNAVAILABLE {
            color: $orange-1;
        }

        &_AVAILABLE {
            color: $color-10;
        }
    }
    .price-and-tax-container {
        height: 1.5em;
    }
    .cc_price_container {
        margin-bottom: 0;
    }
}

.compareTray {
    .cc_remove_from_compare {
        position: absolute;
        z-index: 1;
        right: 20px;
        top: 20px;
    }

    @media only screen and (max-width: $sm - 1) {
        .close-wrapper {
            position: absolute;
            z-index: 9999;
            top: -44px;
            right: 0px;
            background-color: #fff;
            width: 44px;
            height: 44px;

            .close {
                height: 100%;
                width: 100%;
                text-align: center;
                line-height: 44px;
                box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
                opacity: 1;
            }
        }

        .cc_navbar-fixed-bottom {
            backdrop-filter: blur(30px);
            box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);
        }
        
        // reset
        .compare-tray-row.well {
            height: 259px;
            max-height: 259px;
            background-color: #fff;
            padding: 8px;
            margin-bottom: 0;

            ::-webkit-scrollbar {
                // height: 12px !important;
                display: none;
            }
            
            /*
             // Track
            ::-webkit-scrollbar-track {
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
               -webkit-border-radius: 5px !important;
               border-radius: 5px !important;
             }
            
             // Handle
             ::-webkit-scrollbar-thumb {
               -webkit-border-radius: 5px !important;
               border-radius: 5px !important;
               background: $color-18 !important; 
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important; 
             }
            
             ::-webkit-scrollbar-thumb:window-inactive {
               background: $color-18 !important; 
             }
             */
            
            .scroll-pane {
                width: auto;
                display: flex;
                overflow-x: scroll;
                overflow-y: hidden;
                height: 67%;

                .col {
                    width: 25%;
                    height: 153px;
                    max-height: 153px;
                    position: relative;
    
                    &:nth-child(n+2) {
                        margin-left: 16px;
                    }

                    .panel-body {
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        // align-items: flex-start;
                        max-width: 92px;
                        width: 92px;
        
                        .cc_compare_tray_img {
                            max-height: 92px;
                            height: 92px;
                            margin: 0;
        
                            img {
                                max-width: 92px;
                                text-align: center;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                object-fit: cover;
                            }
                        }
        
                        .cc_compare_tray_name {
                            margin-top: 8px;
                            font-size: 11px;
                            line-height: 1.18;
                            color: $color-02;
                            text-transform: none;
                            text-align: left;
                            height: 20px; // attention: this controls the equal position of the clear btn´s
                        }

                        .cc_remove_from_compare {
                            position: relative;
                            z-index: 1;
                            right: -74px;
                            top: -120px;
                            background-color: #fff;
                            width: 18px;
                            height: 18px;
                            opacity: 1;
                            font-size: 18px;
                            font-weight: 100;
                            color: $color-04;
                        }
                    }
                }
            }
        }
        .compareTrayControls {
            display: flex;
            flex-flow: column-reverse;
            position: fixed;
            bottom: 0px;
            width: 100%;
            text-align: center;

            a.cc_clear_compare {
                height: 38px;
                font-size: 14px;
                font-weight: normal;
                line-height: 2.6;
                border-top: 1px solid $color-06;
                color: $color-08;
            }
            button.cc_goto_prod_compare {
                height: 46px;
                font-size: 16px;
                font-weight: normal;
                line-height: 1.5;
                text-align: center;
                color: $color-02;
                background-color: $color-01;
            }
        }

        #compareCollapse.collapse:not(.in) {
            display: none;
        }
        #compareCollapse.collapsing .compareTrayControls {
            display: none;
        }
    }
}

/**** List View styling ****/
.productListContent.cc_results_list:not(.cc_grid_container) {
    margin: 0px;

    .cc_product_container {
        height: 220px;
    }

    .cc_row_item {
        .cc_panel {
            padding: 0px;
        }
        .tier-info-grid {
            display: none !important;
        }
        .tier-info-list {
            display: block !important;
            
            a {
                margin-top: 15px !important;
                display: block;
            }
        }
    }

    .cc_product_image {
        height: auto;
        background-color: $gray-1;
        padding: 0px;
        display: flex;
        align-items: center;

        a {
            height: 220px;
        
            img {
                height: 100%;
                width: 100%;
            }
        }
    }

    .cc_price_container {
        text-align: right;

        .discount-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    .rfq-label-wrap {
        padding-top: 24px;
        color: $color-02;
    }

    @media only screen and (min-width: $md) and (max-width: $lg - 1) {
        .cc_row_product_info,
        .price-qty-addToCart {
            padding-right: 0px;
        }

        .qty-addToCart {
            padding: 0px;
        }
    }

    @media only screen and (max-width: $sm - 1) {
        .cc_product_item_row {
            flex-direction: column;

            .cc_product_image {
                height: auto;
            }

            .cc_product_name_img {
                background-color: $gray-1;
                display: block;

                .img-responsive {
                    width: 100%;
                    height: auto;
                    max-width: 400px;
                }
            }
        }
    }

    .price-and-tax-container {
        justify-content: flex-end;
    }
}

.filterContainer {

    &:focus{
        outline: none;
    }

    #collapseFilters {
        & > .panel-body {
            overflow: hidden;
            transition: .5s ease;
        }
    }

    .cc_filter_container {
        padding: 9px 20px;
        border: solid 2px $color-06;
        margin-bottom: 32px;

        &:focus{
            outline: none;
        }
    }
    .panel-heading.cc_heading {
        border: none;
        padding: 15px 0;
    }
    .cc_filter_options{
        text-align: left;
    }
    h3 {
        font-size: $s16px;
        font-weight: 500;
        line-height: 1.19;
        color: $color-02;
        padding-bottom: 0;
    }
    h5 {
        padding-top: 16px;

        a:hover{
            text-decoration: none;
        }
    }
    h5 {
        .fa { 
            width: 9px !important;
        }
    }
    hr {
        padding-top: 16px;
        border-top-width: 0;
        border-bottom-width: 1px;
        border-color: $color-06;
        border-style: solid;
        margin-top: 0;
        margin-bottom: 0;
        display: none;
    }
    .panel-body {
        padding-top: 0;
    }
    .cc_spec_values_container {
        padding-top: 2px;
        &.in {
            & + hr {
                display: block;
            }
        }
        // &.collapsing {
            // transition: none;
            // -webkit-transition: none;
            // height: auto !important;
        // }
    }
    .list-group {
        margin-bottom: 0;
        padding: 0px;
    }
    .list-group-item {
        border: none;
    }
    .cc_spec_filter_option {
        padding: 2px 0;
    }
    .badge {
        background-color: transparent;
        font-size: $s14px;
        line-height: 1.14;
        color: $color-04;
        padding-right: 0;
        font-weight: 400;
    }
    .cc_filter_value {
        padding: 0px 12px;
    }
    .cc_filter_checkbox {
        width: 21px;
        height: 21px;
        margin-top: 0;
    }
    .cc_spec_group_container {
        &:last-child {
            hr {
                display: none;
            }
        }
        a {
            font-size: $s14px;
            font-weight: 400;
            line-height: 1.43;
            color: $color-02;
            display: block;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
    .cc_filter_buttons {
        display: flex;
        justify-content: space-between;
        padding: 26px 0 0 0;
    }
    input[type="checkbox"] {
        display: none;
    }
    .cc_filter_value {
        &:before {
            content: '';
            display: inline-block;
            height: 14px;
            width: 14px;
            padding: 2px;
            border: 1px solid $color-04;
            font-weight: 700;
            position: absolute;
            left: 0;
            margin: 2px 0px 0px 5px;
        }
    }
    input[type="checkbox"]:checked {
        &~.cc_filter_value {
            &:before {
                content: "\e907";
                font-family: 'imdadicon' !important;
                speak: none;
                font-style: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: $s10px;
                padding-right: 0px;
            }
        }
    }
    .cc_spec_values_container_ar {
        .center-block {
            padding-left: 0px;
            padding-right: 20px;
        }
        .cc_filter_value:before {
            left: unset;
            right: 0px;
        }
    }
    .spec_slider_container {
        padding: 13px 0px 0px 0px;
        .noUi-target {
            width: 100%;
            padding: 0px 15px;
        }
        .upperDisp,
        .lowerDisp {
            margin-right: 0;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .noUi-handle {
            height: 30px;
            width: 30px;
            background-color: $color-01;
            border: none;
            top: -10px;
            left: -15px;
            &:before,
            &:after {
                display: block;
                left: 10px;
                top: 8px;
            }
            &:after {
                left: 18px;
            }
        }
        .cc_upper_disp,
        .cc_lower_disp {
            width: 30px;
            text-align: center;
            display: flex;
            justify-content: center;
        }
    }
}

//IE
_:-ms-fullscreen, :root .productListPage .wishButtons .icon-ic_arrow_down_small {
    margin-top: 0;
}

_:-ms-fullscreen, :root .productComparePage {
    @media only screen and (min-width: $lg) {
        width: 1300px;
    }
}

.compareTrayProd {

    .panel-body {
        padding: 25px 0 0;
    }

    .cc_product_name_img {
        height: 100px;
        overflow: hidden;

        @media only screen and (max-width: 370px) {
            display: block;
            height: 50px;
        }
    }

    &ImageSec {
        margin: auto -15px;

        @media only screen and (max-width: $sm - 1) {
            margin: auto -3px;
        }
    }

    .img-responsive {
        max-height: 100%;
        width: 100%;

        @media only screen and (max-width: 370px) {
            max-width: 50px;
        }
    }

    .cc_compare_tray_name {

        @media only screen and (min-width: $md) {
            text-align: left;
            padding-right: 55px;
        }

        .cc_product_name {
            font-size: $s12px;
            font-weight: 400;

            @media only screen and (max-width: 370px) {
                width: 50px;
                word-break: break-all;
            }
        }
    }
}

.productComparePage {
    .cc_spec_table_label, 
    .compareTextRow {
        color: $color-02;

        .compareCol {
            padding: 10px;

            .rfq-label-wrap { 
                margin: 0px;
            }
        }
    }

    .cc_price_container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .discount-container {
        width: 100%;
        margin-bottom: 6px;
        flex-wrap: wrap;
        white-space: normal;

        .cc_baseprice {
            overflow: visible;
            white-space: nowrap;
        }
    }

    .cc_yousave_price .cc_yousave {
        padding: 0px 5px;
    }

    .display_mobile_scroll {
        @media only screen and (max-width: $md - 1) { 
            overflow-x: auto;
            -webkit-overflow-scrolling: auto;
            

            .table-responsive {
                border: none !important;
                margin-bottom: 10px;

                th,
                td {
                    white-space: normal !important;
                }
            }

            h5.cc_product_link .cc_product_name {
                font-size: $s16px;
            }

            .compare-qty-wrap {
                min-width: 180px;
            }

            ::-webkit-scrollbar {
                height: 12px !important;
             }
            
             /* Track */
            ::-webkit-scrollbar-track {
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
               -webkit-border-radius: 5px !important;
               border-radius: 5px !important;
             }
            
             /* Handle */
             ::-webkit-scrollbar-thumb {
               -webkit-border-radius: 5px !important;
               border-radius: 5px !important;
               background: $color-18 !important; 
               -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5) !important; 
             }
            
             ::-webkit-scrollbar-thumb:window-inactive {
               background: $color-18 !important; 
             }
        }
    }

    .productComparePage .cc_product_container h5.cc_product_link {
        @media only screen and (max-width: $md - 1) { 
            height: auto;
        }
    }
}

.addToCartConfirmation_container {
    .close_icon_container {
        float: none !important;
    }

    .close_confirmation {
        cursor: pointer;
    }

    .confirmation_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & > div:last-child {
            display: flex;
            justify-content: space-evenly;
        }

        &_ar {
            flex-direction: row-reverse;
            font-family: 'Cairo', sans-serif;

            .display_label {
                text-align: right;
            }

            & > div:last-child {
                flex-direction: row-reverse;
            }
        }
    }

    .display_label {
        margin: 0px;
        font-size: $s20px;
        color: $color-02;
    }

    .gotoPLPbtn,
    .gotoCartbtn {
        max-width: 200px;
        width: 100%;
        margin: 5px;
    }

    @media only screen and (max-width: $md - 1) {
        .confirmation_container {
            flex-direction: column;
        }

        .display_label {
            text-align: center !important;
            margin: 0px 0px 15px;
        }

        .product-price-value {
            font-size: $s16px;
        }
    }

    @media only screen and (max-width: 450px) {
        .confirmation_container > div:last-child {
            flex-direction: column-reverse;
            align-items: center;
        }

        .gotoPLPbtn,
        .gotoCartbtn {
            min-width: 200px;
        }
    }
}

@media only screen and (max-width: $md - 1) {
    .contentBody.cc_content_body {
        padding-top: 0;
        background-color: $color-white;
    }
    .productListHeader {
        &.cc_page_header {
            margin-top: $s24px;
            overflow: hidden;
        }
        .cc_controls_panel {
            margin-bottom: 0;
            padding: 0;
        }
        .cc_category_details {
            .cc_category_desc ~ p {
                display: none !important;
            }
            // .flex-header > small ~ p {
            //     display: none !important;
            // }
            .flex-header {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: flex-end;

                .cc_category_name {
                    font-size: $s20px;
                    font-weight: 500;
                    line-height: 1.2;
                    text-align: left;
                    color: $color-02;
                    justify-self: left;
                    flex-grow: 1;
                }
                .cc_product_results_tagline {
                    font-size: $s12px;
                    font-weight: normal;
                    font-style: normal;
                    line-height: 1.17;
                    color: $color-05;
                    margin-top: 0;
                    align-self: center;
                    width: auto;
                }
            }
        }
        .cc_remove_filters {
            font-size: $s14px;
            line-height: 1.14;
            color: $color-08;
        }
        .cc_filter_breadcrumb {
            font-size: $s14px;
            font-weight: 500;
            line-height: 1.14;
            color: $color-02;

            a.remove i.icon.icon-ic_cross {
                font-size: $s10px;
                color: $color-04;
            }
        }
    }

    .modal-dialog.sort-dialog,
    .modal-dialog.filter-dialog {
        // margin: 0;
        height: 100%;
        margin-bottom: -48px;

        #collapseFilters {
            margin: 0 0 15px;
        }

        .btn-row {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }

        li.cc_sort_item,
        .btn {
            .icon {
                opacity: 0.75;
            }
        }

        .cc_sort_options {
            list-style: none;
            border: none;
            border-width: 0;
            margin: 0px 2px;
            padding: 0px;
        }

        .cc_product_sort {
            text-align: left;
        }

        .cc_sort_item {
            display: flex;
            justify-content: space-between;
            border-bottom: solid 2px #ebebeb;

            .icon {
                margin-top: 8px;
                font-size: 1.1rem;
            }

            .cc_sort_option {
                background-color: #fff;
                font-size: $s14px;
                line-height: 1.43;
                color: $color-02;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .productListPage {
        #filtersModal,
        #sortModal {
            border: 10px solid $color-02 !important;
            background-color: #fff;
            margin: 0;
            border: none;
            overflow-y: hidden;

            .panel-heading.cc_heading{
                padding: 30px 15px 5px;
            }
            button.close {
                width: 100%;
                margin: 0;
                padding: 0;
                opacity: 1;
                color: $color-04;
            }

            .cc_sort_option {
                width: 100%;
                display: flex;
                justify-content: flex-start;
            }

            .icon {
                font-size: $s24px;
            }

            h3.cc_title {
                font-size: $s20px;
                font-weight: 500;
                line-height: 1.2;
                text-align: center;
                color: $color-02;
                margin-top: 21px;
                margin-bottom: 10px;
            }

            h5.cc_title {
                font-size: $s14px;
                line-height: 1.43;
                text-align: left;
                color: $color-02;
            }

            .cc_filter_value {
                float: left;
            }

            button.btn-viewresults {
                color: $color-02;
                font-size: $s16px;
                line-height: 1.5;
                position: absolute;
                bottom: 10px;
                height: 46px;
                text-shadow: none;
                font-weight: normal;
            }
        }
        #filtersModal{
            .panel-heading.cc_heading{
                /*height: 100%;*/
                padding-left: 0;
                padding-right: 0;
            }
            .PLP-filter-content{
                height: calc(100% - 110px);
                overflow: scroll;
                padding: 0 15px;
            }
            /*.cc_filter_breadcrumbs{
                display: none;
            }*/
            .cc_filter_value:before{
                margin: 0;
                height: 20px;
                width: 20px;
            }
            input[type="checkbox"]:checked {
                &~.cc_filter_value {
                    &:before {
                        font-size: 0.85rem;
                    }
                }
            }
            .cc_filter_breadcrumb_panel{
                padding-left: 0;
                padding-right: 0;

                .cc_filter_breadcrumb_list{
                    padding: 20px 0 0;
                    border-top: 2px solid $color-06;
                    margin: 0px 20px 15px;
                }

                .cc_filter_breadcrumb{
                    background-color: transparent;
                    border-color: $color-06;
                    border-style: solid;
                    border-width: 2px;
                    color: $color-02;
                    border-radius: 0;
                    font-size: $s14px !important;
                    font-weight: 500;
                    line-height: 1.14;
                    margin-right: 5px;

                    a{
                        color: $color-17;

                        .icon{
                            color: $color-04;
                            font-size: 1rem;
                        }
                    }
                }
            }
            .cc_filter_options {
                overflow: scroll;
                padding-bottom: 40px;
            }
            .cc_remove_filters_control{
                text-align: center;
                border-top: 1px solid $color-06;
                margin-bottom: 25px;

                .cc_remove_filters{
                    line-height: 1.14;
                    font-size: $s14px;
                    color: $color-08;
                    text-decoration: none;
                }
            }
        }
        .btn-default:focus {
            background-color: #fff;
            border: solid 2px $color-06;
        }
        .product-list_filter-actions {
            padding: 0 15px;
            margin-bottom: 15px;
            margin-left: 0;
            margin-right: 0;

            .col-xs-6:nth-of-type(odd) {
                padding-left: 0;
                padding-right: 5px;
            }
            .col-xs-6:nth-of-type(even) {
                padding-left: 5px;
                padding-right: 0;
            }

            .btn {
                font-size: $s14px;
                font-weight: 500;
                line-height: 3.5;
                text-align: center;
                color: $color-02;
                text-transform: uppercase;
                min-height: 51px;
                min-width: 140px;
                overflow-x: hidden;
                border-radius: 0;
                border: 2px solid $color-06;
                text-overflow: ellipsis;
                width: -webkit-fill-available;

                &-filter {
                    margin-left: 0;
                    p {
                        margin: 0;
                    }
                }
                &-sort {
                    p {
                        margin: 0;
                    }
                }
            }
        }
        .productListContent{
            justify-content: space-between;

            >.clearfix{
                display: none;
            }

            &.row{
                margin-left: 0;
                margin-right: 0;

                &:before, &:after{
                    display: none;
                }
            }
        }
        .cc_product_container {
            .included_items,
            .cc_qty_control_row,
            .cc_action,
            .cc_wishlist {
                display: none;
            }
        }
        .cc_grid_item {
            .cc_grid_product_info,
            .cc_price_container,
            .cc_product_grid_actions {
                padding: 8px 8px 0 8px;
                margin: 0;
            }
            
            .cc_grid_product_info {
                flex-grow: 1;
            }
            
            .cc_price_container {
                padding: 0 8px !important;
                flex-grow: 0;
                align-items: baseline;

                .gp_prod {
                    font-size: 14px;
                    line-height: 1.14;
                    color: $color-08;
                }
                .cc_price {
                    font-size: 14px;
                    line-height: 1.14;
                    text-align: left;
                    margin-bottom: 0;

                    .product-price-value {
                        color: $color-02;
                    }
                    .measure-each {
                        display: none;
                    }
                }
            }

            .cc_product_grid_actions {
                padding: 0 !important;
                margin: 0 !important;
            }

            .compare-wishlist-container {
                height: auto;
            }
        }
        .cc_grid_container .cc_product_container .cc_product_link {
            overflow: visible;
        }
        .cc_product_container{
            padding: 0 !important;
            width: 48%;
            margin-bottom: 25px;
        }
        .cc_product_container {
            h5.cc_product_link {
                height: auto;
                margin-bottom: 0;
                margin-top: 0;
            }
        }
        .cc_product_item {
            box-shadow: 0 4px 4px 0 rgba(0,0,0,.1);
            
            .thumbnail {
                height: 100%;
                margin-bottom: 0 !important;

                .caption {
                    padding: 8px;
                }
                .cc_product_name_img {
                    max-height: 140px;

                    &.img_responsive {
                        height: 0;
                    }
                    img {
                        min-height: 140px;
                        height: 140px;
                        object-fit: cover;
                    }
                }
            }

            .cc_product_link {
                a {
                    font-size: $s14px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: 1.14;
                    text-align: left;
                    color: $color-02;
                }
            }

            .description.cc_desc {
                display: none;
            }

            hr {
                display: none;
            }

            .cc_availability {
                font-size: $s14px;
            }

            .cc_price_container {
                span, p {
                    font-size: $s14px;
                    font-weight: normal;
                    line-height: 1.14;
                    color: $color-02;
                    margin-left: 4px;
                }

                .cc_price.cc_yousave_price {
                    span {
                        color: $color-10 !important;
                    }
                }
            }
        }
        .compare-wishlist-container {
            display: flex;
            justify-content: center;

            .cc_compare {
                float: unset;
            }
        }
    }

    .filterContainer {
        .cc_filter_container {
            background-color: #fff;
            padding: 0;
        }
    }

    .productComparePage {
        .container.cc_main_container.cc_tmpl_OneColRD {
            margin-bottom: -24px;
        }
    }
}

@media only screen and (min-width: $lg) {
    .productListPage {
        .cc_grid_container > .cc_product_container {
            width: 311px;
        }
    }
}

@media only screen and (max-width: $md - 1) {
    .cc_list_footer .col-xs-12 {
        display: flex !important; 
    }

    .promotion-box-LeftNav.promotion-box--visible {
        margin-top: 8px !important;
    }
}

.compareTrayControls .cc_clear_compare {
    display: block;
    margin-top: 15px;
}
