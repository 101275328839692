.modal {
    z-index: 2050;
    background-color: rgba($color-03, 0.5);
    padding-right: 0px !important;

    &-open {
        padding-right: 0px !important;
    }

    &-dialog {
        width: 760px;
        height: auto;
        margin: 55px auto;
    }

    &-content {
        padding: 56px;
        position: relative;
        height: 100%;
    }

    &-header {
        padding: 24px;

        .cc_close {
            position: absolute;
            top: 31px;
            left: 50%;
            transform: translateX(-50%);
            line-height: 20px;
            outline: none;

            span {
                // visibility: hidden;
                font-size: 0px;
                font-family: 'imdadicon' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                &:before {
                    content: "\e909";
                    visibility: visible;
                    width: 24.3px;
                    height: 24.3px;
                    font-size: 24.3px;
                }
            }
        }

        .cc_modal_title {
            text-align: center;
        }
    }

    &-body {
        height: calc(100% - 125px);
        text-align: center;
        overflow: auto;
    }

    &-footer {
        button,
        input {
            width: 170px;
            height: 46px;
            border-radius: 0px;
            margin: 0px 0px 0px 40px;
            font-size: 16px;
            line-height: 1.19;
            text-align: center;
            color: $color-02;
        }

        .btn + .btn {
            margin-left: 40px;
        }
    }

    @media only screen and (max-width: $sm - 1) {
        &-dialog {
            width: auto;
            height: auto;
            margin: 10px;
        }

        &-content {
            padding: 56px 28px 28px;
        }

        &-footer {
            display: flex;
            justify-content: space-evenly;

            button,
            input {
                margin: 0px;
                padding: 0 10px;
            }
        }
    }
}

.modal-backdrop {
    z-index: 1060;
}

.page-layout--arabic{
    #countrySwitcherMod .cc_modal_footer{
        text-align: left;

        .cc_set_country{
            margin-right: 40px;
            margin-left: 0;
        }
    }
}
