.acctmainSection{
    color: $color-02;

    @media only screen and (min-width: $md) {
        padding: 0 0 0 35px;
    }

    @media only screen and (min-width: $lg) {
        margin-right: -15px;
    }

    h3.panel-title.cc_title{
        font-size: $s30px;
        line-height: 1.17;
        @extend %font-medium;
        color: $color-02;
    }

    .cc_myaccount_profile {
        h3.panel-title.cc_title{
            font-size: $s20px;
            line-height: 1.2;
            color: $color-02;
            @extend %font-medium;
        }

        .ccrz_loading_gif {
            height: 56px;
            margin: 20px 10px 0px;
        }
    } 
    
    label{
        font-size: $s15px;
        line-height: 1.2;
    }

    .alert{
        color: $color-02;
        font-size: $s18px;
        line-height: 1.2;
        padding: 15px;
        @extend %font-medium;
        border: none;
        text-align: center;

        &.alert-success{
            background-color: $color-10;
            color: $color-02;
            line-height: 1.17;
        }
        &.alert-danger{
            background-color: $color-09;
            color: $color-white;
            font-size: $s16px;
            line-height: 1.19;
        }
        &.alert-info{
            background-color: $color-08;
            color: $color-white;
            font-size: $s16px;
            line-height: 1.19;
        }
    }

    .form-group{
        .help-block{
            .alert-danger{
                background-color: transparent;
                padding: 0;
                color: $color-09;
                font-size: $s14px;
                line-height: 1.2;
                margin-top: 5px;
                margin-bottom: 10px;
                font-weight: normal;
                text-align: left;
            }
        }

        &--has-error{
            label{
                color: $color-09;
            }
            .form-control{
                border-bottom-color: $color-09;
            }
        }
    }

    .cc_reset_password_form {
        .form-group {
            @media only screen and (max-width: $sm - 1) {
                .col-xs-3 {
                    width: 100%;
                }
                .col-xs-9 {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }

            @media only screen and (min-width: $sm) {
                display: flex;
                align-items: center;
            }
        }
    }
}

.cc_main_container--ar {
    .acctmainSection{
        color: $color-02;
    
        @media only screen and (min-width: $md) {
            padding: 0 35px 0 0;
        }
    
        @media only screen and (min-width: $lg) {
            margin-left: -15px;
            margin-right: 0;
        }
    
        .cc_reset_password_form{
            .form-group {
                .col-xs-3{
                    float: right;
                    text-align: right;
                }
            }
        }

        .form-group .help-block .alert{
            text-align: right;
        }
    }

    .cc_change_password{
        .cc_reset_form{
            .form-group > label{
                text-align: right;
            }
        }
    }
}
