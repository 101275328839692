/**** WIDGETS ****/
.widgets-container {

    .minicart, .wishlist-container, .reorderContainer {
        background-color: $color-white;
        display: block;
        margin-bottom: 30px;
    }

    .promotion-box-RightNav{
        .cc_body{
            padding-top: 0;
        }

        .wishlist-container{
            margin-top: 30px;
            padding: 0; 
        }

        .side-banner{
            position: relative;

            .cc_promo_link{
                display: block;
            }
        }
    }

    .panel.widget-panel, .panel.cc_re_order {
        @extend %font-medium;
        font-size: $s16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.19;
        letter-spacing: normal;
        border: solid 2px $color-06;
        background-color: $color-white;
        padding: 1.25rem;
        margin: 0px;
        .panel-heading {
            padding: 5px 30px 0 0;
            position: relative;
            text-transform: uppercase;
            .glyphicon {
                font-size: $s20px;

                &-heart-empty {
                    top: 4px;
                }
            }
        }
        .down-arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
        }
        .panel-title {
            display: inline-block;
            color: $color-02;
            margin-left: 10px;
            font-weight: 500;
            line-height: 1.19;
            padding-top: 2px;
            vertical-align: middle;
        }
    }

    .panel.cc_re_order {
        padding: 20px 10px;

        .panel-heading{
            padding: 5px 30px 0 10px;
        }

        .cc_reorder_details_row {
            margin: 0px -5px;
        }
    }

    .itemNr {
        padding: 0 5px;
        top: -1px;
        left: 14px;
        height: 16px;
        min-width: 16px;
        line-height: 16px;
        border-radius: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
    }
    .cc_prod_link,
    .cc_product_name_link {
        &,
        &:link {
            font-weight: normal;
            line-height: 1.5;
            color: $color-02;
            text-decoration: none;
            font-size: inherit;
            text-align: left;
            cursor: pointer;
        }
        &:hover {
            color: $color-01;
        }
    }
    .summary {
        border-top: none;
    }
    .cc_cart_item_count,
    .cc_subtotal {
        color: $color-02;
        font-size: $s20px;
    }
    span.cc_subtotal {
        margin-bottom: 15px;
    }
    .cc_item_heading,
    .cc_row_total,
    .sku,
    .updateCartButton {
        display: none;
    }
    .loggedInAction {
        text-transform: capitalize;
        padding: 0;
        text-align: right;
        margin-top: 5px;
        height: auto;

        @media only screen and (max-width: $sm - 1) {
            width: auto;
        }
    }
    .no_items {
        @extend %font-light;
        line-height: 1.5;
        text-align: center;
        color: $color-04;
    }
    .gp_btn_reorder {
        width: 100%;
        color: $color-02;
    }
    .cc_items {
        margin-bottom: 1rem;

        .cc_prod_name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .cc_quantity{
            display: flex;
            justify-content: flex-end;
        }
        .form-control{
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            color: $color-02;
            border-color: $color-06;
            width: 40px;
        }
    }

    .minicart{
        .cc_form_group{
            .btn-default{
                height: auto;
            }
        }

        .cc_row_sum {
            & > .osftaxincludedprice {
                text-align: right;
            }

            & > .osftaxincludedprice + .form-group {
                margin: 15px 0 0;
            }

            .osftaxincludedprice {
                color: $color-02;
                font-size: $s20px;
            }
        }

        &-qty {
            text-align: right;
            color: $color-02;
        }
    }

    .wishlist-container{

        .btn.cc_dropdown-toggle{
            display: block;
            width: 100%;
            background-color: transparent;
            border: 2px solid $color-06;

            &:hover{
                border-color: $color-05;
            }

            .cc_sel_wish{
                display: inline-block;
                max-width: 75%;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                margin: 0 5px 0 0;
            }
        }

        .quickwishdesk.cc_quickwishdesk{
            margin-top: 15px;
        }

        .cc_wish_item{
            padding: 25px 0;
            border-top: 2px solid $color-06;

            &:first-child{
                border-top: none;
            }
        }

        .cc_wish_row{
            border: none;
            margin: 0;
            padding-bottom: 0;
        }

        a#additionalInfoLink{
            margin-top: 15px;
            display: inline-block;

            &:hover{
                text-decoration: none;
            }
        }

        .cc_wish_img{
            width: 80px;
            height: 80px;
            margin-right: 16px;
            float: left;

            a{
                display: block;
            }
        }

        .cc_qty_control_row{
            display: flex;
            justify-content: flex-end;

            .form-group{
                width: 100%;
            }

            .cc_entry{
                text-align: right;
                border-color: $color-05;
                color: $color-02;
            }
        }

        .cc_cart_inc_price{
            font-size: 20px;
            line-height: 1.2;
            color: $color-02;
        }

        .cc_prt_row_tmplentry {
            margin-top: 7px;
            font-size: 14px;
        }

        .cc_addto_cart{

            .cc_add_item{
                color: $color-02;
                display: block;
                width: 100%;
                line-height: 1.2;
            }
        }

        .cc_addto_cart{
            margin: 0;
        }

        .panel-default>.panel-heading+.panel-collapse>.panel-body{
            padding-bottom: 0;
        }

        /* Responsive styles */
        @media only screen and (max-width: $sm - 1) {
            .cc_sel_wish{
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 170px;
                vertical-align: middle;
            }
            .dropdown-menu{
                max-width: 85vw;
                overflow: hidden;
            }
        }

        .dropdown-menu{
            left: 50%;
            transform: translateX(-50%);
        }

        .cc_pick_quick_wish{
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .reorderContainer{
        margin-bottom: 0;

        .cc_reorder_body{
            padding-top: 5px;
        }

        .cc_reorder_item{
            border-top: 2px solid $color-06;
            border-bottom: none;

            &:first-child{
                border-top: none;
            }
        }

        .cc_reorder_details_col{
            line-height: 1.5;
            font-weight: normal;

            .cc_reorder_row{
                margin-bottom: 30px;
            }
            .cc_reorder_col{
                color: $color-04;
            }
            .cc_prod_qty{
                text-align: right;
                color: $color-02;
                padding: 0px 10px;
                a{
                    line-height: 1.4;
                    font-size: $s16px;

                    &:hover{
                        text-decoration: none;
                    }
                }
            }
            .cc_order_total_label {
                width: 45%;
            }
            .cc_order_total_value {
                width: 55%;
            }

            .gp_btn_reorder{
                color: $color-02;
                line-height: 1.2;
            }
        }

        .cc_show_all_items{
            margin-bottom: 15px;
        }

        .gp_ordered_items_link{
            padding-right: 0;

            &:hover{
                text-decoration: none;
            }
        }

        hr{
            display: none;
        }

        .cc_footer{
            text-align: right;

            .cc_reorder_gotohistory {
                display: inline-block;
                margin: 0;
                padding: 6px 0;
    
                &:hover{
                    text-decoration: none;
                }
            }
        }

        /* Modal window */
        .cc_reorder_included_items_modal{

            .modal-header{
                .cc_reorder_row{
                    margin-bottom: 10px;
                }
                .cc_reorder_details_col{
                    .cc_reorder_col{
                        padding-right: 0;
                        width: 100%;
                    }
                }
                .cc_reorder_orderdate_label p{
                    margin: 0;
                    display: inline-block;
                }
            }
            .modal-body{
                text-align: left;
            }
            .modal-footer{
                padding: 25px 0 0;
                border-top: none;

                .gp_btn_reorder{
                    width: auto;
                }
            }
        }

        .arabic-layout{
            .cc_show_all_items{
                text-align: left;
            }
            .cc_footer{
                text-align: left;
            }
            .icon-ic_arrow_more{
                transform: rotate(180deg);
            }
            .cc_reorder_included_items_modal{
                text-align: right;

                .modal-header{
                    text-align: right;

                    h3{
                        text-align: right;
                    }
                    .cc_reorder_row{
                        margin-right: -7px;
                    }
                }
                .modal-body{
                    text-align: right;

                    .cc_reorder_prod_desc{
                        float: right;
                    }
                }
            }
        }

    }

    /* Icons */
    .icon{
        font-size: 20px;

        &.icon-ic_arrow_more{
            display: inline-block;
            font-size: 26px;
            padding-left: 8px;
            line-height: 14px;
            vertical-align: middle;
        }
    }

    .cc_mini_cart {
        .panel-heading .icon-item-self {
            position: relative;
            display: inline-block;
            padding-right: 15px;

            &_ar {
                transform: rotateY(180deg);

                .itemNr {
                    transform: rotateY(180deg);
                }
            }

            .itemNr {
                top: -7px;
                left: 13px;
            }
        }
    }

}

@media only screen and (max-width: $md - 1) {
    .promotion-box-RightNav,
    .promotion-box-LeftNav{
        display: none;

        &.promotion-box--visible{
            display: block;
            padding: 0 20px;
        }
    }
}

@media only screen and (max-width: $sm - 1) {
    .promotion-box-RightNav,
    .promotion-box-LeftNav{
        &.promotion-box--visible{
            padding: 0 15px;
        }

        .cc_promo{
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.cc_main_container--ar{
    .widgets-container{
        .panel.widget-panel,
        .panel.cc_re_order{
            .panel-heading{
                padding: 5px 0 0 30px;
            }
            .down-arrow{
                left: 0;
                right: auto !important;
            }
            .panel-title{
                margin-left: 0;
                margin-right: 10px;
            }
        }

        .panel.cc_re_order{
            .panel-heading{
                padding: 5px 10px 0 30px;
            }
        }

        .minicart .cc_row_sum .osftaxincludedprice{
            text-align: left;
        }

        .minicart-qty {
            text-align: left;
            direction: ltr;
        }
        
        .cc_pick_quick_wish{
            text-align: right;
        }

        .wishlist-container {
            .cc_wish_row{
                display: flex;

                &.cc_wish_row__price{
                    flex-direction: row-reverse;
                }

                .cc_wish_prod_quantity{
                    width: 205px;
                }
            }

            .cc_qty_control_row .cc_entry{
                text-align: left;
                direction: ltr;
            }

            .cc_dropdown-toggle{
                .cc_sel_wish{
                    margin: 0 0 0 5px;
                }
            }
        }

        .reorderContainer{
            .cc_reorder_included_items_modal{
                .cc_reorder_prod_row{
                    .cc_reorder_prod_img{
                        float: right;
                    }
                }
                .modal-footer{
                    text-align: left;

                    button{
                        margin-left: 0;
                        margin-right: 40px;
                    }
                }
            }
            .arabic-layout .cc_show_all_items {
                text-align: right;
            }

            .cc_reorder_details_col .cc_prod_qty{
                text-align: left;
            }
        }
        .cc_wish_img{
            margin-right: 0;
            float: left;
            margin-left: 16px; 
            overflow: hidden; 
        }

        .cc_dropdown .btn .caret{
            margin-left: 0;
            margin-right: 10px;
        }
    }
}

.wishlist-container {
    .pickQuickWish {
        cursor: pointer;
    }
}