.cc_forgot_password_panel {
    visibility: hidden; /* Hide untill content is rendered */

    .panel-title, 
    h3.cc_section_title {
        font-weight: 500;
        font-size: $s20px;
        line-height: 1.2;
    }
    .form-group {
        & > div {
            color: $color-04;
            font-size: $s14px;
        }
    }
    .has-error {
        .help-block {
            font-size: $s12px;
            font-style: normal;
            color: $color-09;
        }
    }
}

.cc_main_container--ar .cc_forgot_password_panel{
    .form-group{
        > div{
            @media only screen and (max-width: $sm - 1) {
                float: none !important;
            }
        }

        .col-sm-offset-2{
            margin-left: 0 !important;
            float: left !important;
            text-align: right;

            @media only screen and (max-width: $sm - 1) {
                float: right !important;
            }
        }
        .cc_email{
            text-align: right;
        }
        .has-error{
            em{
                text-align: right;
            }
        }
    }
}
