/**** SPLASH ****/
.splash-promo-Desktop {
    width: 100%;

    .carousel{
        @media only screen and (max-width: $sm - 1) {
            margin-bottom: -115px;
        }
    }

    .carousel-inner {
        overflow-x: hidden;

        .item {
            height: 490px;

            a {
                display: block;
                height: 100%;
            }

            img {
                display: block;
                object-fit: cover;
                height: 100%;
            }

            @media only screen and (max-width: $lg - 1) {
                height: 400px;
            }

            @media only screen and (max-width: $md) {
                height: 310px;
            }

            @media only screen and (max-width: $sm - 1) {
                overflow: hidden;
                height: 325px;

                img {
                    display: block;
                    object-fit: initial;
                    width: auto;
                    position: absolute;
                    left: -525px;
                }
            }
        }

        .carusel-content-wrap{
            top: 0;
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            background: rgba(62,67,72,.5);
        }
        .carusel-content{
            text-decoration: none;
            position: absolute;
            bottom: 50%;
            transform: translate(0, 50%);
            width: 100%;
            left: 0;
            padding: 0 175px;

            .carusel-text{
                position: relative;
                max-width: 45%;

                .carusel-text__title{
                    font-size: $s37px;
                    color: $color-01;
                    line-height: 1.18;
                    margin-top: 0;
                }
                .carusel-text__sub-title{
                    margin-top: 0;
                    color: $color-white;
                    font-size: $s24px;
                    @extend %font-light;
                }
            }
            
        }

        @media only screen and (max-width: $lg - 1) {
            .carusel-content{
                padding: 0 150px;
    
                .carusel-text{
                    .carusel-text__title{
                        font-size: $s30px;
                        line-height: 1.18;
                    }
                    .carusel-text__sub-title{
                        font-size: $s24px;
                    }
                }
            }
        }
        @media only screen and (max-width: $md - 1) {
            .carusel-content-wrap{
                background: rgba(62,67,72,.6);
            }

            .carusel-content{
                left: 10%;
                right: 10%;
                margin:0 20px 15px;
                padding: 0;
                width: auto;
    
                .carusel-text{
                    text-align: center;
                    
                    .carusel-text__title{
                        font-size: $s32px;
                    }
                    .carusel-text__sub-title{
                        font-size: $s26px;
                    }
                }
            }
        }
        @media only screen and (max-width: $sm - 1) {
            .carusel-content{
                margin:0 15px;
    
                .carusel-text{
                    max-width: none;
                    
                    .carusel-text__title{
                        font-size: $s29px;
                        line-height: 1.1;
                    }
                    .carusel-text__sub-title{
                        font-size: $s16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .splash-left-arrow {
        background-image: url('../images/splash_arrow_left.svg');
        left: 64px;

        @media only screen and (max-width: $md - 1) {
            left: 30px;
        }

        @media only screen and (max-width: $sm - 1) {
            left: 10px;
        }
    }

    .splash-right-arrow {
        background-image: url('../images/splash_arrow_right.svg');
        right: 64px;

        @media only screen and (max-width: $md - 1) {
            right: 30px;
        }

        @media only screen and (max-width: $sm - 1) {
            right: 10px;
        }
    }

    .splash-left-arrow,
    .splash-right-arrow {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50px;
        width: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media only screen and (max-width: $md - 1) {
            height: 35px;
            width: 35px;
        }

        @media only screen and (max-width: 600px) {
            height: 25px;
            width: 25px;
        }
    }

    .carousel-indicators {
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        margin: 0 0 30px;
        transform: translate(-50%, 0);
        padding: 0px;

        li {
            background-color: rgba($color-white, 0.8);
            margin: 0px 12px;
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.active {
                box-sizing: content-box;
                padding: 5px;
                margin: 0px 6px;
                border: 1px solid $color-white;
                background-clip: content-box;
            }

            @media only screen and (max-width: 600px) {
                margin: 0px 5px;

                &.active {
                    padding: 2px;
                    margin: 0px 5px;
                }
            }
        }

        @media only screen and (max-width: 600px) {
            bottom: -8px;
        }
    }
}

.osf_home_tmpl {

    &.container.cc_main_container{
        margin-top: 0;
    }

    /* Hide */
    .widgets-container{
        padding-top: 100px;
    }
    .cc_right_col.widgets-container{
        .seller-locator-search, .quick-order{
            display: none;
        }
    }

    /**** SPOTLIGHT & FEATURED ****/
    .spotlight-container, .featured-container{
        @media only screen and (min-width: $md) {
            margin-left: -25px;
            margin-right: 5px;
        }
    }

    .cc_spotlight_products,
    .cc_feeatured_products {
        padding: 0px 0 50px 0;

        .panel-heading {
            text-align: center;
        }

        .panel-title {
            @extend %font-medium;
            font-size: $s30px;
            line-height: 1.17;
            color: $color-02;
            text-transform: capitalize;
            margin-bottom: 35px;
        }

        .cc_body{
            padding: 5px 0 0;
        }

        .cc_product_name,
        .cc_price {
            &,
            &:link {
                @extend %font-medium;
                font-size: $s20px;
                line-height: 1.2;
                color: $color-02;
                text-decoration: none;
            }
            &,
            &:focus {
                outline: none;
            }
        }

        .cc_product_name {
            text-transform: none;

            &:hover {
                color: $color-01;
            }

            &::after {
                width: 35px;
                height: 4px;
                display: block;
                margin-top: 8px;
                content: '';
                background: $color-01;
            }
        }

        .cc_description {
            @extend %font-light;
            font-size: $s14px;
            line-height: 1.54;
            color: $color-04;
        }

        .cc_featured_prod_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            @media only screen and (min-width: $md) {
                padding-left: 0;
            }
        }

        .cc_featured_col {
            @media only screen and (max-width: $sm - 1) {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            margin-bottom: 20px;

            .featured_prod_item {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                background-color: $color-white;
                padding: 0px;
                margin: 0px;
                display: flex;
                flex-direction: column;
            }

            .prod-image{
                height: 310px;
                background-color: #f1f3f4;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2px solid $color-06;

                a{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0;

                    img{
                        max-height: 100%;
                        width: 100%;

                        @media only screen and (max-width: $md) {
                            width: auto;
                        }
                    }
                }
            }

            .featured_prod_info {
                padding: 10px 20px 20px;
                text-align: left;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                @media only screen and (max-width: $sm - 1) {
                    padding: 25px 15px;
                }

                .cc_product_name::after {
                    display: none;
                }

                .cc_description {
                    margin: 6px 0px 16px;
                }

                .prod-price-wrapper{
                    min-height: 24px;
                }
                .cc_price {
                    margin: 0px;
                }

                .go_to_PDP {
                    line-height: 16px;

                    &,
                    &:link,
                    &:active,
                    &:visited,
                    &:hover {
                        align-self: flex-end;
                        font-size: $s30px;
                        color: $color-01;
                        text-decoration: none;
                    }
                }

                &.text-right{
                    .go_to_PDP {
                        &,
                        &:link,
                        &:active,
                        &:visited,
                        &:hover {
                            align-self: flex-start;
                            font-size: $s30px;
                            color: $color-01;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    .cc_spotlight_products{

        .cc_title{
            margin-top: 35px;
            margin-bottom: 0;
        }
        .cc_body{
            display: flex;
            justify-content: center;

            @media only screen and (max-width: $sm - 1) {
                flex-wrap: wrap;
            }
        }
        .cc_spotlight_prod_item{
            max-width: 370px;
            margin: 0 22px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

            @media only screen and (max-width: $sm - 1) {
                margin-bottom: 20px;
            }

            .cc_prod_image{
                height: 260px;
                background-color: #f1f3f4;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2px solid $color-06;

                a{
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        max-width: 330px;
                        max-height: 100%;
                    }
                }
            }

            .cc_prod_details{
                padding: 5px 20px 10px;

                .cc_description {
                    font-size: $s14px;
                    line-height: 1.54;
                    color: $color-02;
                    margin: 18px 0 20px;
                }
            }
        }
    }

    .cc_feeatured_products {
        padding: 0px;
    }

    .reorderContainer{
        .down-arrow {
            right: 12px !important;
        }
    }
}

.osf_home_tmpl.cc_main_container--ar{
    .spotlight-container, .featured-container{
        @media only screen and (min-width: $md) {
            margin-left: 5px;
            margin-right: -25px;
        }
    }
    .cc_spotlight_products,
    .cc_feeatured_products {
        @media only screen and (min-width: $md) {
            padding-left: 15px;
            padding-right: 0;
        }
        .cc_featured_prod_list {
            @media only screen and (min-width: $md) {
                padding-right: 0;
            }
        }

        .cc_price{
            text-align: right;
        }
        .icon-ic_arrow_more.gp_prod{
            align-self: flex-end;
            transform: rotate(180deg);
        }

        .go_to_PDP{
            align-self: flex-end !important;
        }
    }
    .reorderContainer {
        .down-arrow{
            left: 10px !important;
        }
    }
}
.page-layout--arabic{
    .splash-promo-Desktop {
        .carousel-inner {
            .item {
                @media only screen and (max-width: $sm - 1) {
                    img {
                        left: auto;
                        right: -525px;
                    }
                }
            }
            .carusel-content-wrap{
                background: rgba(62,67,72,.6);
            }
            .carusel-content{
                .carusel-text{
                    @media only screen and (max-width: $sm - 1) {
                        .carusel-text__title{
                            line-height: 1.5;
                        }
                    }

                    @media only screen and (min-width: $sm) {
                        .carusel-text__sub-title{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .splash-left-arrow {
            background-image: url('../images/splash_arrow_right.svg') !important;
            left: unset !important;
            right: 64px !important;
    
            @media only screen and (max-width: $md - 1) {
                right: 30px !important;
            }
    
            @media only screen and (max-width: $sm - 1) {
                right: 10px !important;
            }
        }
    
        .splash-right-arrow {
            background-image: url('../images/splash_arrow_left.svg') !important;
            right: unset !important;
            left: 64px !important;
    
            @media only screen and (max-width: $md - 1) {
                left: 30px !important;
            }
    
            @media only screen and (max-width: $sm - 1) {
                left: 10px !important;
            }
        }
    }
}

.cc_reorder_sku {
    display: none;
}

/* responsive styles */
@media only screen and (max-width: $sm - 1) {
    .osf_home_tmpl {

        .cc_spotlight_products, .cc_feeatured_products{
            padding: 0px 0 15px 0;

            .panel-title{
                margin-bottom: 15px;
            }
            
        }
        
        .cc_spotlight_products{

            .cc_spotlight_prod_item{
                margin-left: 0;
                margin-right: 0;

                .cc_prod_image{
                    a img{
                        max-width: 100%;
                    }
                }
            }
        }

        .cc_feeatured_products {
            .panel-title{
                font-size: 20px;
            }
        } 

        .cc_feeatured_products .cc_featured_prod_list{
            padding: 0;
        }

        .widgets-container{
            padding-top: 0;

            &.minicart, &.wishlist-container, &.reorderContainer{
                padding: 0 15px;
            }

            .reorderContainer .cc_reorder_included_items_modal .modal-footer{
                padding: 0 5px;
            }
        }
    } 
}

.cc_mini_cart,
.cc_re_order,
.cc_wishlist_panel {
    .fa-caret-down {
        margin: -4px 5px;
    }
}

/* styles for '...' */ 
.block-with-text {
    height: 2.6rem;
    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative; 
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 3.6em; 
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: left;  
    /* place for '...' */
    // margin-right: -1em;
    // padding-right: 1em;

    // /* create the ... */
    // &:before {
    //     /* points in the end */
    //     content: '...';
    //     /* absolute position */
    //     position: absolute;
    //     /* set position to right bottom corner of block */
    //     right: 0;
    //     bottom: 0;
    //     line-height: 1.2;
    // }

    // /* hide ... if we have text, which is less than or equal to max lines */
    // &:after {
    //     /* points in the end */
    //     content: '';
    //     /* absolute position */
    //     position: absolute;
    //     /* set position to right bottom corner of text */
    //     right: 0;
    //     /* set width and height */
    //     width: 1em;
    //     height: 2.6rem;
    //     margin-top: 0.2em;
    //     /* bg color = bg color under block */
    //     background: $color-white;
    // }

    &_ar {
        text-align: right;
        // margin-right: 0em;
        // padding-right: 0em;
        // margin-left: -1em;
        // padding-left: 1em;

        // &:before {
        //     right: unset;
        //     left: 0;
        // }
    
        // &:after {
        //     right: unset;
        //     left: 0;
        // }
    }
}
