.coupon_notification {
    .notification {
        background-color: $color-08;
        color: $color-white;
        
        .container {
            position: relative;
            display: flex;
            align-items: center;
            padding: 15px 55px 15px 15px;

            .coupon_text{
                line-height: 1.19;
                font-weight: 500;
            }

            .icon-ic_cross {
                position: absolute;
                right: 25px;
                top: 0;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;

                @at-root [dir="rtl"] & {
                    left: 25px;
                    right: initial;
                }
            }
        }
    }

    @media only screen and (max-width: $md - 1) {
        .notification {
            .container {
                width: 100%;

                .coupon_text {
                    line-height: 1.33;
                    font-size: $s12px;
                }
            }
        }
    }

    @media only screen and (min-width: $md) {
        margin-left: -17px;

        .notification {
            .container {
                justify-content: center;
    
                .icon-ic_cross {
                    right: 15px;

                    @at-root [dir="rtl"] & {
                        left: 15px;
                        right: auto;
                    }
                }
            }
        }
    }
}

.page-header--ar{
    .coupon_notification{
        .container{
            padding: 15px 15px 15px 55px;
        }
    }
}

header {
    .CN_cc_menu_container_1 {
        @include header-position(1);
    }
    .CN_cc_menu_container_2 {
        @include header-position(2);
    }
    .CN_cc_menu_container_3 {
        @include header-position(3);
    }
    .CN_cc_menu_container_4 {
        @include header-position(4);
    }
}

.deskLayout.CN_deskLayout_1  {
    @include layout-margin-top(1)
}
.deskLayout.CN_deskLayout_2  {
    @include layout-margin-top(2)
}
.deskLayout.CN_deskLayout_3  {
    @include layout-margin-top(3)
}
.deskLayout.CN_deskLayout_4  {
    @include layout-margin-top(4)
}