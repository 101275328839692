.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'imdadicon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ic_account:before {
    content: "\e900";
  }
  .icon-ic_arrow_down_menue:before {
    content: "\e901";
  }
  .icon-ic_arrow_down_small:before {
    content: "\e902";
  }
  .icon-ic_arrow_down:before {
    content: "\e903";
  }
  .icon-ic_arrow_more:before {
    content: "\e904";
  }
  .icon-ic_cal:before {
    content: "\e905";
  }
  .icon-ic_cart:before {
    content: "\e906";
  }
  .icon-ic_checkbox:before {
    content: "\e907";
  }
  .icon-ic_checkout:before {
    content: "\e908";
  }
  .icon-ic_cross:before {
    content: "\e909";
  }
  .icon-ic_email_blue:before {
    content: "\e90a";
  }
  .icon-ic_key:before {
    content: "\e90b";
  }
  .icon-ic_login:before {
    content: "\e90e";
  }
  .icon-ic_logout:before {
    content: "\e90f";
  }
  .icon-ic_minus:before {
    content: "\e910";
  }
  .icon-ic_plus:before {
    content: "\e911";
  }
  .icon-ic_quote_blue:before {
    content: "\e912";
  }
  .icon-ic_recent_orders:before {
    content: "\e913";
  }
  .icon-ic_search:before {
    content: "\e914";
  }
  .icon-ic_trash:before {
    content: "\e915";
  }
  .icon-ic_update:before {
    content: "\e916";
  }
  .icon-ic_wishlist:before {
    content: "\e917";
  }
  .icon-social_fb:before {
    content: "\e918";
  }
  .icon-social_insta:before {
    content: "\e919";
  }
  .icon-social_twitter:before {
    content: "\e91a";
  }
  .icon-social_youtube:before {
    content: "\e91b";
  }
