.datepicker.datepicker-dropdown{
    border-radius: 0;
    border-color: $color-05;

    table tr{
        border-bottom: 1px solid $color-06;

        th{
            @extend %font-medium;
            border-radius: 0;
        }
        td{
            border-radius: 0;
    
            &.day{
                &:hover{
                    background: $gray-1;
                }
            }

            &.today.day{
                background-color: $gray-1;
            }
            &.active.day{
                background-color: $color-01;
            }
            
        }
    }
}