.d-flex {
    display: flex !important;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-baseline {
    align-items: baseline;
}

.pull-left {
    float: left !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-end {
    text-align: end !important;
}

.border-left {
    border: none !important;
    border-right: 2px solid #3E4348 !important;
    padding: 0px !important;
}

.margin-right190 {
    margin-right: 190px !important;
}

.margin-right145 {
    margin-right: 145px !important;
}

.margin-right14 {
    margin-right: 14px !important;
}

.rotateY180 {
    transform: rotateY(180deg);
}

.padding-top10 {
    padding-top: 10px !important;
}

.no_padding {
    padding: 0px !important;
}

.no_margin {
    margin: 0px !important;
}

.no_border {
    border: none !important;
}

.fa-caret-down {
    font-family: 'imdadicon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: 0.6s ease-in-out;
    transform: rotateX(180deg); 
    width: 9px !important;
    height: 5px !important;
    font-size: $s10px;
    display: block;
    margin: 7px 5px;

    &:before {
        content: "\e902";
    }
}

.cc_filter_icon{
    @media only screen and (max-width: $md) {
        display: block;
        padding: 2px 5px 5px;
    }

    .fa-caret-down{
        width: 20px !important;
        height: 15px !important;
        font-size: 1rem;
        display: block;
        margin: 0;
        cursor: pointer;
    }
}

.rotateX180 {
    transition: 0.5s ease-in-out;
    transform: rotateX(180deg) !important;
}

.rotateX0 {
    transition: 0.5s ease-in-out;
    transform: rotateX(0deg) !important;
}

.icon-toggle.cc_filter_icon.collapsed {
    .fa-caret-down {
        transform: rotateX(0deg);
        transition: 0.6s ease-in-out;
    }
}

.noWhiteSpace {
    white-space: nowrap;
}

.opacity_full {
    opacity: 1 !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.no_pointer_events {
    pointer-events: none !important;
}

.transition0s {
    transition: 0s ease !important;
}

.directionLTR {
    direction: ltr !important;
}