html,
body {
    @extend %font-regular;
    font-size: $s16px;

    // mainly reset chrome on android
    @media only screen and (max-width: $sm - 1) {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        margin: 0;
        padding: 0;
    }
}

h1 {
    font-size: $s30px;
}

h2 {
    font-size: $s24px;
}

h3 {
    font-size: $s20px;
}

h4 {
    font-size: $s16px;
}

//inputs
select.form-control,
input.form-control {
    @include default-input;
}

label {
    font-size: $s14px;
    color: $color-02;
    @extend %font-regular;
}

.has-error {
    select.form-control,
    input.form-control {
        @include default-input;
        color: $color-09;
        border-color: $color-09;
        &:focus {
            border-color: $color-09;
        }
    }
    label {
        color: $color-09;
    }
    .help-block {
        color: $color-09;
        font-size: $s14px;
    }
}

//buttons
button.btn-default,
input.btn-default,
label.btn-default {
    color: $color-03;
    background-color: $color-01;
    border-color: $color-01;
    border-radius: 0;
    min-width: 7.5rem;
    padding: 0 2rem;
    &:active {
        background-color: $color-01;
        border-color: $color-01;
    }
    &:hover,
    &:focus {
        background-color: $color-11;
        border-color: $color-11;
    }
}

button.btn-default.disabled,
input.btn-default.disabled,
label.btn-default.disabled {
    color: $color-06;
    background-color: $color-05;
    border-color: $color-05;
    &:hover {
        background-color: $color-05;
        border-color: $color-05;
    }
}

button.btn-primary,
input.btn-primary {
    color: $color-01;
    background-color: transparent;
    border-color: $color-01;
    border-radius: 0;
    border-width: 2px;
    min-width: 7.5rem;
    padding: 0 2rem;
    @extend %font-regular;
    &:hover,
    &:focus,
    &:active {
        color: $color-01;
        background-color: transparent;
        border-color: $color-01;
        border-width: 2px;
        outline: solid $color-01 1px;
        @extend %font-medium;
    }
}

button.btn-primary:active:focus,
input.btn-primary:active:focus,
button.btn-primary:active:hover,
input.btn-primary:active:hover {
    background-color: transparent;
    color: $color-01;
    border-color: $color-01;
    outline: solid $color-01 1px;
}

button.btn-primary.disabled,
input.btn-primary.disabled {
    color: $color-05;
    background-color: transparent;
    border-color: $color-05;
    border-width: 2px;
    outline: none;
    @extend %font-regular;
    &:hover {
        color: $color-05;
        background-color: transparent;
        border-color: $color-05;
        border-width: 2px;
        outline: none;
        @extend %font-regular;
    }
}

//dropdowns
.btn-group {
    &.open {
        .dropdown-toggle {
            background-color: $color-05;
            box-shadow: none;
        }
    }
    button.btn-default.dropdown-toggle {
        max-width: 12.3125rem;
        border: none;
        background-color: $color-06;
        &:hover,
        &:focus {
            background-color: $color-05;
        }
    }
    .dropdown-menu {
        margin: 0;
        border-radius: 0;
        text-align: left;
        width: 100%;
        button.btn-default {
            background-color: #fff;
            border: none;
            padding: 0 1rem;
            height: 1.875rem;
            text-align: left;
            &:hover {
                background-color: $color-06;
            }
        }
    }
}

button.btn-sm,
input.btn-sm,
label.btn-sm {
    font-size: $s15px;
    height: $btn-sm-h;
    border-radius: 0;
}

button.btn-md {
    font-size: $s16px;
    height: $btn-md-h;
    border-radius: 0;
}

button.btn-lg,
input.btn-lg {
    font-size: $s18px;
    height: $btn-lg-h;
    border-radius: 0;
}

button.btn-ghost,
input.btn-ghost {
    &,
    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &:active:hover {
        background-color: transparent;
        border-color: transparent;
        color: $color-01;
        outline: none;
    }
}

button.btn-dropdown {
    background-color: transparent;
    border-color: $color-06;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    &:hover,
    &:focus,
    &:active,
    &.active {
        background-color: $gray-1;
    }
}

button.textbutton-blue,
a.textbutton-blue,
span.textbutton-blue {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: $color-08;
    font-size: $s14px;
    line-height: 1.14;
    font-weight: 400;
    text-decoration: none;
    outline: none;

    &:hover {
        &,
        & span,
        & a {
            text-shadow: 1px 0px 0px $color-08;
        }
    }
    &:hover,
    &:focus {
        color: $color-08;
    }
}

button.textbutton-blue.disabled,
a.textbutton-blue.disabled,
span.textbutton-blue.disabled,
button.textbutton-blue[disabled],
a.textbutton-blue[disabled],
span.textbutton-blue[disabled] {
    color: $gray-2;

    &:hover {
        font-weight: 400;
    }
}

.breadcrumb{
    li,
    a {
        font-size: 12px;
        line-height: 1.17;
        color: $color-05;
        font-size: $s12px !important;

        &:hover {
            color: $color-01;
            text-decoration: none;
            cursor: pointer;
        }

        &:visited,
        &:focus,
        &:link {
            text-decoration: none;
            outline: none;
        }
    }
}

//loading gif
.ccrz_loading_gif {
    height: 56px;
}

/* Page layout */
@media only screen and (min-width: $lg) {
    .container {
        width: 1300px;
    }
    .cc_main_content_col{
        > .cartContainer, > .featured-prod, > .orderContainer, > .checkoutContent{
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    .checkout-nav-steps{
        margin-left: -15px;
        margin-right: -15px;
    }
}
@media only screen and (min-width: $md) {
    .cc_left_col, .cc_right_col{
        padding-left: 0;
        padding-right: 0;
    }
}

//loading gif for backdrop 
#overlay.modal-backdrop.fade.in {
    background-image: url('../images/loading.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    opacity: 0.75;
    transform: scale(0.55);
    margin: -1000px;
    z-index: 3000;
}

/* Font family */
.deskLayout{
    font-family: 'Roboto', sans-serif;
}
.deskLayout[dir=rtl]{
    font-family: 'Cairo', sans-serif;
}

.padLeft10{
  padding-left: 10px;
}

@media only screen and (max-width: $md - 1) and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    .table-responsive .table {
        max-width: none;
        -webkit-overflow-scrolling: touch !important;
    }
}

.collapsing {
    pointer-events: none;
}

// ios fix for the input zooming issue
@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    input[type=text],
    input[type=textarea],
    select {
        font-size: 16px !important;
    }

    .form-group-sm .form-control {
        ::-webkit-input-placeholder { /* Edge, Safari */
            font-size: 12px;
        }
          
        :-ms-input-placeholder { /* Internet Explorer */
            font-size: 12px;
        }
          
        ::placeholder {
            font-size: 12px;
        }
    }

}
