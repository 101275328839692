.wishButtons {
    .btn-group {
        &.open {
            button {
                &.btn-default {
                    @extend %font-medium;
                }
            }
            .icon-ic_arrow_down_small {
                font-weight: 600;
            }
        }

        button {
            &.btn-default {
                background-color: transparent;
                border: none;
                color: $color-08;
                display: flex;
                padding: 0;
                margin: 0;
                min-width: unset;
                font-size: $s14px;
                line-height: 1.14;

                &:hover {
                    text-decoration: underline;
                }

                &.dropdown-toggle {
                    color: $color-08;
                    background-color: transparent;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        color: $color-08;
                        font-weight: 700;
                        background-color: transparent;
                    }
                }

                &.disabled {
                    color: $gray-2;
                }
            }
        }
    }

    .dropdown-menu {
        left: 50%;
        transform: translateX(-50%);

        li:last-child {
            a {
                color: $color-08;
            }
        }
    }

    .icon-ic_arrow_down_small {
        padding: 0rem 0.5rem;
    }

    &:hover {
         
        .icon-ic_arrow_down_small {
            font-weight: 600;
        }
    }

    .pickWish {
        padding: 0 1rem;
        min-width: auto;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
